﻿@import '_variables';
@import '_mixins';

.brand-expression-program {
    .brand-expression__headline {
        font-size: toRem(32);
        font-weight: $fw_bold;
        letter-spacing: toRem(0.5);
    }

    .brand-expression__introline {
        font-size: toRem(16);
        line-height: toRem(22);
    }
}

.main[data-enews-article] {
    .article__headline {
        font-weight: $fw_bold;
        font-size: toRem(24);
    }

    ol li, ul li {
        font-size: toRem(16);
    }

    p {
        font-size: toRem(16);
    }
}

.main[data-enews-issue] {
    font-size: toRem(16);

    ol li, ul li {
        font-size: toRem(16);
    }

    p {
        font-size: toRem(16);
    }
}

[data-browsebytopicpage] {
    .card__list {
        font-size: toRem(16);

        @media all and (min-width:$desktop_min) {
            font-size: toRem(18);
        }
    }
}

a.mySoaNav__link {text-decoration:none;}
.mySOA__wrapper--outermost a {text-decoration:none;}
#srchauthor-button {white-space:nowrap;}

.search--main .search__select::after {display:none; content:none;}
//.search__select select.form-control {background-image:none;}
.search__results .results__link {@include textUnderlineCustom();}
.search-shuffle-sidebar a, .panel__list-item a {}
.pagination__link {text-decoration:none;}

.main[data-pdcalendarpage] {
    a {
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        color: $black;
        line-height: 1.2;
    }

    p {
        font-size: toRem(16);
    }

    ol li, ul li {
        font-size: toRem(16);
    }
}

//[data-landingpage] {
//    .card__headline__link {color:$neutral-900;}
//    a.card__headline__link {text-decoration: none;
//        &, &:link, &:active, &:visited {color:$neutral-900;}
//        &:hover, &:focus {color:$soa-blue;}
//        &:visited:hover {color:$soa-blue;}
//        &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$neutral-900;}
//        &:active, &:visited, &:hover, &:focus {text-decoration:none;}
//        &.disabled, &:disabled {color:$soa-blue;}
//        
//    }
//}

.soa-program-interior-nav {
    a {
        text-decoration: none;
    }
}

[data-event-detail] {
    .product-event__item {
        a {
            //text-decoration: none;
        }
    }
}
.interior-nav__item {display:flex;
    .interior-nav__link {width:100%; height:auto;
        span {display:inline-block; padding-top:toRem(8); padding-bottom:toRem(8); line-height:1.2;}
        &:active, &.active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$white;}
    }
}

.carousel-wrapper {
    .carousel-desktop-cta {@extend .btn, .btn-white, .btn-lg;}
}
a.program-read-more, .program-read-more > a {@extend .btn, .btn-text; font-size:inherit;
    &::after {top:50%; transform:translateY(-50%);}
}
.program-read-more {
    &::after {top:50%; transform:translateY(-50%);}
}
.main a:not(.results__link):not(.card__headline__link)[href$=".pdf"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".doc"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".docx"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".xls"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".xlsx"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".xlsm"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".ppt"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".pptx"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".zip"]:after {transform:none !important;}

.nav-tabs {
    .nav-link {
        a {
            text-decoration: none;
        }
    }
}

.dropdown-menu.autocomplete-results {margin:0; padding:0; border:1px solid $neutral-200; @include boxshadow(0, 3px, 8px, 0, rgba(0, 0, 0, 0.2));
    &, li {font-size:toRem(16); user-select:none;}
    li {padding-left:toRem(16); padding-right:toRem(16); cursor:default;
        &:hover {background-color:$neutral-200;}
    }
}

.toggle-show-panel .register-list {margin-bottom:0;}

.form-control.search__input {border-radius:toRem(16);}

.secondary-content, .enewsletter, .enews__container, .spp__container, .spp__pg-wrapper {@include nestedBlocks(0,0);}