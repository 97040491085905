﻿@import '_variables';

%pageSection {padding-top:toRem($block-gutter-y); padding-bottom:toRem($block-gutter-y);
    @media all and (min-width:$screen_md_min) {
        & {padding-top:toRem($block-lg-gutter-y); padding-bottom:toRem($block-lg-gutter-y);}
    }
}

.soa-breadcrumbs-main {@include sourcesans;
    $crumb-gutter:5;
    .soa-breadcrumbs {display:flex; flex-direction:row; flex-wrap:wrap; justify-content:flex-start; align-content:flex-start; align-items:flex-start; margin-left:toRem(-$crumb-gutter); margin-right:toRem(-$crumb-gutter); padding-top:toRem(12); padding-bottom:toRem(12);}
    .soa-breadcrumbs-crumb {padding-left:toRem($crumb-gutter); padding-right:toRem($crumb-gutter);
        .soa-breadcrumbs-link, .soa-breadcrumbs-separator {display:inline-block; padding-top:toRem(3); padding-bottom:toRem(3); font-size:toRem(13); line-height:toRem(17);}
        .soa-breadcrumbs-link {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$neutral-700; @include textUnderlineCustom;}
            &.active {text-decoration:none;}
        }
        .soa-breadcrumbs-separator {margin-right:toRem(($crumb-gutter * 2)); color:$neutral-300;}
        &:first-child {
            .soa-breadcrumbs-link {
                &::before {@include fas-family; color:$neutral-700; content:"\f015";}
            }
        }
    }
}

/// Alert
.alert--soa {position:relative; background-color:$neutral-900; padding:toRem(16) 0 0 0; margin-bottom:0;
    .row {@include customGutter(9);}
    .alert--icon {color:$soa-aux-blue;}
    &, & p {color:$neutral-400; font-size:toRem(14);
        a {
            &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$white; font-weight:$fw_medium; @include textUnderlineCustom;}
        }
    }
}
.alert__button--close {position:absolute; right:$padding-base; top:$padding-base * 1.25; font-size:2.8rem; font-weight:bold; line-height:1; text-shadow:0 1px 0 #fff; opacity:.2;
    &[type="submit"], &[type="submit" i] {padding:0 .8rem .4rem;}
    &:hover, &:focus {color:#000; text-decoration:none; cursor:pointer; opacity:.5; outline:none;}
}