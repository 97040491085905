﻿@import '_variables';
@import '_mixins.scss';

%sub-menu-link {display:block; padding:toRem(16) toRem(24); border-radius:toRem(26); font-weight:$fw_medium; font-size:toRem(17); line-height:1.1; letter-spacing:toRem(-0.34);
    &, &:link, &:active, &:visited, &:hover, &:focus {color:$neutral-900; text-decoration:none;}
    &, &:link, &:visited {background-color:$white;}
    &:hover, &:focus {background-color:$soa-blue-light; color:$soa-blue;}
    &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {background-color:$soa-blue-light;}
    &.disabled, &:disabled {color:$neutral-500; background-color:$neutral-50;}
    //&, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$soa-blue;}
}

.scroll-locked {overflow:hidden; pointer-events:none;}

header {position:relative; padding:toRem(16) 0;
    .header-logo {
        img, svg {fill:$soa-blue; width:toRem(120);}
        a {
            &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {display:block; text-decoration:none;}
        }
    }
    .header-triggers {@include customGutter(5);
        .btn {padding:toRem(8) toRem(12) toRem(8); border-radius:toRem(16); min-width:toRem(51); text-align:center;}
    }

    $mysoadropdown-posY:20;
    .mySoaDropdown-wrap {position:relative;
        .mySoaDropdownButton {position:relative;}
        &.open {
            .mySoaDropdownButton {
                &::before {display:block; position:absolute; left:50%; bottom:toRem(-$mysoadropdown-posY - 10); transform:translateX(-50%) rotate(45deg); width:toRem(14); height:toRem(14); border-radius:toRem(2); background:$white; content:""; z-index:12; @include boxshadow(0, toRem(10), toRem(64), toRem(-10), rgba($neutral-800, 0.1));}
            }
        }
    }
//    .mySoaDropdownButton {position:relative;
//        &.open::before {display:block; position:absolute; left:50%; bottom:toRem(-$mysoadropdown-posY - 10); transform:translateX(-50%) rotate(45deg); width:toRem//(14); height:toRem(14); border-radius:toRem(2); background:$white; content:""; z-index:8; @include boxshadow(0, 10, 64, -10, rgba($neutral-800, 0.1));}
//        &.collapsed {
//            &::before {display:none; content:none;}
//        }
//    }
    .mySoaDropdown-wrap {}
    #mysoadropdown {position:absolute; left:auto; right:0; top:auto; margin-top:toRem($mysoadropdown-posY); width:calc(100vw - #{toRem($container-gutter-x * 2)}); width:calc(100dvw - #{toRem($container-gutter-x * 2)}); /*max-width:toRem(548);*/ max-width:toRem(328); padding:toRem(24); background:$white; border-radius:toRem(24); @include boxshadow(0, toRem(10), toRem(64), toRem(-10), rgba($neutral-800, 0.1)); z-index:11;}
        .mysoadropdown-wrap {
        .mysoadropdown-userimage {
            svg, img {width:toRem(48);}
        }
        .mysoadropdown-userinfo {margin-bottom:toRem(24); padding-bottom:toRem(24); border-bottom:1px solid $neutral-200; @include figtree();
            & > .row {@include customGutter(8);}
            .mysoadropdown-username {margin-bottom:toRem(4); font-size:toRem(18); font-weight:$fw_semibold; line-height:1.1; letter-spacing:toRem(-0.36); word-break:break-word; word-wrap:break-word;}
            .mysoadropdown-userlinks {
                & > .row {@include customGutter(8);
                    [class*="col-"] {position:relative;
                        &::before {display:block; position:absolute; left:0; top:toRem(7); bottom:toRem(7); width:1px; background:$neutral-200; content:"";}
                        &:first-child {
                            &::before {display:none; content:none;}
                        }
                    }
                }
                a {font-size:toRem(14); font-weight:$fw_medium;
                    //&, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$soa-blue;}
                }
            }
        }
        .mysoadropdown-mysoalinks {
            ul, ul li {
                &, & li {display:block; margin:0; padding:0; list-style-type:none;}
                & li { }
                a {@extend %sub-menu-link;}
            }
        }
    }
    
    $utility-gutter:26;
    .utility__links {display:flex; margin:0; padding:0; flex-wrap:nowrap; justify-content:center; align-items:center;
        .nav {display:flex; margin:0; padding:0; flex-wrap:nowrap; justify-content:center; align-items:center;
            li {display:block; flex-shrink:1; position:relative; padding-left:toRem($utility-gutter); padding-right:toRem($utility-gutter);
                &::before {display:block; position:absolute; left:0; top:toRem(13); bottom:toRem(13); width:1px; background:$neutral-300; content:"";}
                &:first-child {
                    &::before {display:none; content:none;}
                }
            }
            a {display:flex; flex-wrap:nowrap; justify-content:center; align-items:center; padding:toRem(12) 0; @include figtree_medium; text-align:center;
                &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$neutral-700; font-size:toRem(14); line-height:1; text-decoration:none;}
                @include iconClassnames {margin-right:toRem(10); font-size:toRem(10);}
            }
        }
        .utility__search {position:relative;
            #bs-nav-input {padding:toRem(6); background:$neutral-50; border-radius:toRem(30);}
            .nav-input.text-input-group {padding:toRem(5); background:$white; border:1px solid $white; border-radius:toRem(26);
                .text-input {
                    &, &:focus-within {}
                    span {}
                    input {width:toRem(267);}
                }
            }
            .text-input {
                &, &:focus-within {border:none; background:none;}
                span {display:none;}
                input {
                    &, &[type="text"] {padding:0 toRem(10); border:none; background:none; @include figtree_medium; font-size:toRem(16); font-weight:$fw_medium; line-height:1.28; @include placeHolderColor($neutral-500);
                        &, &:focus {color:$soa-blue;}
                    }
                }
            }
            .search-btn {display:flex; justify-content:center; align-items:center; padding:0; width:toRem(36); height:toRem(36); border:none; border-radius:toRem(18); background:$soa-blue; color:$white;
                &::before {display:block; position:relative; z-index:1; @include fas-family; font-size:toRem(12); content:"\f002";}
                span {display:none;}
                &:hover, &:focus {background:$soa-blue-lighter;}
            }
            #bs-nav-input {
                &:focus-within {
                    .nav-input.text-input-group {border:1px solid $soa-blue;}
                }
            }
            .text-input-flyout {right:0; top:100%; margin-top:toRem(6); min-width:toRem(300); border-radius:toRem(16);
                h2 {margin:toRem(14);}
                .results-panel {grid-template-columns:1fr 1fr; padding:0 toRem(16) toRem(16);
                    span {@include textUnderlineCustom();
                        &, &:link, &:active, &:visited {color:$soa-blue;}
                        &:hover, &:focus {color:$soa-link-hover;}
                        &:visited:hover {color:$soa-link-hover;}
                        &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$soa-blue;}
                        &, &:link, &:active, &:visited, &:hover, &:focus {@include textUnderlineCustom($offset:0.08em);}
                        //&:active, &:visited, &:hover, &:focus {text-decoration:none;}
                        &.disabled, &:disabled {color:$neutral-500;}
                    }
                }
            }
        }
    }
    
    @media all and (min-width:$screen_lg) {
        & {
            #mysoadropdown {max-width:toRem(328);}
        }
    }
    
    @media all and (min-width:$desktop_min) {
        & {
            .header-logo {
                img, svg {width:toRem(170);}
            }
        }
    }
}