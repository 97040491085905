﻿@import '_variables';

footer {background:$neutral-900; padding-top:toRem(16); padding-bottom:toRem(30); @include sourcesans;
    .footer__nav {color:$white;
        .footer__nav-list {padding:toRem(12) 0; border-top:1px solid $neutral-800;}
        .footer__nav-column {
            &:first-child {
                .footer__nav-list {border:none;}
            }
        }
        .footer__label {display:block; position:relative; padding-right:toRem(24);
            &::after {display:block; position:absolute; right:0; top:50%; transform:translateY(-50%); @include fas-family(); color:$white; font-size:toRem(12); content:"\f054";}
        }
        .footer__input {position:absolute; left:0; top:0; opacity:0;}
        .footer--main__headline {padding:toRem(12) 0; @include sourcesans_bold; font-size:toRem(12); letter-spacing:toRem(0.48);}
        .footer--main__list-wrapper {height:0; overflow:hidden; transition:height 1s ease;}
        .footer--main__list {margin:0; padding:0 0 toRem(12) 0; @include figtree_medium;}
        .footer--main__listItem {margin:0; padding:toRem(8) 0 0 0;
            &:first-child {padding-top:0;}
        }
        a {display:block;
            &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$neutral-500; font-size:toRem(15); text-decoration:none;}
        }
        .footer__input {
            &:checked {
                & ~ .footer--main__list-wrapper {height:auto;}
                & ~ .footer__label {
                    &::after {content:"\f078";}
                }
            }
        }
    }
    .footer__info {max-width:toRem(1392 + ($container-gutter-x * 2)); margin-left:auto; margin-right:auto;}
    .footer__info-section {position:relative; margin-bottom:toRem(24); padding:toRem(24) 0 0 0; overflow:hidden; border-radius:toRem(20); background:rgba($neutral-800, 30%);}
    .footer__info-bg {position:absolute; left:toRem(-110); top:toRem(-110);
        img, svg {fill:$neutral-900; width:toRem(260); height:toRem(260); transform:rotate(90deg);}
    }
    .footer__info-body {position:relative; z-index:1;}
    .footer__info-logo {text-align:center; margin-bottom:toRem(24);
        a {display:inline-block;
            &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {text-decoration:none;}
        }
        img, svg {fill:$white; width:toRem(170);}
    }
    .footer__copyright {margin-bottom:toRem(24); text-align:center; @include figtree;
        &, & a {color:$neutral-500; font-size:toRem(14); text-decoration:none;}
        a {
            &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$neutral-500; text-decoration:none;}
        }
    }
    .footer__social {margin-bottom:toRem(16);
        $footer-social-gutter:4;
        .row {@include customGutter($footer-social-gutter, "[class*=\"col-\"]"); justify-content:center;
            [class*="col-"] {padding-bottom:toRem($footer-social-gutter * 2);}
        }
    }
    .footer__social__link {display:flex; justify-content:center; align-items:center; width:toRem(40); height:toRem(40); border-radius:toRem(5); background:$neutral-900; font-size:toRem(16);
        &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$white; text-decoration:none;}
        img {max-width:toRem(16); max-height:toRem(16);}
    }
    .footer__links {margin-bottom:toRem(24);
        $footer-links-gutter:8;
        .row {@include customGutter($footer-links-gutter, "[class*=\"col-\"]"); justify-content:center; overflow:hidden; //flex-wrap:nowrap;
            [class*="col-"] {flex-grow:1; flex-shrink:1; position:relative; margin-right:toRem(3); padding-top:toRem(4); padding-bottom:toRem(4);
                &::before {display:block; position:absolute; left:toRem(-3); top:50%; transform:translateY(-50%); width:toRem(3); height:toRem(3); border-radius:toRem(2); background:$neutral-300; content:"";}
                &:first-child {
                    &::before {display:none; content:none;}
                }
                &:last-child {margin-right:0;}
            }
            a {display:block; padding:toRem($footer-links-gutter); text-align:center;
                &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$neutral-400; font-size:toRem(15); line-height:1.2; @include textUnderlineCustom($color:$neutral-700);}
            }
        }
    }
    .footer__certification {@include figtree;
        .row {
            [class*="col-"] {margin-bottom:toRem(10);}
            a {display:flex; justify-content:center; align-items:center; font-size:toRem(14); line-height:1.2; text-align:center;
                &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$neutral-400; @include textUnderlineCustom($color:$neutral-700);}
            }
        }
    }
    .footer__certification__link {}
    @media all and (min-width:$screen_lg_min) {
        & {padding-top:toRem(40); padding-bottom:toRem(64);}
        .footer__nav {margin-bottom:toRem(40);
            .footer__nav-list {border:none;}
            .footer__label {padding-right:0;
                &::after {display:none; content:none;}
            }
            .footer__input {display:none;}
            .footer--main__headline {margin-bottom:toRem(12);}
            .footer--main__list-wrapper {height:auto;}
        }
        .footer__info {padding-left:toRem($container-gutter-x); padding-right:toRem($container-gutter-x);}
        .footer__info-section {margin-bottom:toRem(32); padding:toRem(44) 0;}
        .footer__info-body {
            & > .row {justify-content:space-between; align-content:center; align-items:center;}
        }
        .footer__info-bg {left:toRem(-170); top:toRem(-90);
            img, svg {width:toRem(568); height:toRem(568); transform:rotate(0deg);}
        }
        .footer__info-logo {text-align:left; margin-bottom:toRem(24);
            img, svg {}
        }
        .footer__copyright {margin-bottom:0; text-align:left;}
        .footer__social {
            .row {justify-content:flex-end;}
        }
        .footer__social__link {}
        .footer__links {margin-bottom:0;
            .row {justify-content:flex-end; flex-wrap:nowrap; overflow:visible;
                [class*="col-"] {flex-grow:0; padding-top:0; padding-bottom:0;
                    &:last-child {
                        a {padding-right:0;}
                    }
                }
                a {padding-top:0; padding-bottom:0;}
            }
        }
        .footer__certification {
            $footer-cert-gutter:8;
            .row {@include customGutter($footer-cert-gutter, "[class*=\"col-\"]"); flex-wrap:nowrap; justify-content:center;
                [class*="col-"] {display:flex; justify-content:center; align-items:center; margin-bottom:0; flex-shrink:1; position:relative;
                    &::before {display:block; position:absolute; left:toRem(-1); top:50%; transform:translateY(-50%); width:toRem(3); height:toRem(3); border-radius:toRem(2); background:$neutral-700; content:"";}
                    &:first-child {
                        &::before {display:none; content:none;}
                    }
                }
                a {padding-left:toRem($footer-cert-gutter); padding-right:toRem($footer-cert-gutter); text-align:center;}
            }
        }
    }
    @media all and (min-width:$screen_xl_min) {
        .footer__nav {
            & > .container {
                & > .row {justify-content:center;}
            }
        }
    }
}