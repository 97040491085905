﻿@import '_variables';

@media print {
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;                 /* Firefox 48 – 96 */
        print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
    }
    @page {
        margin: 0cm;
    }
}