﻿@import '_variables';

.bf-dropdown-menu {position:absolute; top:100%; left:0; z-index:1000; display:none; padding:toRem(16); margin:0; list-style:none; background-color:$white; background-clip:padding-box; border-radius:toRem(24);
    &.show {display:block;}
    ul {
        &, & li {display:block; margin:0; padding:0; list-style-type:none;}
    }
}

.mobile-nav-list, .main-navbar {
    a {
        &, &:link, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
    }
    .bf-dropdown-menu {
        .l2__headline {display:flex; flex-direction:row; flex-wrap:nowrap; margin:0; padding:0; border-radius:toRem(26); font-weight:$fw_medium; line-height:1.1; letter-spacing:toRem(-0.34);
            > a, > span {flex-grow:1; flex-shrink:1; display:block; padding:toRem(16) toRem(8);}
            &, > a, > span {
                &, &:link, &:active, &:visited, &:hover, &:focus {color:$neutral-900; text-decoration:none;}
            }
            &, > a, > span {
                //&, &:link, &:visited {background-color:$white;}
            }
            &, > a, > span {
                &:hover, &:focus, &:focus-within {color:$soa-blue;}
            }
            &:active, &:hover, &:focus, &:focus-within {//background-color:$soa-blue-light;
                &, > a, > span {color:$soa-blue;}
            }
        }
    }

    & .l1__item, & .main-navbar__item {
    }

    @media all and (min-width:$header_nav_min)
    {
        .bf-dropdown-menu {
            .l2__headline {
                > a, > span {padding:toRem(16) toRem(24);}
                &, > a, > span {
                    &, &:link, &:active, &:visited, &:hover, &:focus {}
                }
                &, > a, > span {
                    //&, &:link, &:visited {background-color:$white;}
                }
                &, > a, > span {
                    &:hover, &:focus, &:focus-within {}
                }
                &:active, &:hover, &:focus, &:focus-within {background-color:$soa-blue-light;
                    &, > a, > span {}
                }
            }
        }
    }
}

.mobile-nav-list {
    .bf-dropdown-menu {
        .l2__headline {font-size:toRem(15);}
    }
    & .l1__item {
        @extend %hasLevelThree;
        &.has-level-3 {
            .bf-dropdown-menu {
                .l2__headline {
                    &.hasChildren:not(.collapsed) {
                        .l2__headline-trigger {background:none; border:none;
                            .icon, .fa, .fas, .far, .fab, .fal {
                                &::before {content:"\f078";}
                            }
                            &:focus, &:focus-visible {
                                .icon, .fa, .fas, .far, .fab, .fal {color:$soa-blue;}
                            }
                            &:not(:focus-visible) {outline:none;}
                        }
                    }
                }
            }
        }
    }
}

.main-navbar {
    .bf-dropdown-menu {
        .l2__headline {font-size:toRem(17);}
    }
    & .main-navbar__item {
        @extend %hasLevelThree;
    }
}

%hasLevelThree {
    &.has-level-3 {
        .bf-dropdown-menu {
            .l2__headline {
                > a, > span {}
                .l2__headline-trigger {flex-grow:0; flex-shrink:0; width:toRem(60); display:flex; justify-content:end; align-items:center; padding:0 toRem(8); color:$neutral-300; background:none; border:none;
                    &:focus, &:focus-visible {
                        .icon, .fa, .fas, .far, .fab, .fal {color:$soa-blue;}
                    }
                    &:not(:focus-visible) {outline:none;}
                }
                &.hasChildren:not(.collapsed) {
                    & {color:$soa-blue;}
                    > a, > span {color:$soa-blue;}
                    .l2__headline-trigger {color:$soa-blue;
                        .icon, .fa, .fas, .far, .fab, .fal {
                            //&::before {content:"\f078";}
                        }
                    }
                }
            }
        }

        @media all and (min-width:$header_nav_min)
        {
            & {
                .bf-dropdown-menu {
                    .l2__headline {
                        > a, > span {}
                        .l2__headline-trigger {justify-content:center;
                            .icon, .fa, .fas, .far, .fab, .fal {
                                //&::before {content:"\f054";}
                            }
                        }
                        &.hasChildren:not(.collapsed) {
                            & {background-color:$soa-blue-light;}
                        }
                    }
                }
            }
        }
    }
}

.mobile-nav-sidebar {position:fixed; left:0; top:0; height:0; overflow:hidden; width:100vw; width:100dvw; display:flex; background:rgba($neutral-900, 0.3);
    &.show {z-index:3; height:100%;
        .mobile-nav-overlay {display:block !important;}
    }
    .mobile-nav-overlay {display:none; width:toRem(72); flex-grow:0; flex-shrink:0; z-index:20000; pointer-events:auto;}

    .mobile-nav-wrap {flex-grow:1; flex-shrink:1; width:100%; height:100%; background-color:$white; pointer-events:auto; padding:toRem(24) 0; border-radius:0 toRem(24) toRem(24) 0; display:flex; flex-direction:column;
        & > div, & > ul {padding:0 toRem(24);}
        .mobile-nav-frame {flex-grow:1; width:100%; overflow-y:auto; @include scrollBarHack;}
        .mobile-nav-list {flex-grow:1; margin:0; padding:0; list-style-type:none; display:flex; flex-direction:column; width:100%; @include figtree;
            .search {display:flex !important; width:100%;
                .close {display:block; flex-grow:1; padding:10px 16px; color:#fff; text-align:right;}
                .search-link {padding:10px 16px;}
            }
    
            .l1__item {width:100%; display:block; border-bottom:1px solid $neutral-50;
                > .nav__link, > .utility__link {display:flex; flex-wrap:nowrap; justify-content:space-between; align-items:center; width:100%; padding:toRem(16) 0; color:$neutral-900; font-size:toRem(15); line-height:1.6; font-weight:$fw_medium;}
                &.bf-dropdown {
                    .dropdown-toggle {
                        &::after {color:$neutral-300; content:"\f054";}
                    }
                    &.open {//border-bottom:none;
                        .dropdown-toggle {color:$soa-blue;
                            &::after {color:$soa-blue; content:"\f078";}
                        }
                    }
                }
            }
    
            .bf-dropdown-menu {position:static; margin-bottom:toRem(16); background:$neutral-50;
                .l2__headline {//margin:toRem(12) 0 0; padding:0; text-transform:none;
                    //> a, > span {display:block; padding:toRem(12) 0; color:$neutral-600; font-size:toRem(14); line-height:1.5; font-weight:$fw_bold; letter-spacing:toRem(0.42); text-decoration:none;}
                }
                .header--nav__main--l2 {
                    //> .nav__link {display:block; padding:toRem(12) 0; color:$neutral-900; font-size:toRem(15); line-height:1.6; font-weight:$fw_medium; text-//decoration:none;}
                    //> .nav__span {display:block; padding:toRem(6) 0; color:$neutral-600; font-size:toRem(14); line-height:1.2; font-weight:$fw_bold; letter-spacing:toRem(0.42); text-decoration:none;}
                }
                .header--nav__main--l2--section {padding:0 toRem(24) toRem(12); border-bottom:1px solid $neutral-100;
                    > .nav__link {display:block; padding:toRem(12) 0; color:$neutral-900; font-size:toRem(14); line-height:1.6; font-weight:$fw_medium; text-decoration:none;}
                    > .nav__span {display:block; padding:toRem(6) 0; color:$neutral-600; font-size:toRem(14); line-height:1.2; font-weight:$fw_bold; letter-spacing:toRem(0.42); text-decoration:none;}
                }
                .nav--l1__dropdown--section {
                    &:first-child {
                        //.l2__headline {margin-top:0;}
                    }
                }
            }
        }
    }
    
    .utility__links {margin-top:toRem(12);
        .nav {margin:0 0 toRem(8) 0; padding:0 0 toRem(8) 0; border-bottom:1px solid $neutral-200;
            li {display:block;}
            a {display:flex; flex-wrap:nowrap; align-items:center; padding:toRem(16) 0; @include figtree_medium;
                &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$neutral-700; font-size:toRem(15); font-weight:$fw_medium; line-height:1.6; text-decoration:none;}
                @include iconClassnames {margin-right:toRem(10); font-size:toRem(10);}
            }
        }
    }
}

#headerNav {
    .main-navbar {display:flex; flex-wrap:nowrap; justify-content:flex-end; position:relative; margin:0; padding:0; list-style-type:none; @include figtree_medium;
        &__item {display:flex;
            $mysoadropdown-posY:20;
            > a {display:flex; flex-wrap:nowrap; justify-content:flex-start; align-items:center; padding:toRem(8) toRem(12); outline:none;
                &, &:link, &:active, &:visited, &:hover, &:focus, &:not(:disabled):not(.disabled):active {color:$neutral-900; font-size:toRem(14); line-height:1.2; text-decoration:none;}
                &::after {display:none; content:none;}
                .icon, .fa, .fas, .far, .fab, .fal {margin-left:toRem(10); color:$neutral-300; font-size:toRem(10);}
            }
            &.open {
                > a {position:relative; background:$soa-blue-light; border-radius:toRem(12);
                    &::before {display:block; position:absolute; left:50%; bottom:toRem(-$mysoadropdown-posY - 10); transform:translateX(-50%) rotate(45deg); width:toRem(14); height:toRem(14); border-radius:toRem(2); background:$white; content:""; z-index:1001; @include boxshadow(0, toRem(10), toRem(64), toRem(-10), rgba($neutral-800, 0.1));}

                    &, .icon, .fa, .fas, .far, .fab, .fal {color:$soa-blue;}
                }
            }
            > a:focus {position:relative; background:$soa-blue-light; border-radius:toRem(12);
                &, .icon, .fa, .fas, .far, .fab, .fal {color:$soa-blue;}
            }
            &:hover {}

            .bf-dropdown-menu {margin:0; margin-top:toRem($mysoadropdown-posY); padding:toRem(24); border:none; @include boxshadow(0, toRem(10), toRem(64), toRem(-10), rgba($neutral-800, 0.1));
                //.l2__headline {display:flex; flex-direction:row; flex-wrap:nowrap; margin:0; padding:0; border-radius:toRem(26); font-weight:$fw_medium; font-//size:toRem(17); line-height:1.1; letter-spacing:toRem(-0.34);
                //    > a, > span {flex-grow:1; flex-shrink:1; display:block; padding:toRem(16) toRem(24);}
                //    &, > a, > span {
                //        &, &:link, &:active, &:visited, &:hover, &:focus {color:$neutral-900; text-decoration:none;}
                //    }
                //    &, > a, > span {
                //        //&, &:link, &:visited {background-color:$white;}
                //    }
                //    &, > a, > span {
                //        &:hover, &:focus, &:focus-within {color:$soa-blue;}
                //    }
                //    &:active, &:hover, &:focus, &:focus-within {background-color:$soa-blue-light;
                //        &, > a, > span {color:$soa-blue;}
                //    }
                //}
            }

            & {position:relative;
                .bf-dropdown-menu {left:50%; transform:translateX(-50%); width:toRem(328);}
                &.has-level-3 {position:static;
                    .bf-dropdown-menu {left:0; transform:none; width:100%;
                        //.l2__headline {
                        //    > a, > span {}
                        //    .l2__headline-trigger {flex-grow:0; flex-shrink:0; width:toRem(60); display:flex; justify-content:center; align-items:center; color://$neutral-300;
                        //    }
                        //    &.hasChildren:not(.collapsed) {
                        //        & {background-color:$soa-blue-light; color:$soa-blue;}
                        //        > a, > span {color:$soa-blue;}
                        //        .l2__headline-trigger {color:$soa-blue;}
                        //    }
                        //}
                    }
                    $dropdown-gutter-x:32;
                    .nav--l1__dropdown--row {@include customGutter($dropdown-gutter-x, '.nav--l1__dropdown--col'); display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:space-between; align-items:stretch;
                        .nav--l1__dropdown--headers {flex:0 0 40%;}
                        .nav--l1__dropdown--section {flex:0 0 60%; border-left:1px solid $neutral-200;}
                    }
                }
            }

            .bf-dropdown-menu {
                .nav--l1__dropdown--section {
                    .header--nav__main--l2 {
                        > .nav__link {display:block; padding:toRem(16) 0 toRem(16) toRem(24); color:$neutral-900; font-size:toRem(17); line-height:1.1; font-weight:$fw_medium; letter-spacing:toRem(-0.34); text-decoration:none;}
                        > .nav__span {display:block; padding:toRem(6) 0 toRem(6) toRem(24); color:$neutral-600; font-size:toRem(14); line-height:1.2; font-weight:$fw_bold; letter-spacing:toRem(0.42); text-decoration:none;}
                    }
                }
            }
        }
        .main-navbar-dropdown {display:none;}
    }

    .mobile-header {background-color:$secondary-gray; display:flex; flex-wrap:nowrap; padding:8px;}
}