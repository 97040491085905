﻿@import '_variables';

.soa-search-input-wrap {position:relative; border-radius:toRem(4);
    .icon, .fa, .fas, .far, .fab {display:none;}
    input[type="text"] {display:block; margin-bottom:toRem(12); padding:toRem(12) toRem(16); width:100%; border:1px solid rgba($headline-color, 0.7); border-radius:toRem(4); background:$white;
        &, &:focus {outline:none;}
    }
    button[type="submit"] {}

    @media all and (min-width:$screen_md_min) {
        & {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center; padding:toRem(8); border:1px solid rgba($headline-color, 0.7); border-radius:toRem(4); background:$white;
            .icon, .fa, .fas, .far, .fab {display:block; flex-grow:0; flex-shrink:0; padding:0 toRem(8) 0 toRem(16); color:rgba($primary-blue-1, 0.7);}
            input[type="text"] {flex-grow:1; flex-shrink:1; flex-basis:100%; margin-bottom:0; border:none;}
            button[type="submit"] {flex-grow:0; flex-shrink:1; width:toRem(200);}
        }
    }
}

.soa-redesign-tabs {display:flex; flex-direction:row; flex-wrap:nowrap;}
.soa-redesign-tab-trigger {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center; padding:toRem(9) toRem(15); background:$white; border:5px solid $neutral-50; border-radius:toRem(26); color:$soa-blue !important;
    &.collapsed {background:$neutral-50; color:$neutral-900 !important;
        .soa-redesign-tab-trigger-arrow {
            .icon, .fa, .fas, .far, .fab {
                &::before {content:"\f054";}
            }
        }
    }
}
.soa-redesign-tab-trigger-wrap {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center;}
.soa-redesign-tab-trigger-icon {}
.soa-redesign-tab-trigger-text {flex-grow:1; flex-shrink:1; width:100%;}
.soa-redesign-tab-trigger-title {font-size:toRem(16); line-height:toEm(24,16); font-weight:$fw_semibold;}
.soa-redesign-tab-trigger-arrow {width:toRem(24); font-size:toRem(10.5); text-align:center;}
.soa-redesign-tabs {padding:toRem(12); background:$neutral-50; border-radius:toRem(38);
    .soa-redesign-tab-trigger {flex-grow:1; flex-shrink:1; justify-content:center; width:100%; max-width:33.333333%; padding:toRem(16); border:none; border-radius:toRem(26); @include boxshadow; line-height:1.2;
        &.collapsed {box-shadow:none;}
        &, &:link, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
    }
    &.dark-theme {
        .soa-redesign-tab-trigger {
            //&.collapsed {background:rgba(255, 255, 255, 0.45);}
        }
    }
    &.tab-fill {
        .soa-redesign-tab-trigger {width:100%; max-width:toRem(248);}
    }
    .soa-redesign-tab-trigger-wrap {}
    .soa-redesign-tab-trigger-icon {flex-grow:0; flex-shrink:0; align-self:center; display:flex; justify-content:center; align-items:center; padding-right:toRem(12); font-size:toRem(14); color:$soa-blue;
        img {height:toRem(14);}
    }
    .soa-redesign-tab-trigger-text {}
    .soa-redesign-tab-trigger-title {font-size:toRem(16); line-height:toEm(24,16);}
    .soa-redesign-tab-trigger-arrow {display:none;}
}
.soa-redesign-tab-content {
    //.soa-redesign-tab-trigger {display:block;}
    .soa-redesign-tab-trigger {margin-top:toRem(8); padding:toRem(9) toRem(15);
        &, &:link, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
    }
    .soa-redesign-tab-trigger-wrap {}
    .soa-redesign-tab-trigger-icon {@extend %blockIcon; margin-right:toRem(16); background:$white !important;
        img {height:toRem(18);}
    }
    .soa-redesign-tab-trigger-text {padding-right:toRem(12);}
    .soa-redesign-tab-trigger-title {}

    &[custom-mobile-behavior="true"] {display:flex; flex-direction:column;
        & > * {width:100%;}
        & > .soa-redesign-tab-trigger {order:2;
            &.collapsed {order:1;}
        }
        & > .soa-redesign-tab-card {order:1;
            &.show {order:3;}
        }
    }
}
.soa-redesign-tab-card {padding-left:toRem(18); padding-right:toRem(18); padding-top:toRem(24); padding-bottom:toRem(8); //@include dark-body; //overflow:hidden;
    &.card-fluid {padding-left:0; padding-right:0;}
    &, &.collapse, &.collapsing, &.collapse.show {transition:none;}
}

.soa-redesign-tab-card-listrow {
    h4 {margin:0; padding:0; margin-bottom:toRem(12);}
}
.soa-redesign-tab-card-listcol, .soa-redesign-tab-card-list {margin-top:toRem(24); padding-top:toRem(8);}
.soa-redesign-tab-card-list {}

@media all and (min-width:$screen_sm_min) {
    $listrow-gutter:36;
    .soa-redesign-tab-card-listrow {display:flex; flex-direction:row; flex-wrap:nowrap; margin-left:toRem(-$listrow-gutter); margin-right:toRem(-$listrow-gutter);}
    .soa-redesign-tab-card-listcol {flex-grow:1; flex-shrink:1; width:100%; padding-left:toRem($listrow-gutter); padding-right:toRem($listrow-gutter); border-left:1px solid $secondary-gray;
        &:first-child {border-left:none;}
    }
    .soa-redesign-tab-card-list {
        ul {@include autocolumn(2, 50%);
            li {}
        }
    }
}

@media all and (min-width:$screen_md_min) {
    //.soa-redesign-tabs {display:flex;}
    //.soa-redesign-tab-trigger {flex-grow:1; flex-shrink:1; width:100%; flex-basis:100%;}
    .soa-redesign-tab-content {
        //.soa-redesign-tab-trigger {display:none;}

        &[custom-mobile-behavior="true"] {display:block;
            & > * {width:auto;}
            & > .soa-redesign-tab-trigger {order:1;
                &.collapsed {order:1;}
            }
            & > .soa-redesign-tab-card {order:1;
                &.show {order:1;}
            }
        }
    }
            
    .soa-redesign-tab-card {padding-top:toRem(32);
        &.card-md-fluid {padding-left:0; padding-right:0;}
    }
}

.soa-redesign-pill {display:inline-block; padding:toRem(6) toRem(12); border-radius:toRem(13); background:$neutral-50; color:$soa-blue; font-weight:$fw_medium;
    &, & * {font-size:toRem(14); line-height:1;}
    &.pill-blue {background:$soa-theme-blue-light; color:$soa-theme-blue-dark;}
    &.pill-violet {background:$soa-theme-violet-light; color:$soa-theme-violet-dark;}
    &.pill-green {background:$soa-theme-green-light; color:$soa-theme-green-dark;}
    &.pill-purple {background:$soa-theme-purple-light; color:$soa-theme-purple-dark;}
    &.pill-yellow {background:$soa-theme-yellow-light; color:$soa-theme-yellow-dark;}
    &.pill-lime {background:$soa-theme-lime-light; color:$soa-theme-lime-dark;}
    &.pill-orange {background:$soa-theme-orange-light; color:$soa-theme-orange-dark;}
    &.pill-red {background:$soa-theme-red-light; color:$soa-theme-red-dark;}
}

$soa-redesign-card-gutter:8;
.soa-redesign-card-row {@include customGutter($soa-redesign-card-gutter, '.soa-redesign-card-col');}
.soa-redesign-card-col {display:flex; margin-bottom:toRem(12);}
.soa-redesign-card {display:flex; flex-direction:column; padding:toRem(24); background:none; border:1px solid $neutral-200; border-radius:toRem(16);
    &, &:link, &:visited, &:active, &:hover, &:focus, &:focus-within {text-decoration:none;}
    &, & > div {width:100%;}
    &:hover, &:focus, &:focus-within {@include boxshadow;
        //.btn {color:$primary-blue-1;}
    }
    .soa-redesign-card-body {}
    $card-innerrow-gutter:12;
    .row {display:flex; flex-direction:row; align-items:center; margin-left:toRem(-$card-innerrow-gutter); margin-right:toRem(-$card-innerrow-gutter); margin-bottom:toRem(12); font-weight:500;
        & > [class*="col-"] {padding-left:toRem($card-innerrow-gutter); padding-right:toRem($card-innerrow-gutter);}
        &.ul-horizontal-list > [class*="col-"] {position:relative;
            &::before {display:block; position:absolute; left:0; top:50%; margin-top:1px; width:toRem(4); height:toRem(4); border-radius:toRem(2); background:$primary-blue-1; transform:translate(-50%,-50%); content:"";}
            &:first-child {
                &::before {display:none; content:none;}
            }
        }
    }
    .soa-redesign-card-date {}
    .soa-redesign-card-info {margin-bottom:toRem(16);}
    //.soa-redesign-card-title {margin-bottom:toRem(16); font-size:toRem(18); line-height:1.5; font-weight:$fw_semibold;}
    .soa-redesign-card-edate {display:flex; flex-direction:row; flex-wrap:nowrap; //margin-top:toRem(8);
        span {display:block; flex-grow:1; flex-shrink:1; width:100%; color:$neutral-700; font-size:toRem(14); font-weight:$fw_medium; line-height:1;
            &.icon, &.fa, &.fas, &.far, &.fab {flex-grow:0; flex-shrink:0; width:auto; margin-right:toRem(12); color:$link-default; font-size:toRem(20);}
            &.fal {font-weight:300;}
            &.icon, &.far, &.fab {font-weight:400;}
            &.fa, &.fas, &.fad {font-weight:900;}
            &.icon-svg {flex-grow:0; flex-shrink:0; width:auto; margin-right:toRem(12);
                svg {height:toRem(20); fill:$link-default;}
            }
            &.w-uniform {width:toRem(20); margin-right:toRem(10); text-align:center;}
        }
    }
    .soa-redesign-card-summary {margin-bottom:toRem(6);}
    .soa-redesign-card-link {
        .btn {padding-left:0; padding-right:0; border:none;}
    }
}
a.soa-redesign-card {
    &, &:link, &:visited, &:active, &:hover, &:focus, &:focus-within {text-decoration:none;}
    &:hover, &:focus, &:focus-within {
        .btn {color:$primary-blue-1;}
    }
}

.soa-redesign-pagination {
    .soa-redesign-pagination-numrow {
        .btn {padding-left:toRem(12); padding-right:toRem(12); min-width:toRem(40);}
    }
}

@media all and (min-width:$screen_md_min) {
    .soa-redesign-card-col {margin-bottom:toRem($soa-redesign-card-gutter * 2);}
    .soa-redesign-card {padding:toRem(40); border-radius:toRem(24);}
    .soa-redesign-card-link {margin-top:auto; padding-top:toRem(24); border-top:1px solid $neutral-200;}
}

$pillnav-gutter:0; //4
.soa-redesign-pillnav {position:relative; display:flex; flex-direction:row; flex-wrap:nowrap;}
.soa-redesign-pill-trigger {
    &.collapsed {
    }
}
.soa-redesign-pill-trigger-wrap {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center;}
.soa-redesign-pill-trigger-text {flex-grow:1; flex-shrink:1; width:100%;}
.soa-redesign-pill-trigger-title {font-size:toRem(15); line-height:1.2; font-weight:$fw_semibold;}
.soa-redesign-pillnav {margin-left:toRem(-$pillnav-gutter); margin-right:toRem(-$pillnav-gutter); padding:toRem(16); background:$neutral-100; border-radius:toRem(16);
    .soa-redesign-pill-trigger {flex-grow:1; flex-shrink:1; width:100%; max-width:33.333334%; position:relative; display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center; margin-left:toRem($pillnav-gutter); margin-right:toRem($pillnav-gutter); padding:toRem(20); background-color:$white; border:none; border-radius:toRem(16); @include boxshadow; z-index:1; color:$neutral-900; text-align:left;
        &, &:link, &:active, &:visited, &:hover, &:focus {text-decoration:none; outline:none;}
        //.soa-redesign-pill-trigger-title {color:$white;}
        &.collapsed {background:none; box-shadow:none;
            //.soa-redesign-pill-trigger-title {color:$headline-color;}
        }
    }
    .soa-redesign-pill-trigger-wrap {}
    //.soa-redesign-pill-trigger-text {line-height:toRem(18);}
    //.soa-redesign-pill-trigger-title {font-size:toRem(14);}
}

.soa-redesign-dropnav {position:relative;}

.soa-redesign-dropnav-trigger {display:flex; flex-direction:row; flex-wrap:nowrap; position:relative; overflow:hidden; border-width:1px; border-style:solid; border-color:$neutral-200; border-radius:toRem(16) toRem(16) 0 0; @include boxshadow;
    &, &:link, &:active, &:visited, &:hover, &:focus {text-decoration:none; outline:none; transition:none;}
    .soa-redesign-dropnav-trigger-overlay {position:absolute; left:0; right:0; top:0; bottom:0; width:100%; height:100%; z-index:1;}
    .soa-redesign-dropnav-trigger-text {flex-grow:0; flex-shrink:0; width:100%; display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center; padding:toRem(20) toRem(52) toRem(20) toRem(20); background-color:$white; background-image:$icon-chevron-down-blue; background-position:right toRem(20) center; background-repeat:no-repeat; background-size:toRem(16); order:1; color:$neutral-900; font-size:toRem(16); font-weight:$fw_semibold; line-height:1.4;
        &, &:link, &:active, &:visited, &:hover, &:focus {text-decoration:none; outline:none;}
        &.collapsed {order:2;}
    }
    &.collapsed {border-color:$white; border-radius:toRem(16);
        .soa-redesign-dropnav-trigger-text {background-image:$icon-chevron-right-blue;}
    }
}

.soa-redesign-dropnav-select {border-radius:0 0 toRem(16) toRem(16); border:1px solid $neutral-200; border-top:none; background:$white; overflow:hidden; @include boxshadow; //position:absolute; left:0; right:0; top:auto; width:100%;
    &, &.collapse, &.collapsing, &.collapse.show {transition:none;}
    &.collapsed {
    }
}

.soa-redesign-dropnav-options {display:none; margin:0; padding:toRem(16) toRem(18); width:100%; border:none; border-top:1px solid $neutral-100; background:none; text-align:left;
    &:first-child {border-top:none;}
    .soa-redesign-dropnav-options-title {color:$neutral-900; font-size:toRem(16); font-weight:$fw_semibold; line-height:1.4;}
    &, &:link, &:active, &:visited, &:hover, &:focus {text-decoration:none; outline:none;}
    &.collapsed {display:block;}
}