﻿@import '_variables';
@import '_mixins';

%blockSection {padding-top:toRem($block-gutter-y); padding-bottom:toRem($block-gutter-y);
    @media all and (min-width:$screen_md_min) {
        & {padding-top:toRem($block-lg-gutter-y); padding-bottom:toRem($block-lg-gutter-y);}
    }
}
%blockSubtitle {margin-bottom:toRem(20); color:$soa-blue;
    @media all and (min-width:$screen_md_min) {
        & {margin-bottom:toRem(24);}
    }
}
%blockTitle {@extend %h1tag; font-weight:$fw_semibold;}
%blockSummary {margin-bottom:toRem(22);}
%blockIconBase {flex-grow:0; flex-shrink:0; align-self:center; display:flex; justify-content:center; align-items:center; width:toRem(24);
    img, svg {fill:$soa-blue; max-height:toRem(14); max-width:100%;}
    svg {fill:$soa-blue;}
    @media all and (min-width:$screen_md_min) {
        & {//align-self:flex-start;
            img, svg {}
        }
    }
}
%blockIcon {@extend %blockIconBase; width:toRem(40); height:toRem(40); border-radius:toRem(20); background:$secondary-light; font-size:toRem(18); color:$soa-blue; //background:$light-icons;
    img, svg {}
    svg {}
    @media all and (min-width:$screen_md_min) {
        & {width:toRem(50); height:toRem(50); border-radius:toRem(25); font-size:toRem(24);
            img, svg {height:toRem(24);}
        }
    }
}

.hero-carousel {padding-bottom:toRem(40);
    .hero-carousel-slick {opacity:0; transition:opacity 0.15s ease;
        &.slick-initialized {opacity:1;}
    }
    .hero-carousel-list {margin-bottom:0;}
    .hero-carousel-item {position:relative; padding-bottom:toRem(20); display:flex; align-items:flex-start;}
    .hero-carousel-item-row {
        & > [class*="col-"] {}
    }
    .hero-carousel-body {padding-top:toRem(40); padding-bottom:toRem(30);}
    .hero-carousel-subtitle {@extend %blockSubtitle; display:none; margin-bottom:toEm(32,14);}
    //.hero-carousel-title {@extend %blockTitle;}
    .hero-carousel-title {word-break:break-word; word-wrap:break-word;}
    .hero-carousel-summary {@extend %blockSummary;}
    .hero-carousel-ctas {margin-bottom:toRem(20);}
    .hero-carousel-isvisitor {font-weight:500;}
    .hero-carousel-image {
        img {width:100%;}
    }
    
    @include slickControls;

    .hero-carousel-indicator {}
    .hero-carousel-indicator-row {}

    @media all and (min-width:$screen_md_min) {
        & {padding-bottom:toRem(50);
            .hero-carousel-list {}
            .hero-carousel-item {padding-top:toRem(20); padding-bottom:toRem(20); align-items:center;
                & > .container {padding:0; overflow:hidden;}
            }
            .hero-carousel-item-row {
                //& > [class*="col-"] {position:static;}
            }
            .hero-carousel-body {position:relative; z-index:1; padding-left:toRem(30); padding-right:toRem(30); background:$white;}
            .hero-carousel-title {}
            .hero-carousel-summary {}
            .hero-carousel-ctas {}
            .hero-carousel-isvisitor {}
            .hero-carousel-image {display:flex; justify-content:center;
                img {width:auto;}
            }
            .hero-carousel-indicator {}
            .hero-carousel-indicator-row {
                .slick-dots {margin-left:0; margin-right:0;
                    li {
                        & > div {display:block;}
                        &::before {top:0; transform:none;}
                        &:first-child {
                            &::before {left:0;}
                        }
                        &:last-child {
                            &::before {right:0;}
                        }
                        &.slick-active {
                            &::before {}
                        }
                    }
                }
            }

            .hero-carousel-navigation {
                .slick-arrow-row {display:none;}
            }
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .hero-carousel-list {}
            .hero-carousel-item {align-items:stretch; overflow:hidden; padding-top:toRem(30); padding-bottom:toRem(30);
                & > .container {display:flex;}
            }
            .hero-carousel-item-row {flex-grow:1; flex-shrink:1; align-items:center;
                & > [class*="col-"] {padding-top:toRem(30); padding-bottom:toRem(30); overflow:hidden;}
            }
            .hero-carousel-subtitle {display:block;}
            .hero-carousel-image {display:block;
                img {width:100%;}
                //img {position:absolute; left:50%; top:50%; width:auto; min-width:100%; min-height:100%; max-width:none; transform:translate(-50%,-50%);}
            }
        }
    }
}

.join-block {@extend %blockSection; background-color:$neutral-50; position:relative;

    &-background-top {display:none; user-select:none; position:absolute; top:0;
        @media all and (min-width:$screen_md_min) {display:revert;}
    }

    &-background-bottom {user-select:none; position:absolute; bottom:0; right:0;}

    &-body {display:grid; row-gap:toRem(40); column-gap:toRem($gutter-x * 2); //justify-content:space-between;
        @media all and (min-width:$screen_md_min) {grid-template-columns:minmax(auto, toRem(300)) 50%; justify-content:space-between;}

        @media all and (min-width:$screen_lg_min) {grid-template-columns:minmax(auto, toRem(400)) 50%;}

        @media all and (min-width:$screen_xl_min) {grid-template-columns:minmax(auto, toRem(480)) 50%;}

        &-promo {display:flex; flex-direction:column; justify-content:flex-end; row-gap:toRem(20);

            &.no-top-image {justify-content:flex-start;}

            @media all and (min-width:$screen_xl_min) {row-gap:toRem(32);}

            &__subtitle {color:$soa-blue; font-weight:$fw_bold; margin-block-end:0;}

            &__title {@extend %h1tag; color:$neutral-900; font-weight:$fw_semibold; margin-block-end:0; text-wrap:balance;}

            &__summary {font-weight:$fw_medium;
                p:last-child {padding-block-end:0;}
            }

            &__ctas {display:flex; flex-wrap:wrap; gap:toRem(16);}
        }

        &-cards {display:flex; flex-direction:column; gap:toRem(12);
            @media all and (min-width:$screen_lg_min) {gap:toRem(16);}

            .join-block-card {border-radius:toRem(24); padding:toRem(20); background-color:$white; display:grid; grid-template-columns:minmax(auto, toRem(50)) 1fr; gap:toRem(20); transition:box-shadow 0.15s ease; text-decoration:none; //transition:transform 0.15s ease, box-shadow 0.15s ease;
                @media all and (min-width:$screen_xl_min) {grid-template-columns:minmax(auto, toRem(80)) 1fr;}

                @media all and (min-width:$screen_xl_min) {padding:toRem(40); gap:toRem(48);}

                &:hover {@include boxshadow;} //transform:translateX(toRem(10));

                &-icon {max-width:toRem(50);
                    img, svg {width:100%;}
                    @media all and (min-width:$screen_xl_min) {max-width:toRem(80);}
                }

                &-body {
                    &.no-image {grid-column:span 2;}

                    &__title {color:$neutral-900; font-weight:$fw_semibold; margin-block-end:toRem(16);}

                    &__summary {color:$neutral-800; font-weight:$fw_medium; padding:0; margin-block-end:toRem(16);}

                    &__link {color:$soa-blue; padding-block-start:toRem(16); border-top:1px solid $neutral-200;

                        .btn {text-align:left;

                            &:hover {cursor:pointer;}

                            span {vertical-align:0;}
                        }
                    }
                }
            }
        }
    }
}

.cta-block {@extend %blockSection;
    .cta-block-row {justify-content:space-between; align-items:center;}
    .cta-block-col-body {padding-bottom:toRem(30);}
    .cta-block-col-image {}
    .cta-block-image {margin-left:toRem(-$container-gutter-x); margin-right:toRem(-$container-gutter-x);
        img {width:100%;}
    }
    .cta-block-body {}
    .cta-block-subtitle {@extend %blockSubtitle;}
    .cta-block-title {@extend %blockTitle;}
    .cta-block-summary {@extend %blockSummary;}
    .cta-block-ctas {}
    @media all and (min-width:$screen_md_min) {
        & {
            .cta-block-row {}
            .cta-block-col-body {order:2; padding-bottom:0; max-width:toRem(680);}
            .cta-block-col-image {order:1;}
            .cta-block-col-image {}
            .cta-block-image {margin-left:0; margin-right:0;
                img {}
            }
        }
    }
}

.ctacard-block {@extend %blockSection;
    .ctacard-block-row {justify-content:space-between;}
    .ctacard-block-col-auxbody {align-self:center; margin-bottom:toRem(42);}
    .ctacard-block-col-ctablock {display:flex;}
    .ctacard-block-ctablockwrap {display:flex; position:relative; margin:0 toRem(34) toRem(16) 0; width:100%;
        --ctacard-block-bg:#{$soa-blue};
        --ctacard-block-bgimg:#{$ctacard-bg-mobile-darkblue};
        --ctacard-block-bgimg-bl:#{$ctacard-bg-desktop-bl-darkblue};
        --ctacard-block-bgimg-tr:#{$ctacard-bg-desktop-tr-darkblue};
        --ctacard-block-accent:#{$soa-theme-yellow-base};
        --ctacard-block-text:#{$white};
        //&.ctacard-block-theme-blue-yellowaccents, &.ctacard-block-theme-default {
        //    --ctacard-block-bg:#{$soa-blue};
        //    --ctacard-block-bgimg:#{$soa-blue-darker};
        //    --ctacard-block-accent:#{$soa-theme-yellow-base};
        //    --ctacard-block-text:#{$white};
        //}
        &.ctacard-block-theme-blue-limeaccents {
            --ctacard-block-accent:#{$soa-theme-lime-base};
        }
        &.ctacard-block-theme-blue {
            --ctacard-block-accent:#{$soa-aux-blue};
        }
        &.ctacard-block-theme-purple {
            --ctacard-block-bg:#{$soa-theme-purple-light};
            --ctacard-block-bgimg:#{$ctacard-bg-mobile-white};
            --ctacard-block-bgimg-bl:#{$ctacard-bg-desktop-bl-white};
            --ctacard-block-bgimg-tr:#{$ctacard-bg-desktop-tr-white};
            --ctacard-block-accent:#{$soa-theme-purple-base};
            --ctacard-block-text:#{$soa-theme-purple-dark};
        }
    }
    .ctacard-block-ctablock {display:flex; flex-direction:column; justify-content:center; padding:toRem(40); width:100%; background-color:var(--ctacard-block-bg); background-image:var(--ctacard-block-bgimg); background-position:right toRem(46) center; background-size:150%; background-repeat:no-repeat; border-radius:toRem(24); color:var(--ctacard-block-text); text-align:center; position:relative; z-index:1;
    }
    .ctacard-block-heading, .ctacard-block-subheading, .ctacard-block-ctas {width:100%;}
    .ctacard-block-heading {margin-bottom:toRem(16); width:100%;}
    .ctacard-block-subheading {font-weight:$fw_regular;
        &, & p, & span {font-size:toRem(14);}
    }
    .ctacard-block-ctas {}

    .ctacard-block-clip {display:block; position:absolute; right:toRem(-16); bottom:toRem(-16); width:99%; height:99%; overflow:hidden;
        svg {position:absolute; bottom:0; right:0; height:toRem(351); max-height:100%; fill:var(--ctacard-block-accent);}
    }
    .ctacard-block-clip-mobile {display:block;}
    .ctacard-block-clip-desktop {display:none;}

    &.hasBodyText {}

    @media all and (min-width:$screen_md_min) {
        & {
            .ctacard-block-ctablock {padding:toRem(48) toRem(40);}
            &:not(.hasBodyText) {
                .ctacard-block-ctablock {background-image:var(--ctacard-block-bgimg-bl), var(--ctacard-block-bgimg-tr); background-position:left toRem(-96) top toRem(64), right toRem(-58) bottom toRem(42); background-size:44.5%;}
            }
            .ctacard-block-col-auxbody {margin-bottom:0;}
            .ctacard-block-ctablockwrap {margin:0;}
            .ctacard-block-clip-mobile {display:none;}
            .ctacard-block-clip-desktop {display:block;}
            &.hasBodyText {
                &.textPositionRight {
                    .ctacard-block-clip {left:toRem(-16); right:auto;
                        svg {left:0; right:auto;}
                    }
                }
            }
        }
    }
    @media all and (min-width:$screen_lg_min) {
        & {
            .ctacard-block-col-body {}
            .ctacard-block-col-image {}
            .ctacard-block-image {}
        }
    }
    @media all and (min-width:$screen_xl_min) {
        & {
            .ctacard-block-clip {right:toRem(-24); bottom:toRem(-24);
                svg {}
            }
            &.hasBodyText {
                .ctacard-block-row {@include customGutter(40);}
                &.textPositionRight {
                    .ctacard-block-clip {left:toRem(-24);}
                }
            }
        }
    }
}

.faq-block {@extend %blockSection; background:$off-white;
    .faq-block-body {text-align:center;} //padding-bottom:toRem(30);
    .faq-block-subtitle {@extend %blockSubtitle;}
    .faq-block-title {@extend %blockTitle;}
    .faq-block-summary {margin-left:auto; margin-right:auto; max-width:toRem(680); @extend %blockSummary;}
    .faq-block-ctas {}
    .faq-block-qas {margin-left:auto; margin-right:auto; max-width:toRem(840);}
    .faq-block-qas-item {margin-top:toRem(12);
        &:first-child {margin-top:0;}
    }
    .faq-block-qas-item-btn {display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:space-between; align-items:center; padding:toRem(20) toRem(24); background:$soa-blue; border:1px solid $soa-blue; border-radius:toRem(16); font-size:toRem(16); font-weight:$fw_semibold; line-height:1.4; text-decoration:none; transition:background 0.15s ease; will-change:background; position:relative; z-index:1;
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
        & > span {display:block;}
        .faq-block-qas-item-label {flex-grow:1; flex-shrink:1; color:$white;}
        .icon, .fa, .fas, .far, .fab {flex-grow:0; flex-shrink:0; padding-left:toRem(16); width:toRem(34); font-size:toRem(15); text-align:center; color:$white;}
        &.collapsed {background:$white; border-color:$neutral-200;
            .faq-block-qas-item-label {color:$neutral-900;}
            .icon, .fa, .fas, .far, .fab {color:$neutral-300;
                &::before {content:"\f054";}
            }
        }
    }
    .faq-block-qas-item-card {padding:toRem(36) toRem(24) toRem(24) toRem(24); transform:translateY(toRem(-16)); background:$white; border-radius:0 0 toRem(16) toRem(16); color:$neutral-800; font-size:toRem(15); font-weight:$fw_medium; line-height:1.5;
        &.show {}
    }
    @media all and (min-width:$screen_md_min) {
        & {
            .faq-block-body {}
            .faq-block-ctas {}
            .faq-block-qas-item-btn {padding:toRem(20) toRem(40); border:none; border-radius:toRem(24); font-size:toRem(21);
                .icon, .fa, .fas, .far, .fab {padding-left:toRem(24); width:toRem(42);}
            }
            .faq-block-qas-item-card {padding:toRem(32) toRem(40); transform:none; background:none; border-radius:0; font-size:toRem(18); line-height:1.6;}
        }
    }
}

.flexible-block {@extend %blockSection;
    .flexible-block-row {justify-content:space-between;}
    .flexible-block-col-body {padding-bottom:toRem(30);}
    .flexible-block-col-content {display:flex; justify-content:center;}
    .flexible-block-body {}
    .flexible-block-subtitle {@extend %blockSubtitle;}
    .flexible-block-title {@extend %blockTitle;}
    .flexible-block-summary {@extend %blockSummary; margin-bottom:0;}
    .flexible-block-ctas {margin-bottom:toRem(20);}
    .flexible-block-link {
        //.btn {padding-left:0; padding-right:0; border:none;}
    }
    .flexible-block-image {display:flex; width:100%; justify-content:center; align-items:center;
        img {width:100%;}
    }
    .flexible-block-quote {position:relative; z-index:1; display:flex; padding:0; width:100%; justify-content:center; align-items:center;}
    .flexible-block-quote-comp {padding-top:toRem(24); width:100%;}
    .flexible-block-quote-bg {display:none;}
    .flexible-block-quote-ldquo {display:none;}
    .flexible-block-quote-wrap {position:relative; width:100%; padding:0 toRem(22) toRem(32) toRem(22); background:$white; border-radius:toRem(24); @include boxshadow(0, toRem(10), toRem(56), 0, rgba($grey-45,0.1)); z-index:1;}
    //.flexible-block-quote-icon {position:absolute; left:toRem(11); top:toRem(26); font-size:toRem(20); color:$primary-blue-1;}
    .flexible-block-quote-icon {display:none;}
    .flexible-block-quote-list {}
    .flexible-block-quote-slider {opacity:0; transition:opacity 0.15s ease; min-height:toRem(120);
        .slick-list {overflow:visible; overflow-x:hidden; overflow-x:clip; padding-top:toRem(24); transform:translateY(toRem(-24));}
        .flexible-block-quote-item {height:1px;}
        &.slick-initialized, &.inEditMode {opacity:1; min-height:auto;
            .flexible-block-quote-item {height:auto;}
        }
        .slick-track {
            .slick-slide {align-items:flex-start;}
        }
    }
    .flexible-block-quote-item {display:flex; flex-direction:column; padding-left:toRem(10); padding-right:toRem(10); color:$neutral-900; font-size:toRem(16); font-weight:$fw_medium; line-height:1.5;}
    .flexible-block-quote-text {width:100%;}
    .flexible-block-quote-author {margin-bottom:toRem(10); width:100%;
        .row {margin-left:toRem(-12); margin-right:toRem(-12); flex-wrap:nowrap;
            & > [class*="col-"] {padding-left:toRem(12); padding-right:toRem(12);}
        }
    }
    .flexible-block-quote-author-img-wrap {position:relative; transform:translateY(toRem(-14));
        & > svg {position:absolute; left:toRem(-10); top:toRem(-10); width:toRem(86); fill:$soa-blue;}
    }
    .flexible-block-quote-author-img {position:relative; width:toRem(110); height:toRem(140); border-radius:toRem(14); overflow:hidden; z-index:1;
        img {position:relative; left:50%; transform:translateX(-50%); min-width:toRem(110); max-width:none; height:toRem(140);} //@include boxshadow(0, toRem(10), toRem(56), 0, rgba($grey-45,0.1));
        //.icon, .fa, .fas, .far, .fab {display:flex; justify-content:center; align-items:center; width:toRem(52); height:toRem(52); border-radius:toRem(26); /background:/$secondary-light; color:$body-copy-color; font-size:toRem(23);
        //    &::before {display:block;}
        //}
    }
    .flexible-block-quote-author-text {padding:toRem(26) 0;}
    .flexible-block-quote-author-ldquo {display:block; margin-bottom:toRem(12); line-height:1;
        svg {width:toRem(18); fill:$soa-blue;}
    }
    .flexible-block-quote-author-name {font-size:toRem(18); font-weight:$fw_semibold;}
    .flexible-block-quote-author-title {font-size:toRem(15);}
    .flexible-block-quote-navigation {padding-left:toRem(10); padding-right:toRem(10);
        @include slickControls;
        &.inEditMode {
            .slick-arrow.slick-hidden {display:flex;}
        }
    }
    //.flexible-block-quote-image {position:absolute; left:toRem(($container-gutter-x - $gutter-x) * -1); right:toRem(($container-gutter-x - $gutter-x) * -1); /top:100px;/ bottom:0; overflow:hidden;
    //    img {min-width:100%; min-height:100%; max-width:none; position:absolute; left:50%; top:50%; transform:translate(-50%, -50%);}
    //}
    $metrics-gutter:4;
    .flexible-block-metrics {display:flex; flex-direction:column; justify-content:center;}
    .flexible-block-metrics-list {width:100%;
        & > .row {align-content:stretch; justify-content:center; @include customGutter($metrics-gutter);}
    }
    .flexible-block-metrics-image {margin-bottom:toRem(16); width:100%; text-align:center;}
    .flexible-block-metrics-subtext {width:100%; margin-top:toRem(16); color:$neutral-700; font-size:toRem(12); line-height:toEm(18,12); text-align:center;}
    .flexible-block-metrics-col {display:flex; margin-top:toRem($metrics-gutter * 2); max-width:toRem(200 + ($metrics-gutter * 2));
        &:nth-child(1), &:nth-child(2) {margin-top:0;}
    }
    .flexible-block-metrics-col {
        &:nth-child(4n + 1) {
            .flexible-block-metrics-item {background:$soa-theme-lime-light; color:$soa-theme-lime-dark;}
        }
        &:nth-child(4n + 2) {
            .flexible-block-metrics-item {background:$soa-theme-orange-light; color:$soa-theme-orange-dark;}
        }
        &:nth-child(4n + 3) {
            .flexible-block-metrics-item {background:$soa-theme-yellow-light; color:$soa-theme-yellow-dark;}
        }
        &:nth-child(4n + 4) {
            .flexible-block-metrics-item {background:$soa-theme-purple-light; color:$soa-theme-purple-dark;}
        }
    }
    .flexible-block-metrics-item {padding:toRem(25) toRem(12); width:100%; border-radius:toRem(16); text-align:center;}
    .flexible-block-metrics-super {margin-bottom:toRem(4); font-size:toRem(10); line-height:toEm(11,10); font-weight:$fw_bold; letter-spacing:toRem(0.49); text-transform:uppercase;}
    .flexible-block-metrics-highlight {margin-bottom:toRem(4); font-size:toRem(37); line-height:toEm(41,37); font-weight:$fw_semibold;}
    .flexible-block-metrics-text {font-size:toRem(13); line-height:toEm(14.5,13); font-weight:$fw_semibold;}
    .flexible-block-video {display:flex; flex-direction:column; justify-content:center; width:100%;}
    .flexible-block-video-item {width:100%;
        video {width:100%;}
    }
    .flexible-block-video-frame {
        &.hasIframe {position:relative; padding-bottom:56.25%;}
        iframe {position:absolute; left:0; right:0; top:0; bottom:0; width:100%; height:100%;}
    }
    @media all and (min-width:$screen_md_min) {
        & {
            .flexible-block-row {flex-wrap:nowrap;
                & > [class*="col-"] {}
                & > .flexible-block-col-content {flex-shrink:1;}
                //& > .flexible-block-col-body {flex:0 0 39.025%; max-width:39.025%;}
                //& > .flexible-block-col-content {flex:0 0 47.155%; max-width:47.155%;}
            }
            .flexible-block-col-body {padding-bottom:0;}
            .flexible-block-col-image {}
            .flexible-block-col-image {}
            .flexible-block-image {}
            .flexible-block-col-content {
                //.flexible-block-quote-image {top:0; left:33%; right:toRem($gutter-x);}
                //&.order-md-1 {
                //    .flexible-block-quote {margin-left:auto; margin-right:0;}
                //    .flexible-block-quote-image {left:toRem($gutter-x); right:33%;}
                //}
            }
            .flexible-block-quote {padding:0;}
            .flexible-block-quote-comp {position:relative; padding:toRem($container-gutter-x) 0;}
            .flexible-block-quote-bg {display:block; position:absolute; right:toRem(-$container-gutter-x); top:0; height:99%; overflow:hidden;
                svg {height:toRem(400); fill:$soa-blue;}
                &.position-left {left:toRem(-$container-gutter-x); right:auto;}
            }
            .flexible-block-quote-ldquo {display:block; position:absolute; left:toRem(24); top:toRem(48); width:toRem(18); line-height:1;
                svg {width:100%; fill:$soa-blue;}
            }
            .flexible-block-quote-wrap {padding:toRem(52) toRem(42);}
            .flexible-block-quote-slider {
                .slick-list {padding-top:0; transform:none;}
            }
            .flexible-block-quote-item {font-size:toRem(22); min-height:toRem(305);}
            .flexible-block-quote-text {order:1; margin-bottom:toRem(33);}
            .flexible-block-quote-author {order:2; margin-bottom:toRem(33);
                & > .row {align-items:center;}
            }
            
            .flexible-block-quote-author-img-wrap {transform:none;}
            .flexible-block-quote-author-img {width:toRem(84); height:toRem(84); border-radius:0;
                svg {position:static; left:0; top:0; width:toRem(84); fill:none;
                    image {width:100%; height:100%;}
                }
                img {min-width:100%;}
            }
            .flexible-block-quote-author-text {padding:0;}
            .flexible-block-quote-author-name {line-height:1.6;}
            .flexible-block-quote-author-title {color:$neutral-700; font-size:toRem(14); font-weight:$fw_regular;}
            .flexible-block-quote-author-ldquo {display:none;}
        }
    }
    @media all and (min-width:$screen_lg_min) {
        & {
            .flexible-block-row {
                //& > [class*="col-"] {max-width:toRem(480 + ($gutter-x * 2));}
                .flexible-block-col-body {max-width:toRem(450 + ($gutter-x * 2));}
                .flexible-block-col-content {max-width:toRem(550 + ($gutter-x * 2));}
            }
            $metrics-gutter:5;
            .flexible-block-metrics-image {margin-bottom:toRem(20);}
            .flexible-block-metrics-subtext {margin-top:toRem(20); font-size:toRem(13); line-height:toEm(14.3,13);}
            .flexible-block-metrics-col {margin-top:toRem($metrics-gutter * 2); max-width:toRem(200 + ($metrics-gutter * 2));
                &:nth-child(1), &:nth-child(2) {margin-top:0;}
            }
            .flexible-block-metrics-item {padding:toRem(30) toRem(12); border-radius:toRem(24);}
            .flexible-block-metrics-super {margin-bottom:toRem(6); font-size:toRem(13); line-height:toEm(14.3,13); letter-spacing:toRem(0.65);}
            .flexible-block-metrics-highlight {margin-bottom:toRem(6); font-size:toRem(48); line-height:toEm(52.8,48); letter-spacing:toRem(-1.44);}
            .flexible-block-metrics-text {font-size:toRem(16); line-height:toEm(17.6,16); letter-spacing:toRem(0.48);}
        }
    }
    @media all and (min-width:$screen_xl_min) {
        & {
            .flexible-block-quote-comp {padding:toRem(32) 0;}
            .flexible-block-quote-bg {right:toRem(-32);
                &.position-left {left:toRem(-32); right:auto;}
            }
            .flexible-block-quote-ldquo {left:toRem(56); top:toRem(68);}
            .flexible-block-quote-wrap {padding:toRem(72) toRem(86);}
        }
    }
}

.hero-general {background-color:$neutral-100; padding-block:toRem(40); padding-inline:toRem(24); position:relative;
    @media all and (min-width:$screen_md_min) {padding-block:toRem(64); padding-inline:toRem(40);}

    @media all and (min-width:$screen_xl_min) {padding-block-start:toRem(132); padding-block-end:toRem(92); padding-inline:toRem(120);}

    .hero-general-image {display:none;
        @media all and (min-width:$screen_lg_min) {display:revert; position:absolute; right:0; top:0;}
    }

    .hero-general-body {text-align:center; max-width:toRem(680);}

    .hero-general-subtitle {@extend %blockSubtitle;}

    .hero-general-summary {@extend %blockSummary; margin-inline:auto; margin-block-end:0;
            p {font-weight:$fw_medium;

            &:last-child {padding:0;}
        }
    }

    .hero-general-ctas {margin-inline:auto; margin-block-start:toRem(20); display:flex; flex-direction:row; gap:toRem(16); justify-content:center; flex-wrap:wrap;}

    .hero-general-title {@extend %blockTitle; margin-block-end:toRem(20);
        @media all and (min-width:$screen_lg_min) {margin-block-end:toRem(32);}
    }
}

.nextsteps-block {@extend %blockSection; //background:$off-white;
    $nextsteps-gutter:8;
    .nextsteps-block-body {padding-bottom:toRem(10);
        &.text-center {
            .nextsteps-block-summary {margin-left:auto; margin-right:auto;}
        }
        &.text-right {
            .nextsteps-block-summary {margin-left:auto;}
        }
    }
    .nextsteps-block-subtitle {@extend %blockSubtitle;}
    .nextsteps-block-title {@extend %blockTitle;}
    .nextsteps-block-summary {max-width:toRem(680); @extend %blockSummary;}
    .nextsteps-block-list {margin-left:toRem(-$nextsteps-gutter); margin-right:toRem(-$nextsteps-gutter); padding-top:toRem(12);
        & > div {display:flex; margin-bottom:toRem($nextsteps-gutter * 2); padding-left:toRem($nextsteps-gutter); padding-right:toRem($nextsteps-gutter);}
    }
    .nextsteps-block-item {width:100%; background:$white; border:1px solid $neutral-200; border-radius:toRem(16); overflow:hidden;}
    .nextsteps-block-item-icon {@extend %blockIcon; margin-right:toRem(16); background:none; border-radius:0; height:auto;
        img, svg {width:100%; height:auto; max-height:none;}
    }
    .nextsteps-block-item-image {position:relative; margin-right:0; width:100%; max-width:none; background:$white; overflow:hidden; //  margin-right:toRem(24); width:35.474%; max-width:toRem(180);width:toRem(116);
        &::before {display:block; padding-bottom:75%; content:"";}
        img, svg {min-width:100%; min-height:100%; object-fit:cover; position:absolute; left:50%; top:50%; transform:translate(-50%, -50%);}
    }
    .nextsteps-block-item-text {flex-grow:1; flex-shrink:1; display:flex; flex-direction:column; justify-content:center; width:100%;}
    .nextsteps-block-item-title {margin-bottom:0; color:$neutral-900; font-size:toRem(21); font-weight:$fw_semibold; line-height:1.25;}
    .nextsteps-block-item-summary {margin-bottom:toRem(20); color:$neutral-800; font-size:toRem(15); font-weight:$fw_medium;}
    .nextsteps-block-item-label {display:block; margin-bottom:toRem(20); font-weight:$fw_medium; @include textUnderlineCustom;
        .icon, .fa, .fas, .far, .fab, .fal {margin-left:toRem(10); font-size:0.75em; line-height:toRem(16);}
    }
    //.nextsteps-block-item-arrow {display:flex; align-items:center; padding-right:toRem(18);}
    .nextsteps-block-item-title-row {display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:flex-start; align-items:center; margin-bottom:toRem(20);
        .nextsteps-block-item-title {flex-grow:1; flex-shrink:1;}
        .nextsteps-block-item-icon {flex-grow:0; flex-shrink:0;}
    }

    //.nextsteps-block-ctas {margin-top:toRem(24);}
    .nextsteps-block-ctas {margin-bottom:toRem(20);}

    .nextsteps-block-item {
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
        //&:hover, &:focus, &:focus-within {box-shadow:4px 4px 10px rgba(0,0,0,0.15);}
    }

    &:not(.nextsteps-theme-none)
    {
        --nextsteps-theme-bg:#{$soa-theme-blue-light};
        --nextsteps-theme-color:#{$soa-theme-blue-dark};
        &.nextsteps-theme-blue {--nextsteps-theme-bg:#{$soa-theme-blue-light}; --nextsteps-theme-color:#{$soa-theme-blue-dark};}
        &.nextsteps-theme-violet {--nextsteps-theme-bg:#{$soa-theme-violet-light}; --nextsteps-theme-color:#{$soa-theme-violet-dark};}
        &.nextsteps-theme-green {--nextsteps-theme-bg:#{$soa-theme-green-light}; --nextsteps-theme-color:#{$soa-theme-green-dark};}
        &.nextsteps-theme-purple {--nextsteps-theme-bg:#{$soa-theme-purple-light}; --nextsteps-theme-color:#{$soa-theme-purple-dark};}
        &.nextsteps-theme-yellow {--nextsteps-theme-bg:#{$soa-theme-yellow-light}; --nextsteps-theme-color:#{$soa-theme-yellow-dark};}
        &.nextsteps-theme-lime {--nextsteps-theme-bg:#{$soa-theme-lime-light}; --nextsteps-theme-color:#{$soa-theme-lime-dark};}
        &.nextsteps-theme-orange {--nextsteps-theme-bg:#{$soa-theme-orange-light}; --nextsteps-theme-color:#{$soa-theme-orange-dark};}
        &.nextsteps-theme-red {--nextsteps-theme-bg:#{$soa-theme-red-light}; --nextsteps-theme-color:#{$soa-theme-red-dark};}

        .nextsteps-block-item {
            &:hover, &:focus, &:focus-within {background:var(--nextsteps-theme-bg);
                //.nextsteps-block-item-title {@include textUnderlineCustom;}
                .nextsteps-block-item-text, .nextsteps-block-item-title, .nextsteps-block-item-summary, .nextsteps-block-item-label {color:var(--nextsteps-theme-color);}
            }
        }
    }
    &.nextsteps-block-type-icon {
        .nextsteps-block-item {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:stretch; padding:toRem(32) toRem(32) toRem(12) toRem(32);}
    }
    &.nextsteps-block-type-image {
        $nextsteps-gutter:18;
        .nextsteps-block-list {margin-left:toRem(-$nextsteps-gutter); margin-right:toRem(-$nextsteps-gutter);
            & > div {margin-bottom:toRem($nextsteps-gutter * 2); padding-left:toRem($nextsteps-gutter); padding-right:toRem($nextsteps-gutter);}
        }
        .nextsteps-block-item {padding:0;}
        //.nextsteps-block-item-title {margin-bottom:toRem(20);}
        .nextsteps-block-item-text {padding:toRem(18) toRem(16);}
        //.nextsteps-block-item-summary {display:none;}
        a.nextsteps-block-item {
            &:hover, &:focus, &:focus-within {
                .nextsteps-block-item-title {@include textUnderlineCustom;}
            }
        }
    }
    @media all and (min-width:$screen_lg_min) {
        & {
            .nextsteps-block-item {}
            .nextsteps-block-body {}
            .nextsteps-block-title {}
            .nextsteps-block-list {padding-top:toRem(24);}
            .nextsteps-block-item {flex-direction:column; flex-wrap:nowrap; align-items:center; padding:toRem(40) toRem(40) toRem(24) toRem(40); border-radius:toRem(24); text-decoration:none; //transition:transform .25s ease;
                //&:hover, &:focus, &:focus-within {transform:translateY(toRem(-24));}
            }
            .nextsteps-block-item-icon {width:toRem(80); margin:0 0 toRem(48) 0;
                img, svg {height:toRem(80); max-height:toRem(80);}
            }
            //.nextsteps-block-item-image {margin-right:0; width:100%; max-width:none;}
            .nextsteps-block-item-text {justify-content:flex-start; width:100%;}
            .nextsteps-block-item-title {margin-bottom:toRem(16); width:100%; font-size:toRem(24); line-height:1.3; letter-spacing:toRem(-0.24);}
            .nextsteps-block-item-summary {display:block; margin-bottom:toRem(16); width:100%; font-size:toRem(16);}
            .nextsteps-block-item-label {margin-bottom:toRem(16); margin-top:auto; width:100%;}
            //.nextsteps-block-item-arrow {display:none;}
            .nextsteps-block-item-title-row {flex-wrap:wrap; align-items:flex-start; margin-bottom:0;
                .nextsteps-block-item-title {flex-grow:0; flex-shrink:0; width:100%;}
                .nextsteps-block-item-icon {}
            }

            //.nextsteps-block-ctas {margin-top:toRem(48);}
            .nextsteps-block-ctas {margin-bottom:toRem(24);}
            
            &.nextsteps-block-type-icon {
            }
            &.nextsteps-block-type-image {
                .nextsteps-block-item-text {padding:toRem(48);}
                .nextsteps-block-item-summary {display:block;}
            }
        }
    }
}

.newsblock-block {@extend %blockSection; overflow:hidden;
    .newsblock-block-header {margin-bottom:toRem(12);}
    .newsblock-block-subtitle {@extend %blockSubtitle;}
    .newsblock-block-title {@extend %blockTitle;}
    .newsblock-block-more {margin-top:toRem(32);}
    .newsblock-block-list {}
    .newsblock-block-item {margin-bottom:toRem(16); color:$neutral-900;}
    .newsblock-block-item-card {display:flex; flex-direction:column; background:$white; border:1px solid $neutral-200; border-radius:toRem(16); color:$neutral-900; overflow:hidden; transition:box-shadow .25s ease; will-change:box-shadow; //transition:transform .25s ease, box-shadow .25s ease; will-change:transform, box-shadow;
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
        &, & > div {width:100%;}
        &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$neutral-900;}
        &:hover, &:focus, &:focus-within {@include boxshadow(0, toRem(10), toRem(64), toRem(-10), rgba($neutral-800, 0.1)); //transform:translateY(toRem(-10));
            .newsblock-block-item-title {color:$soa-blue; @include textUnderlineCustom;}
            .btn {color:$neutral-900;}
        }
    }
    .newsblock-block-item-image {display:none;
        img {width:100%;}
    }
    .newsblock-block-item-body {padding:toRem(24);}
    $subheader-gutter:8;
    .newsblock-block-item-subheader {display:flex; flex-direction:row; align-items:center; @include customGutter($subheader-gutter); margin-bottom:toRem(12); color:$neutral-700; font-size:toRem(14); font-weight:$fw_semibold;
        & > div {//position:relative;
            //&::before {display:block; position:absolute; left:0; top:50%; margin-top:1px; width:toRem(4); height:toRem(4); border-radius:toRem(2); background:/$primary-/blue-1; transform:translate(-50%,-50%); content:"";}
            //&:first-child {
            //    &::before {display:none; content:none;}
            //}
        }
    }
    .newsblock-block-item-type {}
    .newsblock-block-item-date {padding:toRem(2) 0;}
    .newsblock-block-item-title {margin-bottom:toRem(16); font-size:toRem(18); line-height:1.5; font-weight:$fw_semibold;}
    .newsblock-block-item-summary {display:none; margin-bottom:toRem(6); font-size:toRem(16); font-weight:$fw_medium; line-height:1.5;}
    .newsblock-block-item-link {
        .btn {padding-left:0; padding-right:0; border:none; font-size:toRem(16);
            &:not(:disabled):not(.disabled) {cursor:pointer;}
            &.btn-cta {
                .icon, .fa, .fas, .far, .fab, .fal {line-height:toRem(16);}
            }
        }
    }
    .newsblock-block-item {
        &.isFeatured {margin-bottom:toRem(32);
            .newsblock-block-item-card {
                &, &:hover, &:focus, &:focus-within {transform:none;}
            }
            .newsblock-block-item-image {display:block;}
            .newsblock-block-item-title {margin-bottom:toRem(8); font-size:toRem(21); line-height:1.25;}
            .newsblock-block-item-summary {display:block;}
        }
    }
    //.newsblock-block-slick {opacity:0; transition:opacity 0.15s ease; padding-right:toRem(30 + $gutter-x);
    //    .newsblock-block-item {height:1px;}
    //    &.slick-initialized, &.inEditMode {opacity:1; min-height:auto;
    //        .newsblock-block-item {height:auto;}
    //    }
    //    $slick-gutter:$gutter-x; //12
    //    .slick-list {overflow:visible;}
    //    .slick-track {margin-left:toRem(-$slick-gutter); margin-right:toRem(-$slick-gutter);
    //        .slick-slide {align-items:stretch; padding-left:toRem($slick-gutter); padding-right:toRem($slick-gutter);}
    //    }
    //}

    //@include slickControls;

    //.newsblock-block-indicator {}
    //.newsblock-block-indicator-row {}

    @media all and (min-width:$screen_md_min) {
        & {
            .newsblock-block-header {margin-bottom:toRem(56); text-align:center;}
            .newsblock-block-more {margin-top:toRem(64);}
            $list-gutter:8; //12
            .newsblock-block-list {display:flex; flex-direction:row; flex-wrap:wrap; margin-left:toRem(-$list-gutter); margin-right:toRem(-$list-gutter);}
            .newsblock-block-item-card {}
            .newsblock-block-item-summary {margin-bottom:toRem(20);}
            .newsblock-block-item {
                &:not(.isFeatured) {
                    .newsblock-block-item-card {flex-direction:row;}
                    .newsblock-block-item-body {display:flex; flex-direction:column;}
                    .newsblock-block-item-subheader {width:100%;}
                    .newsblock-block-item-title {width:100%;}
                    .newsblock-block-item-summary {width:100%;}
                    .newsblock-block-item-link {width:100%; margin-top:auto; padding-top:toRem(12); border-top:1px solid $neutral-200;}
                }
            }
            .newsblock-block-item {flex-basis:50%; width:50%; display:flex; margin-bottom:toRem($list-gutter * 2); padding-left:toRem($list-gutter); padding-right:toRem($list-gutter);
                &.isFeatured {flex-basis:100%; width:100%;
                    .newsblock-block-item-card {flex-shrink:0; flex-grow:1; flex-direction:row; justify-content:space-between;
                        & > div {}
                        &:hover, &:focus {}
                    }
                    .newsblock-block-item-image {flex-basis:33.333333%; width:33.333333%;
                        img {width:100%; height:100%; max-width:none; object-fit:cover;}
                    }
                    .newsblock-block-item-body {align-self:center; flex-basis:66.666667%; width:66.666667%; max-width:toRem(680); padding:toRem(32);}
                    //.newsblock-block-item-title {font-size:toRem(30); letter-spacing:normal;}
                }
            }
            .newsblock-block-item-summary {display:block;}
            //.newsblock-block-slick {opacity:1; padding-right:0;
            //    .newsblock-block-item {height:auto;}
            //}
            //.newsblock-block-navigation {display:none;}
        }
    }
    @media all and (min-width:$screen_lg_min) {
        & {
            .newsblock-block-item-subheader {margin-bottom:toRem(20);}
            .newsblock-block-item-title {margin-bottom:toRem(20); font-size:toRem(21); line-height:1.4;}
            .newsblock-block-item-summary {margin-bottom:toRem(20);}
            .newsblock-block-item {flex-basis:33.333333%; width:33.333333%;
                //&:first-child {flex-basis:100%; width:100%;}
                &.isFeatured {margin-bottom:toRem(80);
                    .newsblock-block-item-image {flex-basis:50%; width:50%;}
                    .newsblock-block-item-body {flex-basis:50%; width:50%; padding:toRem(66) toRem(64);}
                    .newsblock-block-item-subheader {margin-bottom:toRem(24);}
                    .newsblock-block-item-title {margin-bottom:toRem(24); font-size:toRem(40); line-height:1.2; letter-spacing:toRem(-0.8);}
                    .newsblock-block-item-summary {margin-bottom:toRem(24); font-size:toRem(18); line-height:1.6;}
                }
            }
        }
    }
}

.pathway-block {@extend %blockSection; background:$off-white;
    .pathway-block-image {
        img {width:100%;}
    }
    .pathway-block-body {margin-bottom:toRem(20);}
    .pathway-block-subtitle {@extend %blockSubtitle;}
    .pathway-block-title {@extend %blockTitle;}
    .pathway-block-summary {@extend %blockSummary;}
    @media all and (min-width:$screen_md_min) {
        & {
            .pathway-block-image {margin-left:0; margin-right:0;
                img {}
            }
        }
    }
}

.resources-block {@extend %blockSection;
    .resources-block-header {margin-bottom:toRem(20);}
    .resources-block-subtitle {@extend %blockSubtitle;}
    .resources-block-title {@extend %blockTitle;}
    .resources-block-summary {@extend %blockSummary;}
    .resources-block-bodytext-row {justify-content:space-between;}
    .resources-block-bodytext-col {}

    .resources-block-list {}
    .resources-block-list-content {}
    .resources-block-list-aside {display:none;}
    .resources-block-item-trigger {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center; margin-top:toRem(8); padding:toRem(9) toRem(15); background:$white; border:5px solid $neutral-50; border-radius:toRem(26); color:$soa-blue;
        img, svg {fill:$soa-blue;}
        svg {fill:$soa-blue;}
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
        //&:hover, &:focus, &:focus-within {box-shadow:4px 4px 10px rgba(0,0,0,0.15);}
        &.collapsed {background:$neutral-50; color:$neutral-900;
            .icon, .fa, .fas, .far, .fab {
                &::before {content:"\f054";}
            }
            img, svg {fill:$neutral-900;}
            svg {fill:$neutral-900;}
        }
    }
    .resources-block-item-trigger-icon {@extend %blockIconBase; margin-right:toRem(12);}
    .resources-block-item-trigger-text {flex-grow:1; flex-shrink:1; width:100%; padding-right:toRem(12); font-size:toRem(16); line-height:toEm(24,16); font-weight:$fw_semibold;}
    .resources-block-item-trigger-title {margin-top:0; padding:0;}
    .resources-block-item-trigger-summary {display:none;}
    .resources-block-item-trigger-label {display:none; font-weight:500;}
    .resources-block-item-trigger-arrow {width:toRem(10.5); font-size:toRem(10.5);}
    .resources-block-list-aside {}
    .resources-block-list-aside-wrap {padding:toRem(12); background:$neutral-50; border-radius:toRem(32);}
    .resources-block-item-aside-trigger {display:block; margin-top:toRem(4); padding:toRem(18) toRem(24); background:$white; border-radius:toRem(30); @include boxshadow; color:$soa-blue; font-size:toRem(18); line-height:toEm(21.6,18); font-weight:$fw_semibold;
        &:first-child {margin-top:0;}
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
        //&::before {display:block; position:absolute; left:0; top:0; bottom:0; width:toRem(4); height:100%; background:$primary-blue-1; content:"";}
    }
    .resources-block-item-aside-trigger-wrap {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center;}
    .resources-block-item-aside-trigger-icon {@extend %blockIconBase; margin-right:toRem(12);}
    .resources-block-item-aside-trigger-text {flex-grow:1; flex-shrink:1; width:100%;}
    .resources-block-item-aside-trigger-title {margin-top:0; padding:0;}
    .resources-block-item-aside-trigger-summary {display:block; padding-left:toRem(66); padding-right:toRem(16); padding-bottom:toRem(12);}
    .resources-block-item-aside-trigger {
        &.collapsed {background:none; box-shadow:none; color:$neutral-900;
            //&::before {display:none; content:none;}
            .resources-block-item-aside-trigger-icon {}
            .resources-block-item-aside-trigger-text {}
            .resources-block-item-aside-trigger-title {}
            .resources-block-item-aside-trigger-summary {display:none;}
        }
    }

    $list-gutter:8;

    .resources-block-item-card {padding-top:toRem(16); padding-bottom:toRem(14);
        &, &.collapse, &.collapsing, &.collapse.show {transition:none;}
        & > .row {margin-left:toRem(-$list-gutter); margin-right:toRem(-$list-gutter);}
    }

    .resources-block-gpage {display:flex; margin-bottom:toRem($list-gutter * 2); padding-left:toRem($list-gutter); padding-right:toRem($list-gutter);}
    .resources-block-gpage-card {display:flex; flex-direction:row; padding:toRem(24); border:1px solid $neutral-200; border-radius:toRem(16);
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
        &, & > div {width:100%;}
        &:hover, &:focus, &:focus-within {//box-shadow:4px 4px 10px rgba(0,0,0,0.15);
            //.btn {color:$soa-blue;}
        }
    }
    .resources-block-gpage-image {display:none;
        img {width:100%;}
    }
    $subheader-gutter:12;
    .resources-block-gpage-subheader {display:flex; flex-direction:row; align-items:center; margin-left:toRem(-$subheader-gutter); margin-right:toRem(-$subheader-gutter); margin-bottom:toRem(12); font-weight:500;
        & > div {position:relative; padding-left:toRem($subheader-gutter); padding-right:toRem($subheader-gutter);
            &::before {display:block; position:absolute; left:0; top:50%; margin-top:1px; width:toRem(4); height:toRem(4); border-radius:toRem(2); background:$primary-blue-1; transform:translate(-50%,-50%); content:"";}
            &:first-child {
                &::before {display:none; content:none;}
            }
        }
    }
    .resources-block-gpage-pill {padding:toRem(4) toRem(12); border-radius:toRem(12); background:$off-white; color:$link-default; font-size:toRem(12); font-weight:500;}
    .resources-block-gpage-date {}
    .resources-block-gpage-info {margin-bottom:toRem(16);}
    .resources-block-gpage-title {color:$neutral-900;}
    .resources-block-gpage-edate {display:flex; flex-direction:row; flex-wrap:nowrap; margin-top:toRem(8);
        span {display:block; flex-grow:1; flex-shrink:1; width:100%; font-size:toRem(16); font-weight:500; line-height:toRem(20);
            &.icon, &.fa, &.fas, &.far, &.fab {flex-grow:0; flex-shrink:0; width:auto; margin-right:toRem(12); color:$link-default; font-size:toRem(20);}
            &.fal {font-weight:300;}
            &.icon, &.far, &.fab {font-weight:400;}
            &.fa, &.fas, &.fad {font-weight:900}
            &.icon-svg {flex-grow:0; flex-shrink:0; width:auto; margin-right:toRem(12);
                svg {height:toRem(20); fill:$link-default;}
            }
            &.w-uniform {width:toRem(20); margin-right:toRem(10); text-align:center;}
        }
    }
    .resources-block-gpage-summary {margin-bottom:toRem(6); color:$neutral-800; font-size:toRem(15); font-weight:$fw_medium;}
    .resources-block-gpage-link {
        .btn {padding-left:0; padding-right:0; border:none;
            &:not(:disabled):not(.disabled) {cursor:pointer;}
        }
    }
    @media all and (min-width:$screen_lg_min) {
        & {
            .resources-block-bodytext-row {}
            .resources-block-bodytext-col {flex-shrink:1; max-width:50%;}
            .resources-block-button-col {flex-shrink:0; max-width:50%;}

            .resources-block-list {
                & > .row {justify-content:space-between; flex-wrap:nowrap;}
            }
            .resources-block-list-content {flex-grow:1; flex-shrink:1; max-width:toRem(885);
                & > .resources-block-item-trigger {display:none;}
            }
            .resources-block-list-aside {flex-grow:0; flex-shrink:0; display:block; width:toRem(303);}
            
            .resources-block-item-card {padding-top:0;}

            .resources-block-gpage-card {padding:toRem(40);}
            .resources-block-gpage-body {display:flex; flex-direction:column;}
            .resources-block-gpage-summary {margin-bottom:toRem(24); font-size:toRem(16);}
            .resources-block-gpage-link {margin-top:auto; padding-top:toRem(24); border-top:1px solid $neutral-200;}
        }
    }
}

.study-block {@extend %blockSection;
    .study-block-header {margin-bottom:toRem(20);}
    .study-block-subtitle {@extend %blockSubtitle;}
    .study-block-title {@extend %blockTitle;}
    .study-block-summary {@extend %blockSummary;}
    .study-block-bodytext-row {justify-content:space-between;}
    .study-block-bodytext-col {}
    @media all and (min-width:$screen_lg_min) {
        & {
            .study-block-bodytext-row {}
            .study-block-bodytext-col {flex-shrink:1; max-width:50%;}
            .study-block-button-col {flex-shrink:0; max-width:50%;}
        }
    }

    .study-block-list {//margin-top:toRem(32);
        @media all and (min-width:$screen_md_min) {display:grid; grid-template-columns:repeat(2, 1fr); gap:toRem(16);}

        @media all and (min-width:$screen_lg_min) {grid-template-columns:repeat(3, 1fr);} //margin-top:toRem(64);

        &__card {border-radius:toRem(16); border:1px solid $neutral-200; padding:toRem(24); display:flex; flex-direction:column; align-items:start; row-gap:toRem(12); color:$neutral-900; line-height:1.5; font-size:toRem(15);
            @media all and (min-width:$screen_lg_min) {padding:toRem(40); row-gap:toRem(20); border-radius:toRem(24); font-size:toRem(16);}

            &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}

            & > * {flex:0; width:100%;}

            .card-title {margin:0;}

            .card-link {margin-top:auto;
                @media all and (min-width:$screen_md_min) {padding-top:toRem(24); border-top:1px solid $neutral-200;}

                .btn {
                    &:not(:disabled):not(.disabled) {cursor:pointer;}
                }
            }
        }
    }

    @include slickControls;

    .study-block-navigation {
        @media all and (min-width:$screen_md_min) {display:none;}
    }

    .slick-list {margin-inline:toRem(-12);

        .slick-slide {margin-inline:toRem(12);}
    }
}

.professional-block {@extend %blockSection;

    .professional-block-header {margin-block-end:0;
        @media all and (min-width:$screen_md_min) {margin-block-end:toRem(40);}

        @media all and (min-width:$screen_lg_min) {margin-block-end:toRem(62);}

        .professional-block-subtitle {@extend %blockSubtitle; margin-block-end:toRem(20);
            @media all and (min-width:$screen_lg_min) {margin-block-end:toRem(24);}
        }

        .professional-block-title {@extend %blockTitle; margin-block-end:0;}
    }

    .professional-block-list {

        .professional-block-list-tab {display:none; flex-wrap:nowrap;
            @media all and (min-width:$screen_md_min) {display:flex; column-gap:toRem(4); padding:toRem(12); background-color:$neutral-50; border-radius:toRem(200); margin-block-end:toRem(40);}

            @media all and (min-width:$screen_lg_min) {margin-block-end:toRem(78);}

            .professional-block-item-tab-trigger {display:flex; flex:1; justify-content:center; align-items:center; padding:toRem(16) toRem(12); border-radius:toRem(200); background:$white; color:$soa-blue; @include boxshadow;

                &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}

                &.collapsed {color:$neutral-900; background:transparent; box-shadow:none;}

                &-wrap {display:flex; align-items:center;

                    &-icon {display:flex; flex:0 0 auto; align-self:center; justify-content:center; align-items:center; padding-right:toRem(12); font-size:toRem(14); color:$soa-blue;
                        img, svg {height:toRem(14);}
                        svg {fill:$soa-blue;}
                    }

                    &-title {font-size:toRem(16); line-height:1.3; font-weight:$fw_semibold;}
                }
            }
        }

        .professional-block-list-content {
            .professional-block-item-card {margin-block-start:toRem(40);
                @media all and (min-width:$screen_md_min) {margin-block-start:0;}

                &.collapse:not(.show) {display:block;
                    @media all and (min-width:$screen_md_min) {display:none;}
                }

                .professional-block-item-card-header {margin-block-end:toRem(24);
                    @media all and (min-width:$screen_lg_min) {margin-block-end:toRem(32);}

                    .professional-block-item-card-header-row {row-gap:toRem(8);

                        &-btn {
                            .btn {line-height:1.8;}
                        }
                    }
                }

                .professional-block-item-card-list {margin-block-end:toRem(24);
                    @media all and (min-width:$screen_md_min) {display:grid; grid-template-columns:repeat(2, 1fr); gap:toRem(16); margin-block-end:0;}

                    @media all and (min-width:$screen_lg_min) {grid-template-columns:repeat(3, 1fr);}

                    .professional-block-gpage-card {padding:toRem(24); border:1px solid $neutral-200; border-radius:toRem(18); text-decoration:none; color:$neutral-900; line-height:1.5; font-size:toRem(15); display:flex;
                        @media all and (min-width:$screen_lg_min) {padding:toRem(40); border-radius:toRem(24); font-size:toRem(16);}

                        p {line-height:1.5; font-size:toRem(15);
                            @media all and (min-width:$screen_lg_min) {font-size:toRem(16);}
                        }

                        .professional-block-gpage-body {display:flex; flex-direction:column; row-gap:toRem(12); height:100%;
                            @media all and (min-width:$screen_lg_min) {row-gap:toRem(20);}

                            & > div {flex:0;}

                            .professional-block-gpage-subheader {display:flex; flex-wrap:wrap; row-gap:toRem(4); column-gap:toRem(20);

                                .professional-block-gpage-edate {font-size:toRem(14); line-height:1; color:$neutral-700; padding-block:toRem(6);}
                            }

                            .professional-block-gpage-locations {ul {list-style-type:none; padding:0; margin:0; display:flex; flex-direction:row; flex-wrap:wrap;

                                    & > li {position:relative; padding-inline:toRem(8);

                                        &::after {display:block; position:absolute; left:100%; top:50%; margin-block-start:1px; width:toRem(4); height:toRem(4); border-radius:toRem(2); background:$primary-blue-1; transform:translate(-50%,-50%); content:"";}

                                        &:last-child {

                                            &::after {display:none; content:none;}
                                        }
                                    }
                                }
                            }

                            .professional-block-gpage-link {margin-block-start:auto;
                                @media all and (min-width:$screen_md_min) {padding-block-start:toRem(24); border-top:1px solid $neutral-200;}

                                .btn {
                                    &:not(:disabled):not(.disabled) {cursor:pointer;}
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include slickControls;

    .slick-slide {margin-inline:toRem(12);}
    /* the parent */
    .slick-list {margin-inline:toRem(-12);}

    .professional-block-navigation {
        @media all and (min-width:$screen_md_min) {display:none;}
    }
}

.pathwayrev {@extend %blockSection; background:$neutral-50;
    $pathwayrev-row-pb:8;
    $pathwayrev-gutter-x:8;
    $pathwayrev-guide-color:$neutral-200;

    .pathwayrev-block-body {margin-bottom:toRem(32);
        & > .row {align-items:center;}
    }
    .pathwayrev-block-subtitle {@extend %blockSubtitle;}
    .pathwayrev-block-title {@extend %blockTitle;}
    .pathwayrev-block-summary {@extend %blockSummary;}

    .pathwayrev-pathway-progress {margin-top:toRem(24); margin-bottom:toRem(24); padding:toRem(16); background:$white; border-radius:3px; box-shadow:2px 2px 5px rgba(0,0,0,0.15);}
    .pathwayrev-pathway-progress-row {display:flex; flex-direction:row; flex-wrap:nowrap; margin-left:toRem(-$pathwayrev-gutter-x); margin-right:toRem(-$pathwayrev-gutter-x);
        & > div {padding-left:toRem($pathwayrev-gutter-x); padding-right:toRem($pathwayrev-gutter-x);}
    }
    .pathwayrev-pathway-progress-percent {}
    .pathwayrev-pathway-progress-percent-pie {display:flex; justify-content:center; align-items:center; width:toRem(48); height:toRem(48); border-radius:toRem(24); background:conic-gradient(darken($green, 3%) var(--myprogress, 0%), $grey-warm 0);}
    .pathwayrev-pathway-progress-percent-text {display:flex; justify-content:center; align-items:center; width:toRem(36); height:toRem(36); background:$white; border-radius:toRem(18); font-size:toRem(12); font-weight:bold;}

    .pathwayrev-pathway-progress-body {font-weight:500;}

    .pathwayrev-visual{}
    .pathwayrev-row {display:flex; flex-direction:row; flex-wrap:wrap; align-items:stretch;}
    .pathwayrev-header {position:relative; flex-grow:0; flex-shrink:0; width:100%; flex-basis:100%; display:flex; flex-direction:row; flex-wrap:nowrap; align-items:stretch; padding-bottom:toRem($pathwayrev-row-pb);}
    .pathwayrev-header-status {position:relative; flex-grow:0; flex-shrink:0; width:toRem(46); flex-basis:toRem(46); display:flex; justify-content:center; align-items:center;}
    .pathwayrev-header-icon {position:relative; z-index:1; width:toRem(10); height:toRem(10); display:flex; justify-content:center; align-items:center; background:$pathwayrev-guide-color; border-radius:toRem(5);
    }
    .pathwayrev-header-text {flex-grow:0; flex-shrink:1; align-self:center; width:100%; flex-basis:100%; padding-left:toRem(26); padding-right:toRem(12); font-size:toRem(12); font-weight:$fw_bold; line-height:toEm(14.4,12); letter-spacing:toRem(0.36); overflow:hidden; text-overflow:ellipsis; word-break:break-word; word-wrap:break-word;}
    .pathwayrev-cols {flex-grow:1; flex-shrink:1; width:100%; flex-basis:100%; display:flex; flex-wrap:wrap; align-items:stretch; padding-bottom:0; padding-left:toRem(72);}
    .pathwayrev-item {flex-grow:1; flex-shrink:1; width:100%; flex-basis:100%; display:flex; position:relative; margin-top:toRem(8);
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
        &:first-child {margin-top:0;}
        & > * {width:100%;}
        //&[data-colspan], &[style*="--colspan"] {
            //width:calc(var(--col-width) * var(--colspan, 1)); flex-basis:calc(var(--col-width) * var(--colspan, 1));
            //&::after {display:block; content:attr(data-colspan);}
            //:root{--someshit:calc(var(--col-width) * var(--colspan));}
            //&::after {display:block; content:var(--someshit);}
        //}

        //& {
            .pathwayrev-item-body {opacity:0.75;}
            &::before, &::after {opacity:0.3;}
            .pathwayrev-item-status-icon {
                .icon, .fa, .fas, .far, .fab {display:flex; justify-content:center; align-items:center; width:toRem(22); height:toRem(22); border-radius:toRem(12); background:$secondary-light; color:$body-copy-color; font-size:toRem(10);
                    &::before {display:block;}
                }
            }
        //}
        &.status-inactive {
            .pathwayrev-item-status-icon {
                 &::before {@include fas-family; display:block; content:"\f023"; display:flex; justify-content:center; align-items:center; width:toRem(22); height:toRem(22); border-radius:toRem(12); background:$secondary-light; color:$body-copy-color; font-size:toRem(10);
                }
            }
        }
        &.status-active {
            .pathwayrev-item-body {opacity:1;}
        }
        &.status-complete {
            .pathwayrev-item-body {opacity:1;}
            &::before, &::after {opacity:1;}
            &::before {background:$primary-blue-1;}
            &::after {color:$primary-blue-1;}
            .pathwayrev-item-status-icon {
                &::before {@include fas-family; display:block; content:"\f058"; color:$green; font-size:toRem(16);}
            }
        }
    }
    .pathwayrev-item-body {display:flex; flex-direction:column; background:$white; border-radius:toRem(20); font-weight:500;
        & > * {width:100%;}
    }
    .pathwayrev-item-body-inner {padding:toRem(16) toRem(12);
        $pathwayrev-row-gutter:6;
        & > .row {flex-wrap:nowrap; align-items:center; margin-left:toRem(-$pathwayrev-row-gutter); margin-right:toRem(-$pathwayrev-row-gutter);
            & > [class*="col-"] {padding-right:toRem($pathwayrev-row-gutter); padding-left:toRem($pathwayrev-row-gutter);}
        }
    }
    .pathwayrev-item-status {}
    .pathwayrev-item-title {flex-grow:1; flex-shrink:1; color:$neutral-900; font-size:toRem(16); font-weight:$fw_semibold; line-height:toEm(19.2,16); overflow:hidden; text-overflow:ellipsis; word-break:break-word; word-wrap:break-word;}
    .pathwayrev-item-tag {margin-left:auto;}
    //.pathwayrev-item-pill {padding:toRem(4) toRem(12); border-radius:toRem(12); background:$off-white; color:$link-default; font-size:toRem(10); font-weight:700;}
    .pathwayrev-item-status-icon {width:toRem(22); text-align:center;}
    .pathwayrev-row {
        &.status-complete {
            .pathwayrev-header-icon {border-color:darken($green, 3%);
                &::before {@include fas-family; display:block; content:"\f00c"; color:$green; font-size:toRem(14);}
            }
        }
        &.status-incomplete {
            .pathwayrev-header-icon {@include createBodyGradientLR($green, 1, 0%, lighten($green, 15%), 1, 50%, $white, 1, 50%)}
        }
    }

    .pathwayrev-header {
        &::before {display:block; position:absolute; left:toRem(23); bottom:0; width:2px; height:toRem($pathwayrev-row-pb); background:$pathwayrev-guide-color; transform:translateX(-50%); content:"";}
    }
    .pathwayrev-header-status {
        &::before, &::after {display:block; position:absolute; left:toRem(25); width:2px; height:50%; background:$pathwayrev-guide-color; transform:translateX(-50%); content:"";}
        &::before {top:0;}
        &::after {top:50%;}
    }
    .pathwayrev-cols {position:relative;
        &::before {display:block; position:absolute; left:toRem(31); top:0; bottom:0; width:2px; background:$pathwayrev-guide-color; transform:translateX(-50%); content:"";}
    }
    .pathwayrev-row {
        &:first-child {
            .pathwayrev-header-status {
                &::before {display:none; content:none;}
            }
        }
        &:last-child {
            .pathwayrev-header {
                &::before {display:none; content:none;}
            }
            .pathwayrev-header-status {
                &::after {display:none; content:none;}
            }
            .pathwayrev-item {
                &::before, &::after {display:none; content:none;}
            }
            .pathwayrev-cols {
                &::before {display:none; content:none;}
            }
        }
    }

    .pathwayrev-tabs {display:none;
        .soa-redesign-tab-trigger {flex-grow:1; flex-shrink:1; width:100%;}
    }
    .pathwayrev-visual-view {
        &, &.collapse:not(.show) {display:none;}
    }
    .pathwayrev-list-view {
        &, &.collapse:not(.show) {display:block;}
    }
    .soa-redesign-tab-card {
        &.pathwayrev-visual-view, &.pathwayrev-list-view {padding-top:0;}
    }

    .pathwayrev-list-stage {margin-bottom:toRem(16); padding:0 toRem(20) toRem(10) toRem(20); background:$white; border-radius:toRem(24);}
    .pathwayrev-list-stage-title {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center; padding:toRem(19) 0; font-size:toRem(12); font-weight:$fw_bold; line-height:toEm(16,12); letter-spacing:toRem(0.36); text-transform:uppercase;
        &::before {display:block; margin-right:toRem(12); width:toRem(15); height:toRem(15); background:$neutral-200; border:3px solid $neutral-50; border-radius:toRem(8); content:"";}
        //&::after {@include fas-family(); color:$neutral-300; font-size:toRem(15); content:"\f078"; display:block; margin-left:auto; padding-left:toRem(12);}
        &.collapsed {
            &::after {content:"\f054";}
        }
    }
    .pathwayrev-list-nodes {}
    .pathwayrev-list-node-trigger {}
    $trigger-gutter:6;
    .pathwayrev-list-node-trigger-wrap {display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:flex-start; align-items:center; margin-left:toRem(-$trigger-gutter); margin-right:toRem(-$trigger-gutter);
        & > div {padding-left:toRem($trigger-gutter); padding-right:toRem($trigger-gutter);}
    }
    .pathwayrev-list-node-trigger-text {}
    .pathwayrev-list-node-trigger-title {}
    .pathwayrev-list-node-trigger-tag {}
    //.pathwayrev-list-node-trigger-pill {padding:toRem(4) toRem(12); border-radius:toRem(12); background:$link-default; color:$off-white; font-size:toRem(10); font-weight:700;}
    .pathwayrev-list-node-trigger-pill {}
    .pathwayrev-list-node-trigger-arrow {}
    .pathwayrev-item-pill, .pathwayrev-list-node-trigger-pill {margin-bottom:toRem(10);}
    .pathwayrev-list-node-item {border-top:1px solid $neutral-200;
        &:first-child {border-top:none;}
        .pathwayrev-list-node-item-status-icon {
            .icon, .fa, .fas, .far, .fab {display:flex; justify-content:center; align-items:center; width:toRem(22); height:toRem(22); border-radius:toRem(12); background:$secondary-light; color:$body-copy-color; font-size:toRem(10);
                &::before {display:block;}
            }
        }
        &.status-inactive {
            .pathwayrev-list-node-item-status-icon {
                 &::before {@include fas-family; display:block; content:"\f023"; display:flex; justify-content:center; align-items:center; width:toRem(22); height:toRem(22); border-radius:toRem(12); background:$secondary-light; color:$body-copy-color; font-size:toRem(10);
                }
            }
        }
        &.status-active {
        }
        &.status-complete {
            .pathwayrev-list-node-item-status-icon {
                &::before {@include fas-family; display:block; content:"\f058"; color:$green; font-size:toRem(16);}
            }
        }
    }
    .pathwayrev-list-node-trigger {display:block; padding:toRem(16) toRem(12); background:$white; color:$neutral-900; font-size:toRem(16); line-height:toEm(19.2,16); font-weight:$fw_semibold; text-decoration:none; transition:background 0.15s ease; will-change:background;
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
        & > span {display:block;}
        .pathwayrev-list-node-trigger-title {}
        .pathwayrev-list-node-trigger-arrow {flex-grow:0; flex-shrink:0; margin-left:auto; padding-left:toRem(16); width:toRem(34); text-align:center;}
        &.collapsed {//background:rgba($white, 0.45);
            .pathwayrev-list-node-trigger-title {}
            .icon, .fa, .fas, .far, .fab {
                &::before {content:"\f054";}
            }
        }
    }

    .pathwayrev-list-node-card {padding:toRem(4) toRem(20) toRem(24) toRem(20); background:$white;
        &.show {}
    }
    
    $cardbody-gutter:12;
    .pathwayrev-list-node-details-body {display:flex; flex-direction:row; flex-wrap:wrap; justify-content:space-between; margin-left:toRem(-$cardbody-gutter); margin-right:toRem(-$cardbody-gutter);
        & > div {padding-left:toRem($cardbody-gutter); padding-right:toRem($cardbody-gutter);}
    }
    .pathwayrev-list-node-details-body-summary {flex-grow:0; flex-shrink:0; width:100%; flex-basis:100%; max-width:toRem(740);}
    .pathwayrev-list-node-details-body-registration {flex-grow:0; flex-shrink:0; width:100%; flex-basis:100%; margin-top:toRem(24);}
    .pathwayrev-list-node-details-body-registration-box {padding:toRem(24) toRem($pathway-gutter); background:$off-white; text-align:center;
        h4 {margin:0; padding:0;}
        .btn {margin-top:toRem(12);}
        .pathwayrev-list-node-details-body-registration-login {margin-top:toRem(12); font-weight:500;}
    }

    .pathwayrev-list-node-details-body-splitpaths {@include orGrid;}

    //FSA track
    &.pathwayfsa {
        .soa-redesign-tab-card {
            &.pathwayrev-track-view {padding-top:toRem(14); padding-bottom:0;}
            //&.pathwayrev-visual-view, &.pathwayrev-list-view {padding-top:0; padding-bottom:0;}
        }
        .pathwayrev-list {padding-top:toRem(14);}
        .pathwayrev-cols {padding-left:0; padding-bottom:toRem(34);
            &::before {display:none; content:"";}
        }
        .pathwayrev-list-stage {margin-bottom:toRem(8); padding:0; background:none; border-radius:0;}
        .pathwayrev-list-stage-title {margin-top:0; margin-bottom:toRem(8); padding:toRem(12); font-size:toRem(14);
            &::before {display:none; content:"";}
        }
        .pathwayrev-list-node-item {margin-top:toRem(8); padding:toRem(4) toRem(8); background:$white; border:none; border-radius:toRem(16);
            &:first-child {margin-top:0;}
        }
    }

    @media all and (min-width:$screen_md_min) {
        & {
            .pathwayrev-tabs {
                .soa-redesign-tab-trigger {flex-grow:0; flex-shrink:0; width:auto; flex-basis:auto; padding-left:toRem(24); padding-right:toRem(24);}
            }

            .pathwayrev-list-node-details-body {flex-wrap:nowrap;}
            .pathwayrev-list-node-details-body-summary {flex-grow:1; flex-shrink:1;}
            .pathwayrev-list-node-details-body-registration {}
            .pathwayrev-list-node-details-body {
                .pathwayrev-list-node-details-body-registration {width:toRem(300); flex-basis:toRem(320); margin-top:0;}
            }
        }
    }

//    @media all and (min-width:$screen_md_min) {
//        & {
//            //$pathwayrev-row-pb:60;
//            $pathwayrev-row-pb:40;
//            .pathwayrev-row {flex-direction:row; flex-wrap:nowrap;}
//            .pathwayrev-header {width:33%; flex-basis:33%; max-width:toRem(300); padding-bottom:toRem($pathwayrev-row-pb);
//                &::before {height:toRem($pathwayrev-row-pb);}
//            }
//            .pathwayrev-header-status {
//                &::before, &::after {display:block; position:absolute; left:toRem(16); width:2px; height:50%; background:$secondary-gray; transform:translateX(-50%); content:"";}
//                &::before {top:0;}
//                &::after {top:50%;}
//            }
//            .pathwayrev-cols {padding-bottom:toRem($pathwayrev-row-pb); position:static;
//                &::before {display:none; content:none;}
//            }
//            
//            .pathwayrev-tabs {}
//        }
//    }

    @media all and (min-width:$screen_lg_min) {
        & {
            //$pathwayrev-row-pb:60;
            $pathwayrev-row-pb:14;
            .pathwayrev-row {flex-direction:row;}
            .pathwayrev-header {padding-bottom:toRem($pathwayrev-row-pb);
                &::before {height:toRem($pathwayrev-row-pb);}
            }
            .pathwayrev-header-status {
                &::before, &::after {display:block; position:absolute; left:toRem(23); width:2px; height:50%; background:$pathwayrev-guide-color; transform:translateX(-50%); content:"";}
                &::before {top:toRem(-9);}
                &::after {top:50%; margin-top:toRem(9);}
            }
            .pathwayrev-header-text {font-size:toRem(21); font-weight:$fw_semibold; line-height:toEm(29.4,21); letter-spacing:0;}
            .pathwayrev-tabs {display:flex;}

            .pathwayrev-visual-view, .pathwayrev-list-view {
                &.collapse:not(.show) {display:none;}
                &.collapse.show {display:block;}
            }

            .soa-redesign-tab-card {
                &.pathwayrev-visual-view, &.pathwayrev-list-view {padding-top:toRem(36);}
            }

            .pathwayrev-cols {padding-top:toRem(14); padding-bottom:toRem(48); flex-wrap:nowrap; margin-left:toRem(-$pathwayrev-gutter-x); margin-right:toRem(-$pathwayrev-gutter-x);
                //&::before {display:none; content:none;}
                &.pathwayrev-cols-count-5 {}
                &.pathwayrev-cols-count-6 {}
                &.pathwayrev-cols-count-7 {}
                &.pathwayrev-limit-5 {flex-wrap:wrap; justify-content:center;
                    .pathwayrev-item {margin-bottom:toRem($pathwayrev-gutter-x * 2); max-width:33.333334%;}
                }
            }
            .pathwayrev-item {margin-top:0; padding-left:toRem($pathwayrev-gutter-x); padding-right:toRem($pathwayrev-gutter-x); //transition:transform .25s ease; //width:calc(var(--col-width) * var(--colspan, 1)); flex-basis:calc(var(--col-width) * var(--colspan, 1));
                //&::before {display:block; position:absolute; left:50%; top:100%; bottom:toRem(-$pathwayrev-row-pb + 6); transform:translateX(-50%); width:toRem(2); border-radius:2px; background:darken($body-copy-color, 15%); content:"";}
                //&::after {display:block; position:absolute; left:50%; bottom:toRem(-$pathwayrev-row-pb + 2); transform:translateX(-50%); height:toRem(9); @include fas-family; content:"\f078"; color:darken($body-copy-color, 15%); font-size:toRem(16); line-height:toRem(9);}
                .pathwayrev-item-body {transition:box-shadow .25s ease;}
                .pathwayrev-item-status-icon {margin-bottom:toRem(8);}
                &:hover, &:focus, &:focus-within {//transform:translateY(toRem(-14));
                    .pathwayrev-item-body {@include boxshadow;}
                }
            }
            .pathwayrev-item-body-inner {padding:toRem(16);
                & > .row {flex-wrap:wrap; justify-content:space-between;}
            }
            .pathwayrev-item-status {order:2;}
            .pathwayrev-item-title {order:3; width:100%;} //font-size:toRem(18); line-height:toEm(25.2,18);
            .pathwayrev-item-tag {order:1; margin-left:0;}
            //.pathwayrev-item-pill {margin-bottom:toRem(10);}
            .pathwayrev-item-status-icon {width:auto;}
        }
    }

    @media all and (min-width:$screen_xl_min) {
        & {
            .pathwayrev-item-body-inner {padding:toRem(24);}
            .pathwayrev-item-title {font-size:toRem(18); line-height:toEm(25.2,18);}
        }
    }

    @media all and (min-width:$screen_xxl_min) {
        & {
            .pathwayrev-cols {
                &.pathwayrev-cols-count-5 {}
                &.pathwayrev-cols-count-6 {}
                &.pathwayrev-cols-count-7 {}
                &.pathwayrev-limit-5 {flex-wrap:nowrap;
                    .pathwayrev-item {margin-bottom:0; max-width:none;}
                }
            }
        }
    }
}

.examdash-block {@extend %blockSection; background:$neutral-50;
    .examdash-block-header {margin-bottom:toRem(20);}
    .examdash-block-subtitle {@extend %blockSubtitle;}
    .examdash-block-title {@extend %blockTitle;}
    .examdash-block-grid-row {
        & > [class*="col-"] {}
    }
    .examdash-block-section {margin-top:toRem(30); padding-top:toRem(30); padding-bottom:toRem(14); border-top:2px solid $tab-hover;
        &:first-child {}
        &.examdash-block-recentresults {margin-top:0; border-top:none;}
        &.examdash-block-recentorders {}
        &.examdash-block-nextexam {}
    }
    .examdash-block-section-header {padding-bottom:toRem(24);
        h3 {margin:0; padding:0;}
    }
    .examdash-block-section-header-row {flex-wrap:nowrap; align-items:center; min-height:toRem(46);}
    .examdash-block-section-header-text {flex-grow:1; flex-shrink:1;}
    .examdash-block-section-header-btn {align-self:flex-start;}

    $list-gutter:15;
    .examdash-block-item {display:flex; padding-left:toRem($list-gutter); padding-right:toRem($list-gutter);}
    .examdash-block-item-card {display:flex; flex-direction:column; padding:toRem(18); background:$white; border-radius:toRem(8); text-decoration:none;
        &, & > div {width:100%;}
    }
    .examdash-block-item-image {display:none;
        img {width:100%;}
    }
    $subheader-gutter:12;
    .examdash-block-item-subheader {display:flex; flex-direction:row; align-items:center; margin-left:toRem(-$subheader-gutter); margin-right:toRem(-$subheader-gutter); margin-bottom:toRem(12); font-weight:500;
        & > div {position:relative; padding-left:toRem($subheader-gutter); padding-right:toRem($subheader-gutter);
            &::before {display:block; position:absolute; left:0; top:50%; margin-top:1px; width:toRem(4); height:toRem(4); border-radius:toRem(2); background:$primary-blue-1; transform:translate(-50%,-50%); content:"";}
            &:first-child {
                &::before {display:none; content:none;}
            }
        }
    }
    .examdash-block-item-pill {padding:toRem(4) toRem(12); border-radius:toRem(12); background:$off-white; color:$link-default; font-size:toRem(12); font-weight:500;}
    .examdash-block-item-date {}
    .examdash-block-item-info {margin-bottom:toRem(16);}
    .examdash-block-item-title {color:$primary-blue-1; font-size:toRem(18); letter-spacing:-1px; font-weight:600; @include htag-family;}
    .examdash-block-item-edate {display:flex; flex-direction:row; flex-wrap:nowrap; margin-top:toRem(8);
        span {display:block; flex-grow:1; flex-shrink:1; width:100%; font-size:toRem(14); font-weight:500; line-height:toRem(16);
            &.icon, &.fa, &.fas, &.far, &.fab {flex-grow:0; flex-shrink:0; width:auto; margin-right:toRem(12); color:$link-default; font-size:toRem(16);}
            &.fal {font-weight:300;}
            &.icon, &.far, &.fab {font-weight:400;}
            &.fa, &.fas, &.fad {font-weight:900}
            &.icon-svg {flex-grow:0; flex-shrink:0; width:auto; margin-right:toRem(12);
                svg {height:toRem(16); fill:$link-default;}
            }
            &.w-uniform {width:toRem(18); margin-right:toRem(10); text-align:center;}
        }
    }
    .examdash-block-item-summary {margin-bottom:toRem(20);}
    .examdash-block-item-link {
        //.btn {padding-left:0; padding-right:0; border:none;}
    }

    .examdash-block-section-slick {opacity:0; transition:opacity 0.15s ease; padding-right:toRem(30 + $gutter-x);
        .examdash-block-item {height:1px;}
        &.slick-initialized, &.inEditMode {opacity:1; min-height:auto;
            .examdash-block-item {height:auto;}
        }
        $slick-gutter:$gutter-x;
        .slick-list {overflow:visible;}
        .slick-track {margin-left:toRem(-$slick-gutter); margin-right:toRem(-$slick-gutter);
            .slick-slide {align-items:stretch; padding-left:toRem($slick-gutter); padding-right:toRem($slick-gutter);}
        }
    }
    
    @include slickControls;

    .examdash-block-indicator {}
    .examdash-block-indicator-row {}
        
    .examdash-block-recentresults {}
    .examdash-block-recentorders {}
    .examdash-block-nextexam {}
    .examdash-block-recentresults, .examdash-block-recentorders {
        .examdash-block-item-link {
            .btn {padding-left:0; padding-right:0; border:none; font-weight:500;}
        }
    }
    @media all and (min-width:$screen_md_min) {
        & {
            $grid-gutter:24;
            .examdash-block-grid-row {margin-left:toRem(-$grid-gutter); margin-right:toRem(-$grid-gutter);
                & > [class*="col-"] {padding-left:toRem($grid-gutter); padding-right:toRem($grid-gutter); border-left:1px solid $secondary-gray;
                    &:first-child {border-left:none;}
                }
            }

            .examdash-block-section {margin-top:0; border-top:none;}

            $list-gutter:$gutter-x;
            .examdash-block-section-list {display:flex; flex-direction:row; flex-wrap:wrap; margin-left:toRem(-$list-gutter); margin-right:toRem(-$list-gutter);}
            .examdash-block-item {display:flex; margin-bottom:toRem($list-gutter * 2); padding-left:toRem($list-gutter); padding-right:toRem($list-gutter);}
            .examdash-block-item-card {background:$white;}
            .examdash-block-section-slick {opacity:1; padding-right:0;
                .examdash-block-item {height:auto;}
            }
            .examdash-block-navigation {display:none;}
            
    
            .examdash-block-recentresults {}
            .examdash-block-recentorders {margin-left:auto;
                .examdash-block-section-list {}
                .examdash-block-item {margin-bottom:0;
                    .examdash-block-item-card {border-top:1px solid $off-white; border-radius:0;}
                    &:first-child {
                        .examdash-block-item-card {border-top:none; border-radius:toRem(8) toRem(8) 0 0;}
                    }
                    &:last-child {margin-bottom:toRem(30);
                        .examdash-block-item-card {border-radius:0 0 toRem(8) toRem(8);}
                    }
                }
                .examdash-block-item-info {margin-bottom:0;}
                .examdash-block-item-link {display:none;}
            }
            .examdash-block-nextexam {margin-top:toRem(36); padding-top:toRem(36); border-top:1px solid $secondary-gray;
                .examdash-block-item {width:50%; flex-basis:50%;}
            }
            .examdash-block-recentresults, .examdash-block-recentorders {max-width:toRem(520);
                .examdash-block-item {width:100%; flex-basis:100%;}
            }
        }
    }
    @media all and (min-width:$screen_lg_min) {
        & {
        }
    }
}

.tab-block {@extend %blockSection;
    .tab-block-body {}
    .tab-block-subtitle {@extend %blockSubtitle; margin-bottom:toEm(8);}
    .tab-block-title {@extend %blockTitle; font-size:toRem(27);}
    .tab-block-summary {@extend %blockSummary;}
    .tab-block-info {}

    @media all and (min-width:$screen_md_min) {
        & {padding-top:0;
            .tab-block-body {padding-bottom:toRem(12);}
            .tab-block-title {font-size:toRem(32);}
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .tab-block-title {}
        }
    }
}

.ctaf-block {margin-bottom:-1px; padding-top:0; padding-bottom:toRem(40); @include createBodyGradientTB($white, 0, 50%, $neutral-900, 1, 50%);
    &.ctaf-block-offwhite {@include createBodyGradientTB($neutral-50, 1, 50%, $neutral-900, 1, 50%);}
    @media all and (min-width:$screen_md_min) {
        padding-top:toRem(60);
    }
    .ctaf-block-wrapper {margin-inline:auto; max-width:toRem(1392 + ($container-gutter-x * 2));
        @media all and (min-width:$screen_md_min) {padding-inline:toRem($container-gutter-x);}
    }

    &-content {background-color:$soa-blue; border-radius:toRem(40); overflow:hidden; display:grid; border-radius:0;
        @media all and (min-width:$screen_md_min) {border-radius:toRem(40); grid-template-columns:repeat(2, 1fr);}

        &__image {grid-row:2;
            @media all and (min-width:$screen_md_min) {grid-row:auto;}

            .ctaf-image-sm {
                @media all and (min-width:$screen_md_min) {display:none;}
            }

            .ctaf-image-lg {display:none;
                @media all and (min-width:$screen_md_min) {display:revert;}
            }

            img {object-fit:cover; width:100%;}
        }

        &__card {grid-row:1; padding-inline:toRem(24); padding-block-start:toRem(48); padding-block-end:toRem(24); color:$white; display:flex; flex-direction:column; justify-content:center; min-height:toRem(300);
            @media all and (min-width:$screen_md_min) {grid-row:auto; min-height:0; padding-inline:toRem(32); padding-block:toRem(48);}

            @media all and (min-width:$screen_xl_min) {padding-inline-start:toRem(64); padding-inline-end:toRem(96); padding-block:toRem(96);}

            .ctaf-card-title {margin-block-end:toRem(16); text-align:center; text-wrap:balance;
                @media all and (min-width:$screen_md_min) {margin-block-end:toRem(32); text-align:start;}
            }

            .ctaf-card-summary {text-align:center; margin-block-end:toRem(20);
                @media all and (min-width:$screen_md_min) {text-align:start; margin-block-end:toRem(32);}

                p {font-size:toRem(18);
                    @media all and (min-width:$screen_xl_min) {font-size:toRem(22);}

                    &:last-child {padding:0;}
                }
                a {
                    &:not(.btn, .button) {
                        &, &:link, &:active, &:visited {color:$white;}
                        &:hover, &:focus {color:$soa-secondary-link-hover;}
                        &:visited:hover {color:$soa-secondary-link-hover;}
                        &, &:link, &:active, &:visited, &:hover, &:focus, &:visited:hover {@include textUnderlineCustom;}
                    }
                }
            }

            .ctaf-card-buttons {display:flex; flex-wrap:wrap; column-gap:toRem(32); row-gap:toRem(10); justify-content:center; margin-block-end:toRem(20);
                @media all and (min-width:$screen_md_min) {justify-content:start; margin-block-end:toRem(32);}

                span {vertical-align:0;}
            }

            .ctaf-card-footer {text-align:center;
                @media all and (min-width:$screen_md_min) {text-align:start;}

                .btn {vertical-align:baseline;

                    &.btn-inverted {font-weight:$fw_medium !important;}
                }
            }
        }
    }
}

.html-block {margin-bottom:toRem(16);
    .html-block-heading {}
}


.assetslink-block {margin-bottom:toRem(24);
    .assetslink-item {display:block; padding:toRem(20) toRem(24); background:$white; border:1px solid $neutral-200; border-radius:toRem(16); color:$neutral-700; font-size:toRem(13); font-weight:$fw_medium; line-height:1.5; text-decoration:none;
        .row {@include customGutter(8); align-items:center;}
        .assetslink-block-info {
            @include iconClassnames {display:inline-flex; justify-content:center; align-items:center; margin-right:toRem(10); width:toRem(24); height:toRem(24); background:$soa-blue-light; border-radius:toRem(4); color:$soa-blue; font-size:toRem(12);}
        }
        .assetslink-block-btn {}
        .btn {font-size:toRem(14);}
        .assetslink-block-title {margin-bottom:toRem(16); color:$neutral-900; line-height:1.2;
            @include iconClassnames {display:none;}
        }
        &:hover, &:focus {color:$neutral-700; @include boxshadow(0, toRem(10), toRem(56), toRem(-10), rgba($neutral-800,0.06));
            .assetslink-block-title {color:$soa-blue;}
            .btn {color:$soa-link-hover;}
        }
    }
    &[role="listitem"] {margin-bottom:0; margin-top:toRem(8);
        .assetslink-item {border:none;}
        &:first-child {margin-top:0;}
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .assetslink-item {padding:toRem(20) toRem(32); border-radius:toRem(30); display:flex; flex-wrap:nowrap; justify-content:space-between; align-items:center; gap:toRem(48);
                .row {flex:0 0 auto; @include customGutter(24); white-space:nowrap;}
                .assetslink-block-info {
                    @include iconClassnames {display:none;}
                }
                .btn {white-space:nowrap;}
                .assetslink-block-title {flex:1 1 100%; margin-bottom:0; display:flex; flex-wrap:nowrap;
                    @include iconClassnames {flex:0 0 toRem(24); display:flex; justify-content:center; align-items:center; margin-right:toRem(12); width:toRem(24);}
                }
            }
            &[role="listitem"] {margin-top:toRem(12);}
        }
    }
}

.assetsdownload-block {margin-bottom:toRem(32); padding:toRem(24); background:$neutral-50; border-radius:toRem(32);
    @media all and (min-width:$screen_lg_min) {
        & {margin-bottom:toRem(48); padding:toRem(40);}
    }
}

.blockquote-block {position:relative; margin-bottom:toRem(72);
    .blockquote-block-clip {display:block; position:absolute; right:toRem(-16); bottom:toRem(-16); width:99%; height:99%; overflow:hidden;
        svg {position:absolute; bottom:0; right:0; height:toRem(351); max-height:100%; fill:$soa-theme-purple-base;}
    }
    figure {position:relative; z-index:1; padding:toRem(24); background-color:$white; border-radius:toRem(24); background-color:$white; background-image:$blockquote-figure-bg; background-position:right bottom; background-repeat:no-repeat; @include boxshadow(0, toRem(10), toRem(56), toRem(-10), rgba($neutral-800,0.06));}
    blockquote, .blockquote {
        &::before {}
    }
    .blockquote-footer {
        &::before {}
        &.hasImage {display:flex; align-items:center; flex-wrap:nowrap; gap:toRem(24);
            &::before {display:none; content:none;}
        }
    }
    .blockquote-author-image {flex:0 0 toRem(64);
        img {border-radius:toRem(16);}
    }
    .blockquote-author-text {flex:1 1 100%;}
    .blockquote-author-name {}
    .blockquote-author-title {}
    .blockquote-author-location {}
    .blockquote-author-title, .blockquote-author-location {color:$neutral-800; font-size:toRem(13); font-weight:$fw_regular; line-height:1.4;}

    @media all and (min-width:$desktop_min) {
        & {margin-bottom:toRem(96);
            .blockquote-block-clip {right:toRem(-24); bottom:toRem(-24);
                svg {}
            }
            figure {padding:toRem(64); border-radius:toRem(32);}
            blockquote, .blockquote {
                &::before {}
            }
            .blockquote-footer {
                &.hasImage {}
            }
        }
    }
}

.updatecenterlongshort-block {@extend %blockSection;
    &.updatecenter-offwhite {background:$off-white;}
    .updatecenterlongshort-block-header {margin-bottom:toRem(20);}
    .updatecenterlongshort-block-subtitle {@extend %blockSubtitle;}
    .updatecenterlongshort-block-title {@extend %blockTitle;}
    .updatecenterlongshort-block-summary {@extend %blockSummary; margin-bottom:0;}
    .updatecenterlongshort-block-text-col {}
    .updatecenterlongshort-block-footer {margin-top:toRem(24);}

    .updatecenterlongshort-block-list-wrap {}
    .updatecenterlongshort-block-list {padding:0 toRem(24); background:$white; border:1px solid $neutral-200; border-radius:toRem(24);
        &.updatecenter-shortblock {}
        &.updatecenter-cardlist {}
    }
    .updatecenterlongshort-block-listitem {padding:toRem(24) 0; border-top:1px solid $neutral-200;
        &:first-child {border-top:none;}
    }
    .updatecenterlongshort-block-itemtext {}
    .updatecenterlongshort-block-itemdate {color:$neutral-700; font-size:toRem(14); font-weight:$fw_medium;}
    .updatecenterlongshort-block-itemtitle {margin-top:toRem(16); margin-bottom:0; color:$neutral-900; font-weight:$fw_semibold;}
    .updatecenterlongshort-block-itemdescription {margin-top:toRem(16); color:$neutral-900; font-size:toRem(15); font-weight:$fw_medium; display:-webkit-box; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis; -webkit-line-clamp:2;
        &, & * {font-size:toRem(15);}
    }
    .updatecenterlongshort-block-itemctas {
        .col-auto {flex-shrink:1;}
    }

    @media all and (min-width:$screen_md_min) {
        & {
            .updatecenterlongshort-block-list {
                &.updatecenter-cardlist {display:flex; flex-direction:row; flex-wrap:wrap; padding:toRem(24);
                    .updatecenterlongshort-block-listitem {flex:0 0 50%; width:50%; max-width:50%;
                        &:nth-child(1), &:nth-child(2) {padding-top:0; border-top:none;}
                        &:nth-child(odd) {padding-right:toRem(24);}
                        &:nth-child(even) {padding-left:toRem(24); border-left:1px solid $neutral-200;}
                    }
                }
            }
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .updatecenterlongshort-block-text-col {flex-shrink:1; max-width:50%;}
            .updatecenterlongshort-block-button-col {flex-shrink:0; max-width:50%;}

            .updatecenterlongshort-block-list {padding:0 toRem(48);}
            .updatecenterlongshort-block-listitem {padding:toRem(48) 0;}
            
            .updatecenterlongshort-block-itemtitle {}
            .updatecenterlongshort-block-itemdescription {font-size:toRem(16); -webkit-line-clamp:1;
                &, & * {font-size:toRem(16);}
            }
            
            .updatecenterlongshort-block-list {
                &.updatecenter-shortblock {padding:toRem(24) toRem(48);
                    .updatecenterlongshort-block-listitem {display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:space-between; align-items:center; padding:toRem(24) 0;}
                    .updatecenterlongshort-block-itemtext {flex:1 1 100%;}
                    .updatecenterlongshort-block-itemctas {flex:0 0 0%;
                        .btn {white-space:nowrap;}
                    }
                }
                &.updatecenter-cardlist {padding:toRem(48);
                    .updatecenterlongshort-block-listitem {padding-top:toRem(32); padding-bottom:toRem(48);
                        //&:nth-child(1), &:nth-child(2) {padding-top:0;}
                        &:nth-child(odd) {padding-right:toRem(48);}
                        &:nth-child(even) {padding-left:toRem(48);}
                    }
                    .updatecenterlongshort-block-itemtitle {font-size:toRem(21);}
                }
            }
        }
    }
}

.updatecenterfeature-block {@extend %blockSection;
    &.updatecenter-offwhite {background:$off-white;}
    .updatecenterfeature-block-wrap {background:$white; border:toRem(6) solid $soa-theme-blue-light; border-radius:toRem(24); overflow:hidden;}
    .updatecenterfeature-block-header {padding:toRem(12) toRem(14); background:$soa-theme-blue-light; border-bottom:toRem(6) solid $soa-theme-blue-light;
        & > .row {@include customGutter(12);}
    }
    .updatecenterfeature-block-title-col {flex:1 1 100%; display:flex; flex-direction:row; flex-wrap:nowrap; align-items:center;
        @include iconClassnames {flex:0 0 toRem(16); margin-right:toRem(11); color:rgba($soa-secondary-blue, 0.4); font-size:toRem(18);}
    }
    .updatecenterfeature-block-title {flex:1 1 100%; margin:0; color:$neutral-900;}
    .updatecenterfeature-block-subheader__link {white-space:nowrap;}

    .updatecenterfeature-block-list {padding:0 toRem(42);}
    .updatecenterfeature-block-listitem {padding:toRem(40) 0 toRem(48); border-top:1px solid $neutral-200;
        &:first-child {border-top:none;}
    }
    .updatecenterfeature-block-itemdate {color:$neutral-700; font-size:toRem(14); font-weight:$fw_medium;}
    .updatecenterfeature-block-itemtitle {margin-top:toRem(16); margin-bottom:0; color:$neutral-900; font-weight:$fw_semibold;}
    .updatecenterfeature-block-itemdescription {margin-top:toRem(16); color:$neutral-900; font-size:toRem(16); font-weight:$fw_medium; display:-webkit-box; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis; -webkit-line-clamp:1;
        &, & * {font-size:toRem(16);}
    }
    .updatecenterfeature-block-itemctas {
        .col-auto {flex-shrink:1;}
    }

    @media all and (min-width:$screen_md_min) {
        & {
            .updatecenterfeature-block-list {display:flex; flex-direction:row; flex-wrap:nowrap; padding:toRem(40) 0 toRem(48);
                .updatecenterfeature-block-listitem {flex:0 0 50%; padding:0 toRem(42); width:50%; max-width:50%; border:none;
                    &:nth-child(odd) {padding-right:toRem(48);}
                    &:nth-child(even) {padding-left:toRem(48); border-left:1px solid $neutral-200;}
                    &.single-item {flex:0 0 100%; width:100%; max-width:100%;
                        &:nth-child(odd) {padding-right:toRem(42);}
                    }
                }
            }
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .updatecenterfeature-block-itemtitle {font-size:toRem(21);}
        }
    }
}

.soa-individualcontent {
    .soa-individualcontent-article {
        section {
            &.updatecenterlongshort-block, &.updatecenterfeature-block {
                &.updatecenter-offwhite {background:$white;}
            }
            //&.updatecenterfeature-block {margin-top:toRem(24);}
        }
    }
}