﻿@import '_variables';
@import '_mixins.scss';

.soa-examresults {
    .soa-examresults-row {}
    .soa-examresults-main {}

    .soa-examresults-header {padding-top:toRem(24); padding-bottom:toRem(40); //@extend %blockSection; //max-width:toRem(760);
        .soa-examresults-header-title {@extend %blockTitle;}
    }

    .soa-examresults-body-summary {@include dark-body;}

    .soa-examresults-results {@extend %blockSection;}
    .soa-examresults-results-header {@extend %blockTitle;}
    .soa-examdetails-results-info {}

    @media all and (min-width:$screen_md_min) {
        & {
            .soa-examresults-header {padding-top:toRem(70); padding-bottom:toRem(72);}
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .soa-examresults-header {}
        }
    }
}

.credverify-block {@extend %blockSection; background:$off-white;
    .credverify-block-body {}
    .credverify-block-subtitle {@extend %blockSubtitle;}
    .credverify-block-title {@extend %blockTitle;}
    .credverify-block-summary {@extend %blockSummary; @include dark-body; margin-top:toRem(24);}
    .credverify-block-summary-text {max-width:toRem(760);}
    .credverify-block-summary-btn {margin-top:toRem(16);}

    @media all and (min-width:$screen_md_min) {
        & {
            .credverify-block-summary {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:flex-start;}
            .credverify-block-summary-text {flex-shrink:1;}
            .credverify-block-summary-btn {flex-grow:0; flex-shrink:0; margin-top:0; margin-left:auto;}
        }
    }
}