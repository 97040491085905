@import '_variables';

$custom-breakpoints:(
    sm:$screen_sm_min,
    md:$screen_md_min,
    lg:$screen_lg_min,
    xl:$screen_xl_min,
    xxl:$screen_xxl_min,
    3xl:$screen_3xl_min
) !default;

$custom-order:0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
$displays:none,initial,inherit,inline,inline-block,block,flex,table,table-row,table-cell,table-header-group,table-row-group;

$justify-content-opt:(
    start:flex-start,
    end:flex-end,
    center:center,
    between:space-between,
    around:space-around,
    evenly:space-evenly
) !default;
$align-items-opt:(
    start:flex-start,
    end:flex-end,
    center:center,
    baseline:baseline,
    stretch:stretch
) !default;
$felx-direction-opt:(
    row:row,
    column:column,
) !default;

$spacing-spacer:4;
$spacings: (
    toRem($spacing-spacer * 0),
    toRem($spacing-spacer * 1),
    toRem($spacing-spacer * 2),
    toRem($spacing-spacer * 3),
    toRem($spacing-spacer * 4),
    toRem($spacing-spacer * 5),
    toRem($spacing-spacer * 6),
    toRem($spacing-spacer * 7),
    toRem($spacing-spacer * 8),
    toRem($spacing-spacer * 9),
    toRem($spacing-spacer * 10),
    toRem($spacing-spacer * 11),
    toRem($spacing-spacer * 12),
    auto,
) !default;
$n-spacings: (
    toRem(-$spacing-spacer * 0),
    toRem(-$spacing-spacer * 1),
    toRem(-$spacing-spacer * 2),
    toRem(-$spacing-spacer * 3),
    toRem(-$spacing-spacer * 4),
    toRem(-$spacing-spacer * 5),
    toRem(-$spacing-spacer * 6),
    toRem(-$spacing-spacer * 7),
    toRem(-$spacing-spacer * 8),
    toRem(-$spacing-spacer * 9),
    toRem(-$spacing-spacer * 10),
    toRem(-$spacing-spacer * 11),
    toRem(-$spacing-spacer * 12),
    auto,
) !default;

$prefixes-m: (
    m  : margin,
    mx : (margin-left, margin-right),
    my : (margin-top, margin-bottom),
    mt : margin-top,
    me : margin-right,
    mb : margin-bottom,
    ms : margin-left,
) !default;

$prefixes-p: (
    p  : padding,
    px : (padding-left, padding-right),
    py : (padding-top, padding-bottom),
    pt : padding-top,
    pe : padding-right,
    pb : padding-bottom,
    ps : padding-left,
) !default;

$prefixes: map-merge($prefixes-m, $prefixes-p) !default;

$prefixes-bw: (
    bw  : border-width,
    bw-x : (border-left-width, border-right-width),
    bw-y : (border-top-width, border-bottom-width),
    bw-t : border-top-width,
    bw-e : border-right-width,
    bw-b : border-bottom-width,
    bw-s : border-left-width,
) !default;

$border-widths:0, 1, 2, 3;
$border-styles:dashed,dotted,solid;

$soa-colors: (
    "white" : $white,
    "primary-blue" : $soa-blue,
    "powder-blue" : rgba($soa-blue, 0.1),
    "secondary-gray" : $neutral-400,
    "headline-color" : $neutral-900,
    "body-copy-color" : $neutral-900,
    "off-white" : $neutral-100,
    "link-default" : $soa-blue,
    "link-hover" : $neutral-900,
    "dark-body" : $neutral-900, //darken($body-copy-color, 15%),
    "red" : $soa-red,
    "neutral-50" : $neutral-50,
    "neutral-100" : $neutral-100,
    "neutral-200" : $neutral-200,
    "neutral-300" : $neutral-300,
    "neutral-400" : $neutral-400,
    "neutral-500" : $neutral-500,
    "neutral-600" : $neutral-600,
    "neutral-700" : $neutral-700,
    "neutral-800" : $neutral-800,
    "neutral-900" : $neutral-900
) !default;
$soa-icons: (
    search:$icon-search,
    chevron-down:$icon-chevron-down,
    search-blue:$icon-search-blue,
    chevron-down-blue:$icon-chevron-down-blue,
) !default;

$em-sizes:(
    0_1:0.1, 0_2:0.2, 0_3:0.3, 0_4:0.4, 0_5:0.5, 0_6:0.6, 0_7:0.7, 0_8:0.8, 0_9:0.9,
    1:1, 1_1:1.1, 1_2:1.2, 1_3:1.3, 1_4:1.4, 1_5:1.5, 1_6:1.6, 1_7:1.7, 1_8:1.8, 1_9:1.9,
    2:2, 2_1:2.1, 2_2:2.2, 2_3:2.3, 2_4:2.4, 2_5:2.5, 2_6:2.6, 2_7:2.7, 2_8:2.8, 2_9:2.9,
    3:3, 3_1:3.1, 3_2:3.2, 3_3:3.3, 3_4:3.4, 3_5:3.5, 3_6:3.6, 3_7:3.7, 3_8:3.8, 3_9:3.9,
    4:4
);
$em-sizes-select:(
    0_1:0.1, 0_2:0.2, 0_3:0.3, 0_4:0.4, 0_5:0.5, 0_6:0.6, 0_7:0.7, 0_8:0.8, 0_9:0.9,
    1:1, 1_1:1.1, 1_2:1.2, 1_3:1.3, 1_4:1.4, 1_5:1.5, 1_6:1.6, 1_7:1.7, 1_8:1.8, 1_9:1.9,
    2:2
);
$font-weights: (
    "thin" : $fw_thin,
    "extralight" : $fw_extralight,
    "light" : $fw_light,
    "regular" : $fw_regular,
    "medium" : $fw_medium,
    "semibold" : $fw_semibold,
    "bold" : $fw_bold,
    "extrabold" : $fw_extrabold,
    "black" : $fw_black,
) !default;
$custom-widths: (
    "0" : 0,
    "25" : 25%,
    "50" : 50%,
    "70" : 7%5,
    "100" : 100%,
    "auto" : auto,
) !default;

$text-wraps:(
    wrap:normal,
    nowrap:nowrap
) !default;

//.soa-redesign {
    @each $cwkey, $cwvalue in $custom-widths {
        .width-#{$cwkey} {width:#{$cwvalue};}
    }
    @each $ord in $custom-order {
        .order-#{$ord} {order:#{$ord} !important;}
    }
    @each $d in $displays {
        .d-#{$d} {display:#{$d} !important;}
    }
    @each $d in $displays {
        .d-#{$d} {display:#{$d} !important;}
    }
    @each $jcname, $jcvalue in $justify-content-opt {
        .justify-content-#{$jcname} {justify-content:#{$jcvalue} !important;}
    }
    @each $ainame, $aivalue in $align-items-opt {
        .align-items-#{$ainame} {align-items:#{$aivalue} !important;}
        .align-self-#{$ainame} {align-self:#{$aivalue} !important;}
    }
    @each $fdname, $fdvalue in $felx-direction-opt {
        .flex-#{$fdname} {flex-direction:#{$fdvalue} !important;}
    }
    @each $attr-short, $attr-list in $prefixes {
        @each $space in $spacings {
            $spcr: #{ index(($spacings), $space)-1 };
            @if $space == auto {
                $spcr:auto;
            }
            .#{$attr-short}-#{ $spcr } {
                @each $attr in $attr-list {
                    #{$attr}: #{$space} !important;
                }
            }
        }
    }
    @each $attr-short, $attr-list in $prefixes-m {
        @each $space in $n-spacings {
            $spcr: #{ index(($n-spacings), $space)-1 };
            @if $space != auto {
                .#{$attr-short}-n#{ $spcr } {
                    @each $attr in $attr-list {
                        #{$attr}: #{$space} !important;
                    }
                }
            }
        }
    }
    @each $attr-short, $attr-list in $prefixes-bw {
        @each $bwidth in $border-widths {
            $bw: #{ index(($border-widths), $bwidth)-1 };
            .#{$attr-short}-#{ $bw } {
                @each $attr in $attr-list {
                    #{$attr}: #{$bw}px !important;
                }
            }
        }
    }
    @each $cname, $cvalue in $soa-colors {
        .text-#{$cname}, .#{$cname}-text {color:#{$cvalue} !important;}
        .bg-#{$cname} {background-color:#{$cvalue};}
        .border-#{$cname} {border-color:#{$cvalue} !important;}
    }
    @each $iname, $ivalue in $soa-icons {
        .bg-#{$iname} {background-image:#{$ivalue};}
    }
    @each $space in $spacings {
        $spcr: #{ index(($spacings), $space)-1 };
        @if $space != auto {
            .br-#{ $spcr } {border-radius: #{$space} !important;}
        }
    }
    @each $bstyle in $border-styles {
        .border-style-#{ $bstyle } {border-style: #{$bstyle} !important;}
    }
    @each $emkey, $emvalue in $em-sizes {
        .sz-em-#{$emkey} {font-size:#{$emvalue}em;}
        .sz-rem-#{$emkey} {font-size:#{$emvalue}rem;}
        .lh-em-#{$emkey} {line-height:#{$emvalue} !important;}
        .lh-rem-#{$emkey} {line-height:#{$emvalue}rem !important;}
        .ls-#{$emkey} {letter-spacing:#{$emvalue}px;}
    }
    @each $fwkey, $fwvalue in $font-weights {
        .fw-#{$fwkey} {font-weight:#{$fwvalue};}
        .fw-#{$fwvalue} {font-weight:#{$fwvalue};}
    }
    @each $twkey, $twvalue in $text-wraps {
        .text-#{$twkey} {white-space:#{$twvalue} !important;}
    }
    @each $key, $value in $custom-breakpoints {
        @media all and (min-width:$value) {
            @each $cwkey, $cwvalue in $custom-widths {
                .width-#{$key}-#{$cwkey} {width:#{$cwvalue};}
            }
            @each $ord in $custom-order {
                .order-#{$key}-#{$ord} {order:#{$ord} !important;}
            }
            @each $d in $displays {
                .d-#{$key}-#{$d} {display:#{$d} !important;}
            }
            @each $jcname, $jcvalue in $justify-content-opt {
                .justify-content-#{$key}-#{$jcname} {justify-content:#{$jcvalue} !important;}
            }
            @each $ainame, $aivalue in $align-items-opt {
                .align-items-#{$key}-#{$ainame} {align-items:#{$aivalue} !important;}
                .align-self-#{$key}-#{$ainame} {align-self:#{$aivalue} !important;}
            }
            @each $fdname, $fdvalue in $felx-direction-opt {
                .flex-#{$key}-#{$fdname} {flex-direction:#{$fdvalue} !important;}
            }
            @each $attr-short, $attr-list in $prefixes {
                @each $space in $spacings {
                    $spcr: #{ index(($spacings), $space)-1 };
                    @if $space == auto {
                        $spcr:auto;
                    }
                    .#{$attr-short}-#{$key}-#{ $spcr } {
                        @each $attr in $attr-list {
                            #{$attr}: #{$space} !important;
                        }
                    }
                }
            }
            @each $attr-short, $attr-list in $prefixes-m {
                @each $space in $n-spacings {
                    $spcr: #{ index(($n-spacings), $space)-1 };
                    @if $space != auto {
                        .#{$attr-short}-#{$key}-n#{ $spcr } {
                            @each $attr in $attr-list {
                                #{$attr}: #{$space} !important;
                            }
                        }
                    }
                }
            }
            @each $attr-short, $attr-list in $prefixes-bw {
                @each $bwidth in $border-widths {
                    $bw: #{ index(($border-widths), $bwidth)-1 };
                    .#{$attr-short}-#{$key}-#{ $bw } {
                        @each $attr in $attr-list {
                            #{$attr}: #{$bw}px !important;
                        }
                    }
                }
            }
            @each $cname, $cvalue in $soa-colors {
                .text-#{$key}-#{$cname}, .#{$cname}-text-#{$key} {color:#{$cvalue} !important;}
                .bg-#{$key}-#{$cname} {background-color:#{$cvalue};}
                .border-#{$key}-#{$cname} {border-color:#{$cvalue} !important;}
            }
            @each $iname, $ivalue in $soa-icons {
                .bg-#{$key}-#{$iname} {background-image:#{$ivalue};}
            }
            @each $space in $spacings {
                $spcr: #{ index(($spacings), $space)-1 };
                @if $space != auto {
                    .br-#{$key}-#{ $spcr } {border-radius: #{$space} !important;}
                }
            }
            @each $bstyle in $border-styles {
                .border-style-#{$key}-#{ $bstyle } {border-style: #{$bstyle} !important;}
            }
            @each $emkey, $emvalue in $em-sizes {
                .sz-#{$key}-em-#{$emkey} {font-size:#{$emvalue}em;}
                .sz-#{$key}-rem-#{$emkey} {font-size:#{$emvalue}rem;}
                .lh-#{$key}-em-#{$emkey} {line-height:#{$emvalue} !important;}
                .lh-#{$key}-rem-#{$emkey} {line-height:#{$emvalue}rem !important;}
                .ls-#{$key}-#{$emkey} {letter-spacing:#{$emvalue}px;}
            }
            @each $fwkey, $fwvalue in $font-weights {
                .fw-#{$key}-#{$fwkey} {font-weight:#{$fwvalue};}
                .fw-#{$key}-#{$fwvalue} {font-weight:#{$fwvalue};}
            }
            @each $twkey, $twvalue in $text-wraps {
                .text-#{$key}-#{$twkey} {white-space:#{$twvalue} !important;}
            }
        }
    }
//}

.flex-grow-0 {flex-grow:0 !important;}
.flex-grow-1 {flex-grow:1 !important;}
.flex-shrink-0 {flex-shrink:0 !important;}
.flex-shrink-1 {flex-shrink:1 !important;}
.flex-nowrap {flex-wrap:nowrap !important;}

.text-decoration-none {text-decoration:none !important;}
.text-decoration-underline {@include textUnderlineCustom($important:true);}

%lineclamp {display:-webkit-box; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis;}
//@mixin lineclamp-set {display:-webkit-box; -webkit-box-orient:vertical; overflow:hidden; text-overflow:ellipsis;}
//@mixin lineclamp-unset {-webkit-box-orient:initial; overflow:visible; text-overflow:initial;}
@each $lc in (0,1,2,3,4,5,6,7,8,9,10) {
    .line-clamp-#{$lc} {@extend %lineclamp; -webkit-line-clamp:#{$lc};}
}