﻿@import '_variables';
@import '_mixins';

.app-search {
    .text-input-wrap {display:block; padding:toRem(6); background:$neutral-50; border-radius:toRem(30);}
    .text-input-group {display:flex; flex-wrap:nowrap; margin-left:0; width:auto; padding:toRem(5); background:$white; border:1px solid $white; border-radius:toRem(26);
        .text-input {flex:1 1 auto;
            &, &:focus-within {border:none; background:none;}
            span {display:none;}
            input {
                &, &[type="text"] {padding:0 toRem(10); width:100%; border:none; background:none; @include figtree_medium; font-size:toRem(16); font-weight:$fw_medium; line-height:1.28; @include placeHolderColor($neutral-500);
                    &, &:focus {color:$soa-blue;}
                }
            }
        }
        .search-btn {flex:0 0 toRem(36); padding:0; width:toRem(36); height:toRem(36); border:none; border-radius:toRem(18); display:flex; justify-content:center; align-items:center; background:$soa-blue;
            &, & span {color:$white; font-size:toRem(16); font-weight:$fw_medium; line-height:1em; white-space:normal; transform:none;}
            &::before {display:block; position:relative; z-index:1; @include fas-family; font-size:toRem(12); content:"\f002";}
            span {width:auto;}
            .search-btn-icon {display:none;}
            .search-btn-text {display:none;}
            &:hover, &:focus {background:$soa-blue-lighter;}
        }
    }
    .text-input-wrap {
        &:focus-within {
            .text-input-group {border:1px solid $soa-blue;
                &::before {color:$soa-blue;}
            }
        }
    }
    .mobile {
        .text-input-wrap {margin-top:1rem; margin-bottom:2rem;}
        .text-input-group {width:100%; margin:0;}
    }

    .info-box .sort-dropdown select {@include customSelect; min-width:auto;}

    @media all and (min-width:$screen_lg_min)
        {
        .text-input-wrap {padding:toRem(12); border-radius:toRem(46);}
        .text-input-group {padding:toRem(10); border-radius:toRem(34);
            &::before {@include fas-family; flex:0 0 toRem(38); width:toRem(38); display:flex; justify-content:flex-end; align-items:center; padding-right:toRem(5); color:$neutral-500; font-size:toRem(18); content:"\f002";}
            .text-input {
                input {
                    &, &[type="text"] {padding:0 toRem(16); font-size:toRem(18); font-weight:$fw_semibold; line-height:1.3;}
                }
            }
            .search-btn {flex:1 1 auto; padding:toRem(15) toRem(24) toRem(15); width:auto; height:auto; max-width:toRem(146); border-radius:toRem(24);
                &::before {display:none; content:none;}
                .search-btn-icon {display:none;}
                .search-btn-text {display:block;}
            }
        }
    }

    @media all and (min-width:1366px)
    {
        //.text-input-wrap {margin-left:8px; width:85%;}
    }
}