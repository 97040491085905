@import '_variables';
@import '_mixins';

.container, .container-fluid {padding-left:toRem($container-gutter-x); padding-right:toRem($container-gutter-x);
    @media all and (min-width: 1280px) {
        & {max-width:toRem($desktop_min_val + ($container-gutter-x * 2))}
    }
}

//.soa-redesign {
    .table {margin-bottom:toRem(24); border-collapse:separate; border-block:none; border-width:0; color:$neutral-900; font-weight:$fw_medium;
        tr {border-radius:toRem(8);}
        & {
            th, thead th, thead td, td, tbody td {border:none;}
        }
        &.table-bordered, &.table-bordered td, &.table-bordered th {border:none;}

        th, thead th, thead td {background-color:$soa-blue; color:$white; font-size:toRem(13); font-weight:$fw_semibold; line-height:1.5; letter-spacing:toRem(0.26);
            .icon, .fa, .fas, .far, .fab {font-size:0.8em;}
        }
        th, thead th, thead td, td, tbody td {padding:toRem(16); vertical-align:top;
            &:first-child {border-radius:toRem(8) 0 0 toRem(8);}
            &:last-child {border-radius:0 toRem(8) toRem(8) 0;}
        }
        &.table-striped, &.table-striped-even { //&:not(.table-striped-md, .table-striped-md-even, .table-striped-md-odd),
            tbody {
                tr {background-color:transparent;}
                tr:nth-of-type(even) {background-color:$neutral-50;}
                tr th {background-color:$soa-blue;}
            }
        }
        &.table-striped-odd {
            tbody {
                tr {background-color:transparent;}
                tr:nth-of-type(odd) {background-color:$neutral-50;}
                tr th {background-color:$soa-blue;}
            }
        }

        @media all and (min-width:$screen_md_min) {
            &.table-striped-md, &.table-striped-md-even {
                tbody {
                    tr {background-color:transparent;}
                    tr:nth-of-type(even) {background-color:$neutral-50; //position:relative;
                        //td {position:relative; z-index:1;}
                        //&::after {position:absolute; left:0; right:0; top:0; bottom:0; background-color:$neutral-50; border-radius:toRem(16); content:"";}
                    }
                    tr th {background-color:$soa-blue;}
                }
            }
            &.table-striped-md-odd {
                tbody {
                    tr {background-color:transparent;}
                    tr:nth-of-type(odd) {background-color:$neutral-50;}
                    tr th {background-color:$soa-blue;}
                }
            }
        }

        @media all and (min-width:$screen_lg_min) {
            th, thead th {padding:toRem(16) toRem(24);}
            thead td, td, tbody td {padding:toRem(24);}
        }
    }

    .table--sortable {
        tr {display:block;}
        th {display:inline-block;}
        td {display:flex; flex-direction:row; flex-wrap:nowrap; vertical-align:middle;
            .meta--label {flex:0 0 42%; display:block; padding-right:toRem(12); width:42%; max-width:toRem(150); color:$dark-grey; font-style:italic;}
        }
	    thead {
            tr {background:$soa-blue; border-radius:toRem(8); overflow:hidden;}
		    th {
			    &:not(.sorting_disabled) {position:relative; padding-right:toRem(24);
                    &::after {position:absolute; right:toRem(12); top:50%; transform:translateY(-50%); @include fas-family; color: $white; font-size: 0.8em; content:"\f0dc";}
				    &.sort_asc {
					    &::after {content:"\f0de";}
				    }
				    &.sort_dsc {
					    &::after {content:"\f0dd";}
				    }
			    }
		    }
            .sorting_disabled {display:none;}
            .sorting--mobile {display:inline-block;}
	    }

        @media (min-width: $screen_md_min) {
            tr {display:table-row;}
            th, td {display:table-cell; width:var(--md-width);}
            td {
                .meta--label {display:none;}
            }
            thead {
                .sorting_disabled {display:table-cell;}
                .sorting--mobile {display:none;
                    & + th {border-radius:toRem(8) 0 0 toRem(8);}
                }
            }
        }
    }

    //.table--sortable thead th.sorting_disabled {padding:toRem(7.5);}
    .actuarial__table {//table-layout:fixed;
        //td {padding:toRem(7.5);}
        .icon {
            .website--icon {height:toRem(18); width:toRem(18); transition:fill 0.5s ease;}
            i {font-size:toRem(18); line-height:toRem(18);}
    
            a {
                &, &:link, &:active, &:visited {
                    svg {fill:$soa-blue;}
                    i {color:$soa-blue;}
                }
                &:hover, &:focus {
                    svg {fill:$soa-link-hover;}
                    i {color:$soa-link-hover;}
                }
                &:visited:hover {
                    svg {fill:$soa-link-hover;}
                    i {color:$soa-link-hover;}
                }
                &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {
                    svg {fill:$soa-blue;}
                    i {color:$soa-blue;}
                }
                &[href=""], &:not([href]), &.disabled, &:disabled {
                    svg {fill:$neutral-500;}
                    i {color:$neutral-500;}
                }
                &[href=""], &:not([href]) {pointer-events:none; cursor:default;}
            }
        }
    
        @media (min-width:$screen_md_min) {
            .icon {text-align: center;
                .website--icon {height:toRem(20); width:toRem(20);}
                i {font-size:toRem(20); line-height:toRem(20);}
            }
        }
    }

    //select {@include customSelect;}

    select {
        &.form-control {padding-right:toRem(28); background-image:$icon-chevron-down-neutral-400; background-position:right toRem(8) top toRem(14); background-repeat:no-repeat; background-size:toRem(12); font-weight:500; appearance:none;
            &::-ms-expand {display:none;}
        }
    }

    input[type="text"] {
        &.form-control {
            &.form-search-control {padding-right:toRem(28); background-image:$icon-search-blue; background-position:right toRem(8) center; background-repeat:no-repeat; background-size:toRem(14); font-weight:500;}
        }
    }

    .form-control-select-search {
        .form-control-select-search-wrap {position:relative;}
        .form-control-select-search-trigger {display:flex; flex-direction:column;
            .form-control-select-search-trigger-selected {padding:toRem(13) toRem(24) toRem(12); height:toRem(40); font-size:toRem(15); line-height:1; font-weight:$fw_medium; border:1px solid $neutral-200; border-radius:toRem(20);}
            &, &:link, &:visited, &:active, &:hover, &:focus {color:$form-control-text; text-decoration:none; outline:none;
                .form-control-select-search-trigger-selected {@include form-control-boxshadow-focus;}
            }
            &:focus {
                .form-control-select-search-trigger-selected {border:1px solid $form-control-border-focus;}
            }
            .form-control-select-search-trigger-selected, .form-control-select-search-trigger-options {padding-right:toRem(40); background-image:$icon-chevron-down-neutral-400; background-position:right toRem(16) top toRem(16); background-repeat:no-repeat; background-size:toRem(12); font-weight:500; appearance:none;}
            .form-control-select-search-trigger-selected {display:flex; flex-direction:row; align-items:center;}
            .form-control-select-search-trigger-text {white-space:nowrap; overflow:hidden; text-overflow:ellipsis;}
            .form-control-select-search-trigger-options {display:flex; flex-direction:column; flex-wrap:nowrap; padding:0 toRem(29 + 3.75) 0 toRem(8.5); height:0; border-width:0 1px; opacity:0; overflow:hidden;
                & > div {white-space:nowrap;}
            }
        }
        .form-control-select-search-dropdown {position:absolute; left:0; top:auto; padding:toRem(4); border:1px solid $dark-grey; border-radius:toRem(2.5); width:100%; background:$white; box-shadow:toRem(2) toRem(2) toRem(6) rgba($black, .25); z-index:9;
            &, &.collapse, &.collapsing {transform:none; transition:none;}
        }
        .form-control-select-search-option-wrap {max-height:toRem(330); overflow-y:auto; @include scrollBarHack();}
        .form-control-select-search-input {padding:toRem(8) toRem(6) toRem(12) toRem(6);
            input {font-size:toRem(16); @include placeHolderColor(rgba($primary-dark, 0.5), #{'italic'});}

            & {position:relative;}
            &::before {display:block; position:absolute; right:14px; top:50%; transform:translateY(-50%); @include fas-family(); content:"\f0b0"; color:rgba($primary-dark, 0.5); font-size:12px;}
            input {position:relative; z-index:1; padding-right:toRem(7.5 + 16); background:none;}
        }
        .form-control-select-search-option {padding:toRem(4) toRem(6); border-radius:toRem(2); font-size:toRem(14); cursor:pointer;
            &:hover, &:focus {background:rgba($primary-blue-1, 0.15); outline:none;}
            &.selected {background:$primary-blue-1; color:$white;}
        }
        
        &::-ms-expand {display:none;}
    }

    [data-soa-sortby], [data-soa-sortdirection] {cursor:pointer;}

    .dropdown-toggle {
        &::after {width:auto; height:auto; margin-left:toRem(6); border:none; vertical-align:middle; @include fa-family(); font-size:toEm(10,14); content:"\f078";}
        //&.show {
            //&::after {content:"\f054";}
        //}
    }
//}