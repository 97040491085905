﻿@import '../_variables.scss';
//.soa-redesign {
@import 'fontawesome-free-6.5.2-web/scss/fontawesome.scss';
//}
@import 'slick-1.8.1/slick.scss';
@import 'slick-1.8.1/slick-theme.scss';


.slick-parent {}
.slick-slider {opacity:0; transition:opacity 0.15s ease;
    &.slick-initialized {opacity:1;}
}
.slick-list {}
.slick-track {display:flex; justify-content:flex-start; align-items:stretch; flex-direction:row; flex-wrap:nowrap;
    .slick-slide {float:none; height:auto; min-height:auto; flex-grow:0; flex-shrink:0; display:flex; align-items:center;}
}
.slick-arrow {
    &:hover, &.focus {
    }
    &, &.slick-disabled {
    }
    &.slick-disabled {opacity:0.62;}
}
//.soa-redesign {
    ul.slick-dots {margin:0; padding:0; margin-block-start:0; margin-block-end:0; margin-inline-start:0; margin-inline-end:0; padding-inline-start:0;}
//}