﻿@import '_variables';
@import '_mixins.scss';

.soa-individualcontent {
    .soa-individualcontent-main {padding-top:toRem(24); padding-bottom:toRem(40);}

    //.soa-individualcontent-row {
    //    .hasSideContent {}
    //}
    //.soa-individualcontent-col {}

    .soa-individualcontent-aside {}
    .soa-individualcontent-sidecard {margin-bottom:toRem(32); padding:toRem(24); background:$neutral-50; border-radius:toRem(24);
        h4 {margin-bottom:toRem(12);}
    }
    .soa-individualcontent-toclist {}
    .soa-individualcontent-tocitem {display:inline-flex; width:100%; padding:toRem(8) 0; color:$neutral-700; font-size:toRem(14); font-weight:$fw_medium; line-height:toRem(24); text-decoration:none;
        &::before {@include fas-family; display:flex; justify-content:center; align-items:center; margin-right:toRem(12); width:toRem(10); height:toRem(20); font-size:toRem(10); content:"\f054";}
        &.active, &:hover, &:focus {color:$soa-blue;}
    }

    .soa-individualcontent-tocdownloads {margin-top:toRem(16); padding-top:toRem(8); border-top:2px solid $neutral-200;}
    .soa-individualcontent-tocdownloads-item {margin-top:toRem(16); color:$neutral-700; font-size:toRem(13); font-weight:$fw_medium; line-height:1.5; text-align:center;}

    .soa-individualcontent-downloads {}
    .soa-individualcontent-downloads-item {display:block; margin-top:toRem(8); padding:toRem(20) toRem(24); background:$white; border-radius:toRem(16); color:$neutral-700; font-size:toRem(13); font-weight:$fw_medium; line-height:1.5; text-decoration:none;
        .row {@include customGutter(8);}
        .btn {font-size:toRem(14);}
        .soa-individualcontent-downloads-itemtitle {margin-bottom:toRem(16); color:$neutral-900; line-height:1.2;}
        &:hover, &:focus {@include boxshadow(0, toRem(5), toRem(15), 0, rgba($neutral-800,0.05));
            .soa-individualcontent-downloads-itemtitle {color:$soa-blue;}
            .btn {color:$soa-link-hover;}
        }
    }

    .soa-individualcontent-sidecontent {display:none; margin-bottom:toRem(32); padding:0 toRem(32); font-weight:$fw_medium;
        &, & p, & ul li, & ol li {font-size:toRem(13);}
    }

    .soa-individualcontent-article {
        @include nestedBlocks;
    }

    .soa-individualcontent-header {margin-bottom:toRem(24);
        .soa-individualcontent-header-title {margin-bottom:toRem(16); color:$neutral-900; font-size:toRem(28); font-weight:$fw_semibold; line-height:1.2; letter-spacing:toRem(-0.28);}
    }
    .soa-individualcontent-header-descriptionwrap {margin:toRem(32) 0 toRem(8) 0; padding:toRem(24); background:$white; border:1px solid $neutral-200; border-radius:toRem(24); color:$neutral-900; font-weight:$fw_medium;}
    .soa-individualcontent-header-description {
        &, & * {font-size:toRem(18);}
    }
    .soa-individualcontent-header-itemctas {}
    
    .soa-individualcontent-pageimage {position:relative; margin-top:toRem(38); margin-bottom:toRem(32);
        img {position:relative; z-index:1; border-radius:toRem(14);}
    }
    .soa-individualcontent-pageimage-bg {display:block; position:absolute; right:toRem(-14); top:toRem(-14); height:99%; overflow:hidden;
        svg {height:toRem(400); max-height:100%; fill:$soa-theme-orange-base;}
    }
    .soa-individualcontent-header-hr {display:flex; align-items:center; margin-top:toRem(24); margin-bottom:toRem(8); height:toRem(48);
        &::before {display:block; width:toRem(80); height:2px; background:$soa-blue; content:"";}
    }
    .soa-individualcontent-summary {font-weight:$fw_medium; //color:$neutral-800;
        &, & p, & ul li, & ol li {font-size:toRem(18);}
    }

    $tags-gutter:6;
    .soa-individualcontent-header-info {margin-bottom:toRem(4); min-height:toRem(38);}
    .soa-individualcontent-header-infotext {padding-top:toRem(4); padding-bottom:toRem(4);}
    .soa-individualcontent-tags {}
    .soa-individualcontent-tagsrow {@include customGutter($tags-gutter);}
    .soa-individualcontent-tagscol {margin-bottom:toRem($tags-gutter * 2);}

    .soa-individualcontent-published {margin-bottom:toRem($tags-gutter * 2); color:$neutral-700; font-size:toRem(13); font-weight:$fw_medium; line-height:1.3;
        span {font-weight:$fw_semibold;}
    }

    .soa-individualcontent-authors {margin-bottom:toRem(8); color:$neutral-700; font-size:toRem(13); font-weight:$fw_medium; line-height:1.3;
        span {font-weight:$fw_semibold;}
    }

    .soa-individualcontent-articlebody {font-weight:$fw_medium;}
    .soa-individualcontent-articlebody-foot {margin-top:toRem(32); padding-top:toRem(24); border-top:1px solid $neutral-200;}

    .soa-individualcontent-footercontent {padding:toRem(16) toRem(24); border:1px solid $neutral-200; border-radius:toRem(24);}

    @media all and (min-width:$screen_md_min) {
        & {
            .soa-individualcontent-header {}
            .soa-individualcontent-header-info {flex-wrap:nowrap;}
            .soa-individualcontent-tags {flex-shrink:1;}
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .soa-individualcontent-main {padding-top:toRem(60); padding-bottom:toRem(108);}
            //.soa-individualcontent-row {display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:center; @include customGutter($gutter-x, '.soa-//individualcontent-col');
            //    .hasSideContent {justify-content:space-between;}
            //}
            //.soa-individualcontent-col {}

            //.soa-individualcontent-aside {flex:0 0 toRem(320); width:toRem(320);}
            .soa-individualcontent-aside {}
            .soa-individualcontent-asideslide {position:sticky; top:toRem(24);}
            .soa-individualcontent-sidecard {}
            .soa-individualcontent-downloads {}
            .soa-individualcontent-sidecontent {display:block;
                &, & p, & ul li, & ol li {font-size:toRem(14);}
            }

            .soa-individualcontent-header {margin-bottom:toRem(32);
                .soa-individualcontent-header-title {margin-bottom:toRem(24); font-size:toRem(48); line-height:1.2; letter-spacing:toRem(-1.44);}
            }
            .soa-individualcontent-header-descriptionwrap {margin:toRem(56) 0 toRem(24) 0; padding:toRem(48);}
            .soa-individualcontent-header-description {
                &, & * {font-size:toRem(22);}
            }

            //.soa-individualcontent-article {flex:0 0 toRem(788); width:toRem(788);}

            .soa-individualcontent-footercontent {display:none;}

            .soa-individualcontent-grid {display:grid; justify-content:center; grid-template-columns:clamp(60%, 100%, 78.8rem); //grid-template-columns:minmax(max-content, toRem(788));
                &.hasSideContent {display:grid; justify-content:space-between; grid-column-gap:toRem($gutter-x *  2); grid-template-columns:toRem(320) minmax(toRem(320), toRem(788)); //grid-template-columns: 1fr; //grid-template-rows: 320px max(720px);
                    grid-template-areas:"aside head""aside body""aside foot";
                    .soa-individualcontent-aside {order:1; grid-row:3 span;} //flex:0 0 toRem(320); width:toRem(320);
                    .soa-individualcontent-header, .soa-individualcontent-articlebody {order:2;} //max-width:toRem(788);
                }
            }
            
            .soa-individualcontent-sidecard {margin-bottom:toRem(42); padding:toRem(32); border-radius:toRem(32);
                h4 {margin-bottom:toRem(12);}
            }
            .soa-individualcontent-toclist {}
            .soa-individualcontent-tocitem {margin-top:toRem(8); padding:toRem(8) toRem(14); color:$neutral-900; line-height:toRem(22); font-weight:$fw_semibold;
                &::before {margin-right:toRem(8); width:toRem(16);}
                &.active, &:hover, &:focus {color:$soa-blue;}
                &.active {background:$white; border-radius:toRem(8); @include boxshadow(0, toRem(5), toRem(15), 0, rgba($neutral-800,0.05));}
            }

            .soa-individualcontent-tocdownloads {margin-top:toRem(28); padding-top:toRem(12);}
            .soa-individualcontent-tocdownloads-item {color:$neutral-700; font-size:toRem(13); font-weight:$fw_semibold; line-height:1.5; text-align:center;}
            
            .soa-individualcontent-pageimage {margin-top:toRem(72); margin-bottom:toRem(48);
                img {border-radius:toRem(32);}
            }
            .soa-individualcontent-pageimage-bg {right:toRem(-32); top:toRem(-32);}
            .soa-individualcontent-header-hr {margin-top:toRem(32); margin-bottom:toRem(24);}
            .soa-individualcontent-summary {
                &, & p, & ul li, & ol li {font-size:toRem(22);}
            }
            
            .soa-individualcontent-header-info {margin-bottom:toRem(28);}
            .soa-individualcontent-tags {}
            .soa-individualcontent-tagsrow {}
            .soa-individualcontent-tagscol {}

            .soa-individualcontent-published {font-size:toRem(14);
                span {}
            }

            .soa-individualcontent-authors {font-size:toRem(14);
                span {font-weight:$fw_semibold;}
            }

            .soa-individualcontent-articlebody-foot {margin-top:toRem(48);}
        }
    }
}