﻿@import '_variables';

@mixin slickArrows {
    $slickarrow-gutter:3;
    .slick-arrow-row {justify-content:flex-end; margin-left:toRem(-$slickarrow-gutter); margin-right:toRem(-$slickarrow-gutter); //margin-top:toRem(12);
        & > [class*="col-"] {display:flex; justify-content:center; padding-left:toRem($slickarrow-gutter); padding-right:toRem($slickarrow-gutter);}
    }
    .slick-arrow-left, .slick-arrow-right {cursor:pointer;
        .icon, .fa, .fas, .far, .fab {display:flex; justify-content:center; align-items:center; width:toRem(40); height:toRem(40); border-radius:toRem(20); background:$soa-blue; color:$white; font-size:toRem(12); cursor:pointer;
            &::before {display:block;}
        }
    }
    .slick-arrow-left {}
    .slick-arrow-right {}
    .dark-theme {
        .slick-arrow-left, .slick-arrow-right {cursor:pointer;
            .icon, .fa, .fas, .far, .fab {background:$icon-blue; color:$white;}
        }
    }
    @media all and (min-width:$screen_md_min) {
        .slick-arrow-left, .slick-arrow-right {
            .icon, .fa, .fas, .far, .fab {width:toRem(48); height:toRem(48); border-radius:toRem(24); font-size:toRem(16); cursor:pointer;
                &::before {display:block;}
            }
        }
    }
}
@mixin slickDots {
    $slickdot-gutter:6;
    $slickcontrol-gutter:10;
    .slick-controls-wrap {display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:space-between; align-items:center; margin-left:toRem(-$slickcontrol-gutter); margin-right:toRem(-$slickcontrol-gutter); margin-top:toRem(8);
        & > div {padding-left:toRem($slickcontrol-gutter); padding-right:toRem($slickcontrol-gutter);}
        .slick-controls-tabs {flex-grow:1; flex-shrink:1;}
        .slick-arrow-row {flex-grow:0; flex-shrink:0;}
    }
    .slick-controls-tabs {}
    .slick-dots {position:static; bottom:0; width:auto; display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:center; align-items:stretch; margin-left:toRem(-$slickdot-gutter); margin-right:toRem(-$slickdot-gutter);
        li {position:relative; width:100%; min-height:1px; height:auto; margin:0; padding-left:toRem($slickdot-gutter); padding-right:toRem($slickdot-gutter); flex:1 1 100%; max-width:33.333333%; display:flex; justify-content:center; align-items:center; padding-top:toRem(16); padding-bottom:toRem(12);  @include inter_400; color:$soa-blue; font-size:toRem(12); line-height:toEm(15,12); text-align:center; cursor:pointer;
            & > div {display:none;}
            &::before {display:block; position:absolute; left:toRem($slickdot-gutter); right:toRem($slickdot-gutter); top:50%; transform:translateY(-50%); height:2px; background:$neutral-200; border-radius:3px; content:"";}
            &.slick-active {
                &::before {background:$soa-blue;}
            }
            button {
                &::before {display:none; content:"";}
            }
        }
    }
    @media all and (min-width:$screen_md_min) {
        $slickcontrol-gutter:0;
        .slick-controls-wrap:not(.slick-controls-tabs-sm) {
            .slick-controls-tabs {padding:toRem(12) toRem(12 + ($slickcontrol-gutter * 2)); background:$neutral-50; border-radius:toRem(38);}
            .slick-dots {
                li {padding-bottom:toRem(16); border-radius:toRem(24); @include inter_400; color:$soa-blue; font-size:toRem(12); line-height:toEm(15,12);
                    &::before {display:none; content:none;}
                    &.slick-active {background:$white; @include boxshadow;}
                }
            }
        }
    }
}

@mixin slickControls {
    @include slickArrows;
    @include slickDots;
}

@mixin orGrid {
    $splitpath-gutter:28;
    & {margin-top:toRem($splitpath-gutter); margin-left:toRem(-$splitpath-gutter); margin-right:toRem(-$splitpath-gutter);
        h4 {padding-top:0;}
        & > div {position:relative; padding-left:toRem($splitpath-gutter); padding-right:toRem($splitpath-gutter); padding-top:toRem($splitpath-gutter * 1.5); margin-top:toRem($splitpath-gutter * 1.5);
            &::before {position:absolute; display:block; left:0; right:0; top:0; height:1px; background:$blue; content:"";}
            &::after {position:absolute; display:block; left:50%; top:0; padding-left:toRem(8); padding-right:toRem(8); padding-bottom:toRem(4); background:$white; transform:translate(-50%, -50%); color:$dark-grey; font-size:toRem(16); font-weight:bold; content:"or"; z-index:1;}
            &:first-child {margin-top:0;
                &::before, &::after {display:none; content:none;}
            }
        }
    }

    @media all and (min-width:$screen_md_min) {
        & {
            & > div {margin-top:0; margin-bottom:toRem($splitpath-gutter); padding-top:toRem(8); padding-bottom:toRem(8);
                &::before {left:0; right:auto; bottom:0; width:1px; height:auto;}
                &::after {left:0; top:50%;}
                &:nth-child(odd) {
                    &::before, &::after {display:none; content:none;}
                }
            }
        }
    }
}

@mixin customSelect {height:toRem(40); border-radius:toRem(20); border:1px solid $neutral-200; padding-left:toRem(24); padding-right:toRem(46); background-image:$icon-chevron-down-neutral-400; background-position:right toRem(24) top toRem(16); background-repeat:no-repeat; background-size:toRem(12); color:$neutral-900; font-size:toRem(15); font-weight:$fw_medium; appearance:none;
    &::-ms-expand {display:none;}
    option {color:$neutral-900;}
    &:focus, &:focus-within, &:focus-visible {border-color:$soa-blue; background-image:$icon-chevron-down-soa-blue; color:$soa-blue; outline:none;
        option {color:$neutral-900;}
    }
}

@mixin nestedBlocks($spacing-sm:32, $spacing-lg:48) {
    section.soa-redesign {padding-top:0; padding-bottom:0; margin-bottom:toRem($spacing-sm); // margin-top:toRem(32);padding-bottom:toRem(56);
        & > .container {padding-left:0; padding-right:0;}
    }
    .html-block {margin-bottom:toRem($spacing-sm);}

    @media all and (min-width:$screen_lg_min) {
        & {
            section.soa-redesign {padding-top:0; padding-bottom:0; margin-bottom:toRem($spacing-lg);} // margin-top:toRem(48);padding-bottom:toRem(72);
            .html-block {margin-bottom:toRem($spacing-lg
                         );}
        }
    }
}