﻿@import '_variables';
@import '_font-face.scss';

:root {font-size:10px;}

html, body {-webkit-text-size-adjust: 100%;} // Prevent font scaling in landscape while allowing user zoom
body {@include figtree; font-size:toRem(16); line-height:1.5; color:$neutral-900;} //text-underline-offset:0.22em;

//.soa-redesign {
    %h1tagspacing {margin-bottom:toEm(20,32);
        @media all and (min-width:$desktop_min) {
            & {margin-bottom:toEm(24,48);}
        }
    }

    %h1tagfont {font-size:toRem(32); line-height:1.1; letter-spacing:toRem(-0.32); font-weight:$fw_regular;
        @media all and (min-width:$desktop_min) {
            & {font-size:toRem(48); line-height:1.2; letter-spacing:toRem(-1.44);}
        }
    }

    %h1tag{@extend %h1tagspacing; @extend %h1tagfont;}
   
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {font-weight:$fw_regular;}

    .soa-redesign {
        h1, .h1, h2, .h2, h3, .h3 {margin-bottom:0.5em;}
        h4, .h4, h5, .h5, h6, .h6 {margin-bottom:0.75em;}
        h1, .h1 {@extend %h1tagspacing; padding-top:0; padding-bottom:0;}
        h2, .h2 {padding-top:0; padding-bottom:0;}
        h3, .h3 {padding-top:0; padding-bottom:0;}
        h4, .h4 {padding-top:0; padding-bottom:0;}
        h5, .h5 {padding-top:0; padding-bottom:0;}
        h6, .h6 {padding-top:0; padding-bottom:0;}
    }
    
    h1, .h1 {@extend %h1tagfont;}
    //h1, .h1 {font-size:toRem(32); line-height:1.1; letter-spacing:toRem(-0.32);}
    h2, .h2 {font-size:toRem(28); line-height:1.2; letter-spacing:toRem(-0.28);}
    h1.hero, .h1.hero, h2.hero, .h2.hero {margin-bottom:toEm(16,36); font-size:toRem(36); letter-spacing:toRem(-0.36); font-weight:$fw_semibold;}
    h3, .h3 {font-size:toRem(21); line-height:1.25;}
    h4, .h4 {font-size:toRem(18);}
    h5, .h5 {font-size:toRem(13); text-transform:uppercase;}
    h6, .h6 {font-size:toRem(16);}
    h4, .h4, h5, .h5, h6, .h6 {line-height:1.5;}
    h3, .h3, h4, .h4, h5, .h5, h6, .h6 {font-weight:$fw_semibold;}

    h1, .h1 {padding-top:toRem(24); padding-bottom:toRem(10);}
    h2, .h2 {padding-top:toRem(24); padding-bottom:toRem(10);}
    h3, .h3 {padding-top:toRem(24); padding-bottom:toRem(10);}
    h4, .h4 {padding-top:toRem(24); padding-bottom:toRem(10);}
    h5, .h5 {padding-top:toRem(24); padding-bottom:toRem(10);}
    h6, .h6 {padding-top:toRem(24); padding-bottom:toRem(10);}

    p {margin-bottom:toRem(16); font-size:toRem(16); line-height:1.5;
        &.xsmall {font-size:toRem(14);}
        &.small {font-size:toRem(15);}
        &.large {font-size:toRem(18);}
    }
    .text-xsmall {
        &, & p, & span {font-size:toRem(14);}
    }
    .text-small {
        &, & p, & span {font-size:toRem(15);}
    }
    .text-large {
        &, & p, & span {font-size:toRem(18);}
    }

    blockquote {font-size:toRem(17); line-height:1.5;}
    
    @media all and (min-width:$desktop_min) {
        //h1, .h1 {font-size:toRem(48); line-height:1.2; letter-spacing:toRem(-1.44);}
        h2, .h2 {font-size:toRem(36); line-height:1.2; letter-spacing:normal; font-weight:$fw_semibold;}
        h1.hero, .h1.hero, h2.hero, .h2.hero {margin-bottom:toEm(32,64); font-size:toRem(64); line-height:1.1; letter-spacing:toRem(-1.92);}
        h2.large, .h2.large {font-size:toRem(40); font-weight:$fw_regular;}
        h3, .h3 {font-size:toRem(24); line-height:1.3;}
        h4, .h4 {font-size:toRem(21);}
        h5, .h5 { font-size:toRem(14); line-height:1.2; letter-spacing:toRem(0.42);} //margin-bottom:toEm(24,14);
        h6, .h6 {font-size:toRem(16);}

        p {font-size:toRem(18);
            &.xsmall {font-size:toRem(14);}
            &.small {font-size:toRem(16);}
            &.large {font-size:toRem(22);}
        }

        .text-xsmall {
            &, & p, & span {font-size:toRem(14);}
        }
        .text-small {
            &, & p, & span {font-size:toRem(16);}
        }
        .text-large {
            &, & p, & span {font-size:toRem(22);}
        }
    }

    a {//font-size:toRem(16); line-height:1.5;
        & {}
        &, &:link, &:active, &:visited {color:$soa-blue;}
        &:hover, &:focus {color:$soa-link-hover;}
        &:visited:hover {color:$soa-link-hover;}
        &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$soa-blue;}
        &, &:link, &:active, &:visited, &:hover, &:focus {@include textUnderlineCustom($offset:0.08em);}
        //&:active, &:visited, &:hover, &:focus {text-decoration:none;}
        &.disabled, &:disabled {color:$neutral-500;}

        &.small {font-size:toRem(15);}
        &.large {font-size:toRem(18);}
    
        @media all and (min-width:$desktop_min) {
            &.xsmall {font-size:toRem(14);}
            &.small {font-size:toRem(16);}
            &.large {font-size:toRem(22);}
        }
    }
    //ul {list-style-type:disc;}
    
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {//color:$neutral-900;
        a {//text-decoration: none;
            &, &:link, &:active, &:visited {color:$neutral-900;}
            &:hover, &:focus {color:$soa-blue;}
            &:visited:hover {color:$soa-blue;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$neutral-900;}
            &, &:link, &:active, &:visited, &:hover, &:focus {text-decoration:none;} //@include textUnderlineCustom;
            &.disabled, &:disabled {color:$soa-blue;}
        }
    }

    ul, ol {padding-bottom:toRem(10);
        li {font-size:toRem(16); line-height:1.5;}
        @media all and (min-width:$desktop_min) {
            li {font-size:toRem(18);}
        }
    }

    ul {
        li {
            &::marker {color:$soa-blue;}
        }
    }

    //.soa-redesign {
        ul, ol {margin:initial; padding:0 0 0 toRem(40); margin-block-start:0; margin-block-end:1em; margin-inline-start:0px; margin-inline-end:0px; padding-inline-start:toRem(40);
        //ul {list-style-type:circle;}
        //ol {list-style-type:decimel;}
            ul, ol {margin-block-start:0px; margin-block-end:0px;}
        }
    //}

    .button {display:inline-block; text-align:center; vertical-align:middle; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; border:1px solid transparent; transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; @include figtree;}
    .btn, .button {padding:toRem(12) toRem(25) toRem(11); border-radius:toRem(20); border-width:1px; font-weight:$fw_medium; font-size:toRem(15); line-height:1em; white-space:normal; @include textUnderlineCustom;
        &.btn-cta {
            .icon, .fa, .fas, .far, .fab, .fal {margin-left:toRem(10); margin-right:toRem(10); font-size:0.75em; line-height:toRem(15);
                //&:first-child {margin-right:toRem(10);}
                //&:not(:first-child) {margin-left:toRem(10);}
            }
        }

        &:not(:disabled):not(.disabled) {cursor:pointer;}
        &, &:link, &:active, &:visited {color:$soa-blue;}
        &:hover, &:focus {color:$soa-link-hover;}
        &:link, &:active, &:visited, &:hover, &:focus {outline:none; box-shadow:none;}
        &, &:link, &:active, &:visited, &:hover, &:focus {background-color:transparent; border-color:transparent; @include textUnderlineCustom;}
        &.focus, &:focus, &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>&.dropdown-toggle:focus {outline:none; box-shadow:none;}
        &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$soa-blue;}
        //&:active, &:visited, &:hover, &:focus {text-decoration:none;}
        &.disabled, &:disabled {color:$neutral-500; cursor:default;}

        &.btn-primary, &.button, &.button--primary, &.button--blue {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$white; text-decoration:none;}
            &, &:link, &:visited {background-color:$soa-blue; border-color:$soa-blue;}
            &:hover, &:focus {background-color:$soa-blue-lighter; border-color:$soa-blue-lighter;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$white; background-color:$soa-blue-darker; border-color:$soa-blue-darker;}
            &.disabled, &:disabled {color:$neutral-500; background-color:$neutral-200; border-color:$neutral-200;}
        }
        &.btn-secondary {
            //@extend .btn-outline;
        }
        &.btn-tertiary, &.btn-dark {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$white; text-decoration:none;}
            &, &:link, &:visited {background-color:$neutral-900; border-color:$neutral-900;}
            &:hover, &:focus {background-color:$neutral-800; border-color:$neutral-800;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$white; background-color:$neutral-900; border-color:$neutral-900;}
            &.disabled, &:disabled {color:$neutral-500; background-color:$neutral-200; border-color:$neutral-200;}
        }
        &.btn-quaternary, &.button--red, &.banner__button {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$white; text-decoration:none;}
            &, &:link, &:visited {background-color:$soa-red; border-color:$soa-red;}
            &:hover, &:focus {background-color:$soa-red-lighter; border-color:$soa-red-lighter;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$white; background-color:$soa-red-darker; border-color:$soa-red-darker;}
            &.disabled, &:disabled {color:$neutral-500; background-color:$neutral-200; border-color:$neutral-200;}
        }
        &.btn-light, &.button--clear-blue {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$soa-blue; text-decoration:none;}
            &, &:link, &:visited {background-color:$soa-blue-light; border-color:$soa-blue-light;}
            &:hover, &:focus {color:$white; background-color:$soa-blue-lighter; border-color:$soa-blue-lighter;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$white; background-color:$soa-blue-darker; border-color:$soa-blue-darker;}
            &.disabled, &:disabled {color:$neutral-500; background-color:$neutral-200; border-color:$neutral-200;}
        }
        &.btn-secondary, &.btn-outline, &.btn-hollow, &.button--secondary-grey {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$neutral-800; text-decoration:none;}
            &, &:link, &:visited {background-color:$white; border-color:$neutral-200;}
            &:hover, &:focus {color:$neutral-900; background-color:$neutral-50; border-color:$neutral-400;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$neutral-900; background-color:$neutral-200; border-color:$neutral-400;}
            &.disabled, &:disabled {color:$neutral-500; background-color:$white; border-color:$neutral-300;}
        }
        &.btn-purple {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$soa-purple-button-text; text-decoration:none;}
            &, &:link, &:visited {background-color:$soa-purple-button; border-color:$soa-purple-button;}
            &:hover, &:focus {background-color:$soa-purple-button-lighter; border-color:$soa-purple-button-lighter;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$soa-purple-button-text; background-color:$soa-purple-button-darker; border-color:$soa-purple-button-darker;}
            &.disabled, &:disabled {color:$neutral-500; background-color:$neutral-200; border-color:$neutral-200;}
        }
        &.btn-white, &.button--clear {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$soa-blue; text-decoration:none;}
            &, &:link, &:visited {background-color:$white; border-color:$white;}
            &:hover, &:focus {background-color:$soa-blue-light; border-color:$soa-blue-light;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {background-color:$soa-blue-light; border-color:$soa-blue-light;}
            &.disabled, &:disabled {color:$neutral-500; background-color:$neutral-50; border-color:$neutral-50;}
        }
        &.btn-inverted {
            &, &:link, &:active, &:visited {color:$white;}
            &, &:link, &:active, &:visited, &:hover, &:focus {@include textUnderlineCustom;}
            &:hover, &:focus {color:$soa-secondary-link-hover;} //text-decoration:none;
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show > &.dropdown-toggle {color:$white;}
            &.disabled, &:disabled {color:$neutral-300;}
        }
        &.btn-pagination {
            &, &:link, &:active, &:visited, &:hover, &:focus {color:$neutral-900; text-decoration:none;}
            &, &:link, &:visited {background-color:$soa-blue-light; border-color:$soa-blue-light;}
            &:hover, &:focus {color:$white; background-color:$soa-blue-lighter; border-color:$soa-blue-lighter;}
            &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>&.dropdown-toggle {color:$white; background-color:$soa-blue-darker; border-color:$soa-blue-darker;}
            &.disabled, &:disabled {color:$neutral-500; background-color:$neutral-200; border-color:$neutral-200;}
        }
        &.btn-inline {}
        &.btn-small, &.btn-sm, &.button--tight, &.button--wide {padding:toRem(7) toRem(12) toRem(6); border-radius:toRem(15); font-size:toRem(14);
            .icon, .fa, .fas, .far, .fab, .fal {line-height:toRem(14);}
        }
        &.button--wide {padding:toRem(7) toRem(24) toRem(6);}
        &.btn-large, &.btn-lg, &.button--large {padding:toRem(14) toRem(32); border-radius:toRem(23); font-size:toRem(16); font-weight:$fw_semibold;
            .icon, .fa, .fas, .far, .fab, .fal {line-height:toRem(16);}
        }
        &.btn-xlarge, &.btn-xl {padding:toRem(14) toRem(32); border-radius:toRem(24); font-size:toRem(18); font-weight:$fw_semibold;
            .icon, .fa, .fas, .far, .fab, .fal {line-height:toRem(18);}
        }
        &.btn-text {padding:0; border-radius:0; border:none;
            //&:active, &:visited, &:hover, &:focus {text-decoration:none;}
            &.disabled, &:disabled {text-decoration:none;}
        }
        &.px-0 {padding-left:0; padding-right:0;}
        &.block {display:block;}

        &.button--clean {} //same as .btn

        @media all and (min-width:$desktop_min) {

        }
    }
    button, a, div {
        &.btn {
            &:not(:disabled):not(.disabled) {cursor:pointer;}
        }
    }
    
    button, a, div {
        &[role="button"] {cursor:pointer;}
    }

    .text-blue-highlight {color:rgba($primary-blue-1, 0.7);}
//}

blockquote, .blockquote {margin-bottom:toRem(24); color:$neutral-900; font-size:toRem(18); font-weight:$fw_medium; line-height:1.5;
    &::before {display:block; margin-bottom:toRem(24); width:toRem(20); line-height:toRem(18); content:$icon-ldquo-blue;} //content:"“";
    //&::after {display:inline; content:"”";}
}
.blockquote-footer {display:inline-flex; align-items:center; margin-top:toRem(16); color:$neutral-900; font-size:toRem(17); font-weight:$fw_semibold; line-height:1.6;
    &::before {display:block; margin-right:toRem(12); width:toRem(50); height:2px; background:$soa-blue; content:"";}
}
@media all and (min-width:$desktop_min) {
    blockquote, .blockquote {font-size:toRem(22); display:flex; flex-wrap:nowrap; gap:toRem(26);
        &::before {flex:0 0 toRem(20); line-height:1.5;}
    }
    .blockquote-footer {padding-left:toRem(46); font-size:toRem(18); line-height:1.2;}
}