﻿@import '_variables';
@import '_mixins.scss';

$pathway-gutter:20;
.soa-examdetails {
    .soa-examdetails-row {justify-content:space-between; }
    .soa-examdetails-aside {padding-top:0; padding-bottom:0;
        .modal {padding:0 !important; width:100vw; width:100dvw; outline:none;}
        .modal-dialog {margin:0; max-width:none;}
        .modal-content {border:none; border-radius:0;}
        .modal-header {
            .close {font-size:toRem(24);}
        }
        .modal-body {padding:0;}
    }
    .soa-examdetails-main {@extend %pageSection; max-width:toRem(790);}

    .soa-examdetails-pathway {background:rgba($primary-blue-1, 0.1);}
    .soa-examdetails-pathway-header {padding:toRem(24) toRem($pathway-gutter);
        h3 {margin:0; padding:0;}
        .soa-examdetails-pathway-header-link {padding-left:0; padding-right:0;}
    }

    .soa-examdetails-header {margin-bottom:toRem(24);
        h1 {@extend %blockTitle; display:none;
            &.soa-examdetails-header-title.inEditMode {display:block;}
        }
        .soa-examdetails-pathway-title {
            h3 {padding-top:0; padding-bottom:toRem(16);}
        }
        .soa-examdetails-pathway-nav-toggle {display:block; padding:toRem(13) toRem(15); background:rgba($primary-blue-1, 0.1); border-radius:toRem(4); border:1.5px solid rgba($primary-blue-1, 0.2);
            &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
            $toggleh1-gutter:4;
            h1 {@extend %blockTitle; margin:0 !important; display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:space-between; align-items:center; margin-left:toRem(-$toggleh1-gutter); margin-right:toRem(-$toggleh1-gutter);
                & > span {display:block; padding-left:toRem($toggleh1-gutter); padding-right:toRem($toggleh1-gutter);
                    &.icon, &.fa, &.fas, &.far, &.fab {color:$link-default; font-size:0.75em;}
                }
            }
            h4 {margin:0; padding:0;}
        }
    }
    $reqitem-gutter:4;
    .soa-examdetails-header-requirements {display:flex; flex-wrap:wrap; margin-left:toRem(-$reqitem-gutter); margin-right:toRem(-$reqitem-gutter); margin-top:toRem(16);
        & > div {padding-left:toRem($reqitem-gutter); padding-right:toRem($reqitem-gutter);}
        a {
            &, &:link, &:visited, &:active, &:hover, &:focus {color:$primary-blue-1; font-weight:500; text-decoration:underline;}
        }
    }
    $body-gutter:12;
    .soa-examdetails-body {display:flex; flex-direction:row; flex-wrap:wrap; justify-content:space-between; margin-left:toRem(-$body-gutter); margin-right:toRem(-$body-gutter);
        & > div {padding-left:toRem($body-gutter); padding-right:toRem($body-gutter);}
    }
    .soa-examdetails-body-summary {flex-grow:0; flex-shrink:0; width:100%; flex-basis:100%; @include dark-body;}
    .soa-examdetails-body-registration {flex-grow:0; flex-shrink:0; width:100%; flex-basis:100%; margin-top:toRem(24);}
    .soa-examdetails-body-registration-box {padding:toRem(24) toRem($pathway-gutter); background:$off-white; @include dark-body; text-align:center;
        h4 {margin:0; padding:0;}
        .btn {margin-top:toRem(12);}
        .soa-examdetails-body-registration-login {margin-top:toRem(12); font-weight:500;}
    }

    .soa-examdetails-body-splitpaths {@include orGrid;}

    .soa-examdetails-info {margin-top:toRem(36);}

    @media all and (min-width:$screen_sm_min) {
        & {
            .soa-examdetails-body {flex-wrap:nowrap;}
            .soa-examdetails-body-summary {flex-grow:1; flex-shrink:1;}
            .soa-examdetails-body-registration {width:toRem(240); flex-basis:toRem(260); margin-top:0;}
        }
    }

    @media all and (min-width:$screen_md_min) {
        & {
        }
    }

    @media all and (min-width:$screen_lg_min) {
        & {
            .soa-examdetails-aside {padding-top:toRem($block-lg-gutter-y); padding-bottom:toRem($block-lg-gutter-y); max-width:toRem(370);
                .modal {display:block !important; position:static; left:auto; right:auto; top:auto; bottom:auto; width:auto; overflow-x:visible; overflow-y:visible; opacity:1 !important;
                    &, &.fade {
                        .modal-dialog {transform:none;}
                    }
                }
                .modal-header {display:none;}
            }
            .soa-examdetails-header {
                h1 {display:block;}
                .soa-examdetails-pathway-title {display:none;}
                .soa-examdetails-pathway-nav-toggle {display:none;}
            }
            .soa-examdetails-header-requirements {margin-top:toRem(12);}
        }
    }
}

.soa-pathway-nav {
    a {
        &, &:active, &:visited, &:hover, &:focus {text-decoration:none;}
    }
    .soa-pathway-nav-stage {margin-top:1px;}
    .soa-pathway-nav-stage-title {display:flex; flex-direction:row; flex-wrap:nowrap; justify-content:space-between; align-items:center; padding:toRem(16) toRem($pathway-gutter); border-top: 1px solid rgba($primary-blue-1, 0.2); background:$white; font-size:toRem(18); font-weight:600; text-decoration:none; transition:background 0.15s ease; will-change:background;
        & > span {display:block;}
        .soa-pathway-nav-stage-title-label {flex-grow:1; flex-shrink:1; color:$primary-blue-1;}
        .icon, .fa, .fas, .far, .fab {flex-grow:0; flex-shrink:0; padding-left:toRem(16); width:toRem(34); text-align:center;}
        &.collapsed {background:rgba($white, 0.45);
            .soa-pathway-nav-stage-title-label {color:$headline-color;}
            .icon, .fa, .fas, .far, .fab {
                &::before {content:"\f054";}
            }
        }
    }

    .soa-pathway-nav-item-title {flex-grow:1; flex-shrink:1; @include dark-body; font-size:toRem(16);}
    .soa-pathway-nav-item-tag {}
    //.soa-pathway-nav-item-pill {padding:toRem(4) toRem(12); border-radius:toRem(12); background:$link-default; color:$off-white; font-size:toRem(12); font-weight:500;}
    .soa-pathway-nav-item {display:block; position:relative; padding:toRem(12) toRem($pathway-gutter); background:rgba(255, 255, 255, 0.85); border-bottom:1px solid rgba($primary-blue-1, 0.1); text-decoration:none;
        &:first-child {border-top:1px solid rgba($primary-blue-1, 0.1);}
        &.active {background:rgba(255, 255, 255, 0.45);
            &::before {display:block; position:absolute; left:0; top:0; bottom:0; width:toRem(3); height:100%; background:$primary-blue-1; content:"";}
            .soa-pathway-nav-item-title {color:$headline-color; font-weight:500;}
            .soa-pathway-nav-item-pill {background:darken($off-white, 4);}
        }
        $navitem-gutter:8;
        & > .row {margin-left:toRem(-$navitem-gutter); margin-right:toRem(-$navitem-gutter); flex-wrap:nowrap; align-items:center;
            & > [class*="col-"] {padding-left:toRem($navitem-gutter); padding-right:toRem($navitem-gutter);}
        }
    }
}